import { useRouteParams } from "@/modules/router";
import { useUserBasicInfo } from "@/security/hooks/useUserBasicInfo.hook";
import get from "lodash/get";
import union from "lodash/union";
import PropTypes from "prop-types";
import React from "react";
import { SecurityContext } from "./authorization";

const getOrEmpty = (map, key1, key2) => {
    if (!key2) return [];
    return get(map, [key1, key2], []);
};

const UserSecurityContentContext = ({
    projectId,
    instanceId,
    partitionId,
    children,
}) => {
    const {
        accountId: accountIdParam,
        instanceId: instanceIdParam,
        partitionId: partitionIdParam,
    } = useRouteParams();

    const basicInfo = useUserBasicInfo();

    const user = basicInfo?.userInfo.user ?? {};
    const globalPermissions = basicInfo?.permissions.globalPermissions ?? [];
    const nonGlobalPermissions = basicInfo?.permissions.permissions ?? {};
    const assetTree = basicInfo?.permissions.assetTree ?? {};

    const currentAccountId = projectId || accountIdParam;
    const currentInstanceId = instanceId || instanceIdParam;
    const currentPartitionId = partitionId || partitionIdParam;

    const getPermissions = (type, id) =>
        getOrEmpty(nonGlobalPermissions, type, id);

    const projectSpecificPermissions = getPermissions(
        "PROJECT",
        currentAccountId,
    );

    const instanceSpecificPermissions = getPermissions(
        "INTEGRATION",
        currentInstanceId,
    );

    const partitionSpecificPermissions = getPermissions(
        "PARTITION",
        currentPartitionId,
    );

    const permissions = union(
        globalPermissions,
        projectSpecificPermissions,
        instanceSpecificPermissions,
        partitionSpecificPermissions,
    );

    return (
        <SecurityContext.Provider
            value={{
                user,
                permissions,
                nonGlobalPermissions,
                globalPermissions,
                projectSpecificPermissions,
                instanceSpecificPermissions,
                partitionSpecificPermissions,
                assetTree,
                currentAccountId,
                currentPartitionId,
                currentInstanceId,
                getPermissions,
            }}
        >
            {children}
        </SecurityContext.Provider>
    );
};

UserSecurityContentContext.propTypes = {
    user: PropTypes.object,
    globalPermissions: PropTypes.array,
    nonGlobalPermissions: PropTypes.object,
    assetTree: PropTypes.object,
    projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    instanceId: PropTypes.number,
    partitionId: PropTypes.number,
    children: PropTypes.any,
};

export default UserSecurityContentContext;
