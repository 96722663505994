import { createContainer } from "awilix";

const dic = {
    instance: null,
};

export function getRawDic() {
    if (!dic.instance) {
        dic.instance = createContainer();
    }

    return dic.instance;
}

export function getDic() {
    // cradle is proxy
    // so we can use dic.cradle.routerService instead of dic.resolve("routerService")
    return getRawDic().cradle;
}
