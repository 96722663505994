import { Checkbox } from "@/components/DesignSystem";
import classNames from "classnames";
import React from "react";
import "./CheckGroupButton.less";

const componentClass = "check-group-button";

export const CheckGroupButton = ({ className, options, onChange, value }) => (
    <Checkbox.Group
        className={classNames(componentClass, className)}
        options={options}
        onChange={onChange}
        defaultValue={value}
    />
);
