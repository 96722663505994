import { combineReducers } from "redux";
import reducers from "./reducers";

const appReducer = combineReducers(reducers);

export const RESET = "RESET";

// Resets global redux state if needed
const resetReducer = (state, action) => {
    const survivedState = action.type === RESET ? {} : state;
    return appReducer(survivedState, action);
};

export default resetReducer;
