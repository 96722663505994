import { Card, Forms } from "@/components/DesignSystem";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import LoginHeader from "../../apps/LoginApp/LoginHeader.component";
import pageStyles from "../../views/Pages/styles.less";
const { SubmitButton, useForm, Form, Fields, pmValidators } = Forms;

export const NewPassword = ({
    isLoading = false,
    onSubmit: onPassedSubmit,
    showBack = true,
    subtitle = t("page.new-password.desc"),
}) => {
    const onSubmit = ({ values }) => {
        onPassedSubmit(values);
    };

    const { formId, handleSubmit } = useForm({
        onSubmit,
    });
    return (
        <Card type="raised" spacing="huge" footer>
            <LoginHeader
                title={t("page.new-password.title")}
                subtitle={subtitle}
            />

            <div className={pageStyles.form}>
                <Form formId={formId} onSubmit={handleSubmit}>
                    <Fields.RestrictedPassword
                        name="password"
                        type="password"
                        placeholder={t(
                            "page.new-password.form.password.placeholder",
                        )}
                        autoComplete="new-password"
                        required
                        validator={pmValidators.isRequired}
                        autoFocus
                    />

                    <SubmitButton
                        loading={isLoading}
                        type="primary"
                        label={t("page.new-password.form.submit")}
                    />
                    {showBack && (
                        <>
                            <br />
                            <a href="/#">
                                <small>{t("page.new-password.back")}</small>
                            </a>
                        </>
                    )}
                </Form>
            </div>
        </Card>
    );
};

NewPassword.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    subtitle: PropTypes.string,
    showBack: PropTypes.bool,
    isLoading: PropTypes.bool,
};
