import { NotYetImplemented } from "@/components/NotYetImplemented.component";
import { getLocationPath, getLocationRouteName } from "./location.utils";

export function createLocation(
    node,
    name,
    path,
    {
        component = NotYetImplemented,
        permission,
        defaultParams,
        forwardTo,
        ...rest
    } = {},
) {
    if (!node && !name) {
        throw new Error("Location should have 'name' or 'node' or both");
    }

    return {
        __isLocation: true,
        node,
        name,
        routeName: getLocationRouteName(node, name),
        completePath: getLocationPath(node, path), //node.parentPath || "" + path,
        path,
        component,
        permission,
        defaultParams,
        forwardTo,
        ...rest,
    };
}
