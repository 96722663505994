import { Forms } from "@pricefx/unity-components";
import PropTypes from "prop-types";
import React from "react";

const DEFAULT_GET_VALUE = ({ value, defaultValue }) => value ?? defaultValue;
export const SELECT_GET_VALUE = ({ value, options = [], loading }) =>
    loading
        ? "Loading..." // TODO: UC?
        : options?.find(o => o.value === value)?.label ?? value ?? <>&nbsp;</>;

const ReadOnlyValueFieldInner = ({
    getValue = DEFAULT_GET_VALUE,
    ...props
}) => <div style={{ lineHeight: "normal" }}>{getValue(props)}</div>;

ReadOnlyValueFieldInner.propTypes = {
    getValue: PropTypes.func,
};

export const ReadOnlyValue = ({ ...props }) => {
    return <Forms.Field as={ReadOnlyValueFieldInner} {...props} />;
};
