import { t } from "@/translations";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { Divider } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Forms } from "../";
import { Select } from "./Select";

const defaultInputToOption = input => ({ value: input, label: input });
const defaultSetOptions = (options, option) => [...options, option];

export const useOptionsWithAddItem = (
    definedOptions,
    onAddOption = defaultSetOptions,
) => {
    const onAddOptionRef = useRef(onAddOption);
    if (onAddOptionRef.current !== onAddOption)
        onAddOptionRef.current = onAddOption;
    const [options, setOptions] = useState(definedOptions);
    const addOption = useCallback(
        option => setOptions(s => onAddOptionRef.current(s, option)),
        [],
    );

    return [options, addOption];
};

const AddItem = ({ onAdd, fieldProps, resetToken }) => {
    const onSubmit = ({ values, reset }) => {
        onAdd(values.newItem);
        reset();
    };
    const { formId, submit, reset } = Forms.useForm({
        onSubmit,
        initialValues: {
            newItem: "",
        },
    });

    useEffect(() => {
        if (resetToken) {
            // click on dropdown item when add item validation message is displayed does not reset touched state :-/
            setTimeout(() => reset(), 20);
        }
    }, [reset, resetToken]);

    return (
        <div className="pmSelect__addItem">
            <Forms.Fields.Input
                formId={formId}
                name="newItem"
                {...fieldProps}
            />
            <Forms.SubmitButton formId={formId}>
                <Button
                    type="text"
                    htmlType="button"
                    onClick={submit}
                    label={
                        <>
                            <PlusOutlined />
                            {t("select-with-add-item.add")}
                        </>
                    }
                />
            </Forms.SubmitButton>
        </div>
    );
};

AddItem.propTypes = {
    onAdd: PropTypes.func.isRequired,
    fieldProps: PropTypes.object,
    resetToken: PropTypes.any,
};

export const SelectWithAddItem = ({
    filterOption = true,
    selectAddedItem = true,
    closeAfterItemAdded = true,
    onAddNewItem,
    addItemFieldProps,
    onChange,
    inputToOption = defaultInputToOption,
    // resetAddItemOnClose = true,
    ...props
}) => {
    const selectRef = useRef();
    const onAdd = useCallback(
        input => {
            const option = inputToOption(input);
            onAddNewItem(option);
            if (selectAddedItem) onChange(option.value);
            if (closeAfterItemAdded) selectRef.current?.blur();
        },
        [
            onAddNewItem,
            onChange,
            selectAddedItem,
            closeAfterItemAdded,
            inputToOption,
        ],
    );
    // TODO: reset field state/validation on dropdown close?
    // const [resetAddItemToken, setResetAddItemToken] = useState();
    // const onDropdownVisibleChange = useCallback(
    //     open => {
    //         console.log("onDropdownVisibleChange", { open });
    //         if (resetAddItemOnClose && !open) setResetAddItemToken(uuid());
    //     },
    //     [resetAddItemOnClose]
    // );

    return (
        <Select
            filterOption={filterOption}
            optionFilterProp="children"
            onChange={onChange}
            {...props}
            ref={selectRef}
            showSearch
            // onDropdownVisibleChange={onDropdownVisibleChange}
            dropdownRender={menu => (
                <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <AddItem
                        onAdd={onAdd}
                        fieldProps={addItemFieldProps}
                        // resetToken={resetAddItemToken}
                    />
                </div>
            )}
        />
    );
};

SelectWithAddItem.propTypes = {
    children: PropTypes.node,
    menu: PropTypes.array,
    onAddNewItem: PropTypes.func.isRequired,
    inputToOption: PropTypes.func,
    filterOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    selectAddedItem: PropTypes.bool,
    closeAfterItemAdded: PropTypes.bool,
    addItemFieldProps: PropTypes.object,
    onChange: PropTypes.func.isRequired,
};
