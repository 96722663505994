import { SET_BREADCRUMB_ROOT_ITEM } from "./breadcrumbs.actions";
import { createReducer } from "../../../redux/factories/reducer.factory";

const INITIAL_STATE = {
    rootItem: null,
};

export default createReducer(INITIAL_STATE, {
    [SET_BREADCRUMB_ROOT_ITEM]: (state, action) => ({
        ...state,
        rootItem: action.rootItem,
    }),
});
