import {
    ACCOUNT_DATA_LOAD_EDIT_PERMISSIONS,
    ACCOUNT_INTEGRATION_PROVISIONING_PERMISSIONS,
    ACCOUNT_PARTITION_PROVISIONING_PERMISSIONS,
    ACCOUNT_USER_MANAGEMENT_PERMISSIONS,
    CLUSTER_UPGRADE_PERMISSIONS,
    hasPermissionInAccountsOrAssets,
    INTEGRATION_ADMIN_PERMISSIONS,
    INTEGRATION_EDIT_ONLY_PERMISSIONS,
    MARKETPLACE_ACCELERATOR_PERMISSIONS,
} from "@/security/permission.utils";
import join from "lodash/fp/join";
import map from "lodash/fp/map";
import pipe from "lodash/fp/pipe";
import md5 from "tiny-hashes/md5";

const page = () => {
    window.Appcues?.page();
};

const identify = (userId, properties) =>
    window.Appcues?.identify(userId, properties);

const appcues = { page, identify };

const createMD5 = value => md5(String(value));

export const createHashedEmail = email => {
    if (!email) return "unknown";
    const [emailUsername, emailDomain] = email?.split("@") ?? [];
    return `${createMD5(emailUsername)}@${emailDomain}`;
};

export const appcuesIdentifyUser = basicInfo => {
    const appcuesUserEmail = basicInfo?.userInfo?.user?.username;
    const hashedEmail = createHashedEmail(appcuesUserEmail);
    const hasPricefxEmail = appcuesUserEmail?.includes("@pricefx.");
    const isPartnerUser = Boolean(basicInfo.partnerUser);

    const nonGlobalPermissions = basicInfo?.permissions.permissions ?? {};

    let appCuesUserProperties = {
        email: hashedEmail,
        "PfM Pricefx User": hasPricefxEmail,
        "PfM Partner User": isPartnerUser,
        "PfM Version": process.env.VERSION,
        "PfM Integrations Editor": hasPermissionInAccountsOrAssets(
            nonGlobalPermissions,
            INTEGRATION_EDIT_ONLY_PERMISSIONS,
        ),
        "PfM Integrations Developer": hasPermissionInAccountsOrAssets(
            nonGlobalPermissions,
            INTEGRATION_ADMIN_PERMISSIONS,
        ),
        "PfM Accelerator User": hasPermissionInAccountsOrAssets(
            nonGlobalPermissions,
            MARKETPLACE_ACCELERATOR_PERMISSIONS,
        ),
        "PfM Account User Manager": hasPermissionInAccountsOrAssets(
            nonGlobalPermissions,
            ACCOUNT_USER_MANAGEMENT_PERMISSIONS,
        ),
        "PfM Cluster Upgrade": hasPermissionInAccountsOrAssets(
            nonGlobalPermissions,
            CLUSTER_UPGRADE_PERMISSIONS,
        ),
        "PfM Data Upload User": hasPermissionInAccountsOrAssets(
            nonGlobalPermissions,
            ACCOUNT_DATA_LOAD_EDIT_PERMISSIONS,
        ),
        "PfM Provisioning Integrations": hasPermissionInAccountsOrAssets(
            nonGlobalPermissions,
            ACCOUNT_INTEGRATION_PROVISIONING_PERMISSIONS,
        ),
        "PfM Provisioning Partitions": hasPermissionInAccountsOrAssets(
            nonGlobalPermissions,
            ACCOUNT_PARTITION_PROVISIONING_PERMISSIONS,
        ),
    };

    if (!hasPricefxEmail && !isPartnerUser) {
        appCuesUserProperties = {
            ...appCuesUserProperties,
            "PfM Account": pipe(map("name"), join(", "))(basicInfo.projects),
        };
    }

    if (!appcuesUserEmail)
        console.log(
            "%c[Appcues]",
            "color:blue;",
            "Appcues user email couldn't be resolved",
        );
    appcues.identify(hashedEmail, appCuesUserProperties);
};

export default appcues;
