export const LOGIN_ERROR_TYPE = {
    USERNAME: "USERNAME",
    LOGIN: "LOGIN",
    FIRST_PASS: "FIRST_PASS",
    OTP: "OTP",
    NEW_PASS: "NEW_PASS",
};

export const LoginStates = {
    NONE: "NONE",
    NEEDS_PASSWORD_CHANGE: "NEEDS_PASSWORD_CHANGE",
    NEEDS_AUTHENTICATION_SECOND_STEP: "NEEDS_AUTHENTICATION_SECOND_STEP",
};
