import { Select } from "@/components/DesignSystem/Select/Select";
import {
    getTimeZonesOptions,
    getUserTimezone,
} from "@/utils/timezone/timezone.utils";
import React from "react";

export const timezones = getTimeZonesOptions().sort(
    (a, b) => b.offset - a.offset,
);

export const preselectedTimezone = (() => {
    const userTimezone = getUserTimezone();
    const foundTimezone = timezones.find(tz => tz.value === userTimezone);

    if (foundTimezone) {
        return foundTimezone.value;
    } else {
        return null;
    }
})();

const TimezoneSelect = props => {
    return (
        <Select
            showSearch
            options={timezones}
            defaultValue={preselectedTimezone}
            {...props}
        />
    );
};

export default TimezoneSelect;
