import { EntityEditorTable as DesignSystemEntityEditorTable } from "@/components/DesignSystem/EntityEditorTable/EntityEditorTable";
import { Forms } from "@pricefx/unity-components";
import identity from "lodash/identity";
import React from "react";

export const EntityEditorTable = ({
    label = null,
    width = "max",
    inputWidth = "max",
    ...props
}) => {
    return (
        <Forms.Field
            as={DesignSystemEntityEditorTable}
            label={label}
            from={identity}
            width={width}
            inputWidth={inputWidth}
            {...props}
        />
    );
};
