import PropTypes from "prop-types";
import React from "react";
import { RawIntlProvider } from "react-intl";

import { translationsService } from "./translations.service";

export const IntlProvider = ({ children }) => (
    <RawIntlProvider value={translationsService}>{children}</RawIntlProvider>
);

IntlProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
