import { Tag as AntdTag } from "antd";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./Tag.style.less";

export function Tag({ className, children, size, ...props }) {
    const classes = cx({
        [`tag-${size}`]: !!size,
        [className]: className,
    });

    return (
        <AntdTag className={classes} {...props}>
            {children}
        </AntdTag>
    );
}

Tag.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    size: PropTypes.string,
};
