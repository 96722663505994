import PropTypes from "prop-types";
import React from "react";
import { Route } from "../..";

export const LocationRoute = ({ $location, ...props }) => {
    // if location represents just a node, then we do not want to
    // match it exactly
    const isExact = !!$location.name;

    return (
        <Route
            name={$location.routeName}
            exact={isExact}
            permission={$location.permission}
            permissionFunc={$location.permissionFunc}
            component={$location.component}
            unauthorizedContent={$location.unauthorizedContent}
            authenticatedOnly={$location.authenticatedOnly}
            {...props}
        />
    );
};

LocationRoute.propTypes = {
    $location: PropTypes.object.isRequired,
    exact: PropTypes.bool,
};
