import { cancelable } from "cancelable-promise";
import { useRef } from "react";
import { useCallback, useEffect, useState, useMemo } from "react";

export const STATUSES = {
    loading: "loading",
    hasError: "hasError",
    hasValue: "hasValue",
};

const isLoading = ([status]) => status === STATUSES.loading;
const getPromise = ([status, promise]) =>
    isLoading([status]) && !promise.isCanceled() ? promise : undefined;
const getResponse = ([status, response]) =>
    status === STATUSES.hasValue ? response : undefined;

export function useMutationLoadable(fn, deps = []) {
    const [callState, setCallState] = useState([STATUSES.hasValue, undefined]);
    // const [refetchToken, setRefetchToken] = useState();
    const handleError = useCallback(error => {
        setCallState([STATUSES.hasError, error]);
        if (process.env.NODE_ENV !== "production") {
            console.error("useMutationLoadable: an error occurred", error);
        }
    }, []);
    const handleSuccess = useCallback(
        response => setCallState([STATUSES.hasValue, response]),
        [],
    );
    const callStateRef = useRef(callState);
    callStateRef.current = callState;
    useEffect(() => {
        return isLoading(callState)
            ? () => {
                  if (isLoading(callStateRef.current)) {
                      getPromise(callState).cancel();
                  }
              }
            : undefined;
    }, [callState]);
    const fnRef = useRef(fn);
    fnRef.current = fn;
    const mutate = useCallback((...args) => {
        const promise = cancelable(fnRef.current(...args));
        setCallState([STATUSES.loading, promise]);
        promise.then(handleSuccess).catch(handleError);
        return promise;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    const [state, contents] = callState;
    const valueMaybe = useMemo(() => () => getResponse(callState), [callState]);
    const loadable = useMemo(
        () => ({
            state,
            contents,
            valueMaybe,
        }),
        [valueMaybe, state, contents],
    );

    return useMemo(() => ({ loadable, mutate }), [loadable, mutate]);
}
