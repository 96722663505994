import { logger } from "@/modules/logger";

export const LOGIN_TOKEN_NAME = "x-pricefx-login-token";
export const REDIRECT_LOGIN_URL = "x-pricefx-redirect-login-url";
export const INIT_TOKEN_COOKIE = "x-init-token";

export const isForbbiddenPathToStore = () => {
    const isLoginOrLogout =
        window.location.hash.startsWith("#/auth/redir") ||
        window.location.hash.startsWith("#/logout");
    return isLoginOrLogout;
};

export function storeCurrentUrl() {
    if (!isForbbiddenPathToStore()) {
        const loginUrl = window.location.hash;
        logger.debug({
            logGroupKey: ["SECURITY", "storeCurrentUrl"],
            color: "magenta",
            msg: loginUrl,
            data: { loginUrl },
        });
        sessionStorage.setItem(REDIRECT_LOGIN_URL, loginUrl);
    }
}

export function getLoginUrl() {
    const loginUrl = sessionStorage.getItem(REDIRECT_LOGIN_URL);
    logger.debug({
        logGroupKey: ["SECURITY", "getLoginUrl"],
        color: "magenta",
        msg: loginUrl,
        data: { loginUrl },
    });
    return loginUrl;
}

export function clearLoginUrl() {
    logger.debug({
        logGroupKey: ["SECURITY", "clearLoginUrl"],
        color: "magenta",
    });
    return sessionStorage.removeItem(REDIRECT_LOGIN_URL);
}

export function removeTokensFromStorage() {
    logger.debug({
        logGroupKey: ["SECURITY", "removeTokensFromStorage"],
        color: "magenta",
    });
    localStorage.removeItem(LOGIN_TOKEN_NAME);
}
