import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useQueryLoadable } from "@/modules/loadable";

export const useLoginMethodsResource = ({
    params,
    onStart,
    onSuccess,
    onError,
}) => {
    const { loginService } = useDic();
    const currentOnStart = useCurrentHandler(onStart);
    const currentOnSuccess = useCurrentHandler(onSuccess);
    const currentOnError = useCurrentHandler(onError);

    return useQueryLoadable(async () => {
        if (params) {
            currentOnStart();
            return await loginService
                .getAuthMethods(params.username)
                .then(res => {
                    currentOnSuccess(res);
                    return res.data;
                })
                .catch(e => {
                    currentOnError(e);
                    throw e; // commenting out this will prevent unhandled rejection
                });
        } else {
            return undefined;
        }
    }, [
        loginService,
        params,
        currentOnStart,
        currentOnSuccess,
        currentOnError,
    ]);
};
