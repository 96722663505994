import { Forms } from "@pricefx/unity-components";
import React from "react";
import { Checkbox as DesignSystemCheckbox } from "../../index";
const { Field } = Forms;

export const Checkbox = ({ onClick, label, disabled, tooltip, ...props }) => {
    return (
        <Field
            valueProp="checked"
            from={({ target: { checked } }) => checked}
            disabled={disabled}
            tooltip={tooltip}
            {...props}
        >
            <DesignSystemCheckbox
                onClick={onClick}
                label={label}
                tooltip={tooltip}
                disabled={disabled}
            />
        </Field>
    );
};

export const InvertedCheckbox = props => (
    <Checkbox
        from={({ target: { checked } }) => !checked}
        to={value => !value}
        {...props}
    />
);
