import { logger } from "@/modules/logger";
import {
    LOGIN_TOKEN_NAME,
    isForbbiddenPathToStore,
    removeTokensFromStorage,
    storeCurrentUrl,
} from "@/security/security";
import axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import { LOGOUT } from "../mixpanel/mixpanel.constants";

export function createAxiosClient({
    authenticationService,
    locationRouterService,
    mixpanelService,
    locations,
    baseURL,
}) {
    logger.debug({
        logGroupKey: ["AXIOS", "createAxiosClient"],
        color: "pink",
        data: {
            "process.env.PROXY": process.env.PROXY,
            locations,
            baseURL,
            stack: new Error().stack,
        },
    });
    const axiosClient = axios.create({
        baseURL,
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
        ...(process.env.PROXY === "STUB" ? { withCredentials: true } : {}), // cassette and variant cookies for vcr.js
        transformRequest: mergeWithDefaultRequestTransformers([
            addAuthorizationHeaderToRequest,
        ]),
    });

    axiosClient.defaults.maxRedirects = 0;

    axiosClient.interceptors.request.use(
        addTokenIfNecessary({
            authenticationService,
        }),
    );

    axiosClient.interceptors.response.use(
        // error => {
        //     if (error.response && [301, 302].includes(error.response.status)) {
        //         const redirectUrl = error.response.headers.location;
        //         console.log("catch 302", redirectUrl);
        //         return axiosClient.get(redirectUrl);
        //     }
        //     return Promise.reject(error);
        // },
        updateLoginTokenInterceptor,
        unauthorizedInterceptor({
            authenticationService,
            mixpanelService,
            locationRouterService,
            locations,
        }),
    );

    return axiosClient;
}

function mergeWithDefaultRequestTransformers(transformRequest) {
    return axiosDefaults.transformRequest.concat(transformRequest);
}

export function getAxiosCancelToken() {
    return axios.CancelToken.source();
}

function addAuthorizationHeaderToRequest(data, headers) {
    if (localStorage.getItem(LOGIN_TOKEN_NAME) != null) {
        headers[LOGIN_TOKEN_NAME] = localStorage.getItem(LOGIN_TOKEN_NAME);
    }

    return data;
}

function updateLoginTokenInterceptor(response) {
    if (response.headers[LOGIN_TOKEN_NAME]) {
        logger.debug({
            logGroupKey: ["AXIOS", "updateLoginTokenInterceptor"],
            msg: `Setting LOGIN_TOKEN_NAME (${LOGIN_TOKEN_NAME})`,
            color: "pink",
            data: { response },
        });
        localStorage.setItem(
            LOGIN_TOKEN_NAME,
            response.headers[LOGIN_TOKEN_NAME],
        );
    }
    return response;
}

function unauthorizedInterceptor({
    locationRouterService,
    locations,
    mixpanelService,
    authenticationService,
}) {
    return error => {
        if (error?.response?.status === 401) {
            const doNothing = isForbbiddenPathToStore();
            logger.debug({
                logGroupKey: ["AXIOS", "unauthorizedInterceptor"],
                msg: "Logging out (401)",
                color: "pink",
                data: { doNothing, error, response: error.response },
            });
            if (doNothing) return Promise.reject(error);

            removeTokensFromStorage();

            storeCurrentUrl();
            mixpanelService.track(LOGOUT, {
                data: error,
            });

            authenticationService.clearSession();
            locationRouterService.navigate(locations.authRedirLocation);
        }
        return Promise.reject(error);
    };
}

function addTokenIfNecessary({ authenticationService }) {
    return async config => {
        try {
            if (authenticationService.isLoggedIn()) {
                if (authenticationService.isAccessTokenExpired()) {
                    logger.debug({
                        logGroupKey: ["AXIOS", "addTokenIfNecessary"],
                        msg: "Using token",
                        color: "pink",
                        data: "access token expired",
                    });
                    await authenticationService.refreshToken().catch(error => {
                        logger.debug({
                            logGroupKey: [
                                "AXIOS",
                                "addTokenIfNecessary",
                                "refreshExpired",
                            ],
                            msg: "Using token",
                            color: "pink",
                            data: { info: "Refresh token error", error },
                        });
                        storeCurrentUrl();
                        throw error;
                    });
                }
                const token = authenticationService.getToken();

                logger.debug({
                    logGroupKey: ["AXIOS", "addTokenIfNecessary"],
                    msg: "Using token",
                    color: "pink",
                    data: { token },
                });
                config.headers.Authorization = `Bearer ${token}`;
            }
        } catch (error) {
            logger.debug({
                logGroupKey: ["AXIOS", "addTokenIfNecessary"],
                msg: "Using token",
                color: "pink",
                data: { info: "Some problems with token", error },
            });
            authenticationService.clearSession();
        }

        return config;
    };
}
