import { CopyrightText } from "@/components/Copyright/CopyrightText";
import { Button, Card, Col, Forms, Gap, Text } from "@/components/DesignSystem";
import { isLoading } from "@/modules/loadable";
import { T, t } from "@/translations";
import moment from "moment";
import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { PM_FIRST_LOGIN_HELP } from "../../../constants/CommonConstats";
import LoginHeader from "../LoginHeader.component";
import { timeService } from "../services/time.service";
import styles from "../styles.less";
import { LoginError } from "./LoginError";

const { useForm, Form, Fields, SubmitButton, pmValidators } = Forms;

const SecondAuth = ({ userContext, loginMutation }) => {
    const [state, setState] = useState({
        serverTimeOffset: null,
        timer: null,
        time: moment(),
    });

    const { username, password, secret } = userContext;

    const recalculateTime = () => {
        return setState(state => ({
            ...state,
            time: moment().add(state.serverTimeOffset, "milliseconds"),
        }));
    };

    useEffect(() => {
        timeService
            .getServerTime()
            .then(({ data: serverTime }) => {
                const offset = moment(serverTime, "HH:mm:ss.ssssss").diff(
                    new Date(),
                );
                return Promise.resolve(offset);
            })
            .then(offset => {
                const timer = setInterval(recalculateTime, 1000);
                setState(state => ({
                    ...state,
                    serverTimeOffset: offset,
                    timer,
                }));
            });

        return () => {
            clearInterval(state.timer);
        };
    }, []);

    const secretUrl = `otpauth://totp/${username}?secret=${secret}&issuer=PriceF(x)`;

    const handleVerification = ({ values }) => {
        loginMutation.mutate({
            username,
            password,
            code: values.code,
            ...(userContext.newPassword
                ? { newPassword: userContext.newPassword }
                : {}),
        });
    };

    const { formId, handleSubmit } = useForm({
        onSubmit: handleVerification,
    });

    return (
        <div className="pmSecondAuth">
            <LoginError />
            <Card type="raised" spacing="large" styles={styles.card}>
                {secret && (
                    <div>
                        <h5>
                            <T id="login.verify.scan-qr-code" />
                        </h5>

                        <QRCode
                            size={300}
                            style={{
                                height: "auto",
                                maxWidth: "100%",
                                width: "100%",
                            }}
                            value={secretUrl}
                            viewBox={`0 0 300 300`}
                        />

                        <h5>
                            <T id="login.verify.scan-qr-code-problem-question" />
                        </h5>
                        <p>
                            <T id="login.verify.add-qr-code-details" />
                            <br />
                            <strong>
                                <T id="login.verify.account" />
                            </strong>{" "}
                            {username}
                            <br />
                            <strong>
                                <T id="login.verify.key" />
                            </strong>{" "}
                            {secret}
                            <br />
                            <strong>
                                <T id="login.verify.time-based" />
                            </strong>{" "}
                            <T id="login.verify.yes" />
                            <br />
                            <br />
                            <T
                                id="login.verify.time-warning"
                                values={{
                                    time: state.time.format("HH:mm:ss"),
                                }}
                            />
                            <br />
                        </p>
                    </div>
                )}

                <LoginHeader title={t("login.title")} />

                <Form formId={formId} layout="vertical" onSubmit={handleSubmit}>
                    <Fields.Input
                        label={t("login.verify.placeholder.verification-code")}
                        width="min"
                        id="pfx-code"
                        name="code"
                        validator={pmValidators.isRequired}
                        autoFocus
                        placeholder={t(
                            "login.verify.placeholder.verification-code",
                        )}
                    />

                    <SubmitButton withoutSpinner>
                        <Button
                            label={t("login.verify.button.verify")}
                            type="primary"
                            htmlType="submit"
                            loading={isLoading(loginMutation.loadable)}
                        />
                    </SubmitButton>
                </Form>
            </Card>
            <Gap size="large" />
            <Col type="flex" align="middle" className={styles.footerText}>
                <Text size="small" type="current">
                    {t("login.footer.need-help-question.text")}
                    <a
                        href={PM_FIRST_LOGIN_HELP}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {" "}
                        {t("login.footer.see-documentation.text")}
                    </a>
                    .
                </Text>

                <Gap size="small" />
                <CopyrightText />
            </Col>
        </div>
    );
};

SecondAuth.propTypes = {};

export default SecondAuth;
