import en from "./en";
import { defaultFormatters } from "./formatters";
import { translationsFactory } from "./translations.factory";

export const translationsService = translationsFactory("en", en);

export function t(messageId, params) {
    return translationsService.formatMessage(
        {
            id: messageId,
        },
        { ...defaultFormatters, ...params },
    );
}
