export const AUTH_SERVICE_URL =
    process.env.AUTH_PFX_SERVICE_URL ??
    "https://platform-login.plqa.pricefx.com";
export const issuer = new URL(`${AUTH_SERVICE_URL}`);
export const client_id = process.env.AUTH_PFX_CLIENT_ID ?? "pfm-qa";
export const client_secret = process.env.AUTH_PFX_CLIENT_SECRET ?? "password";
export const redirect_uri = window.location.origin;
export const code_challenge_method = "S256";
export const algorithm = "oidc";

export const client = {
    client_id,
    client_secret,
    token_endpoint_auth_method: "client_secret_post",
};
