import { useMemo } from "react";
import { createLocationsList } from "../location.utils";

export function useLocationsList(locations, mapperFn) {
    return useMemo(() => {
        const list = createLocationsList(locations);

        return mapperFn ? list.map(mapperFn) : list;
    }, [locations]);
}
