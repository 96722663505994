import { ButtonMenu, Modal, UnityLayout } from "@/components/DesignSystem";
import { ErrorBoundary } from "@/components/Error";
import PropTypes from "prop-types";
import React from "react";

const maybeButton = (label, customProps, defaultProps) =>
    label || customProps
        ? {
              label,
              ...defaultProps,
              ...customProps,
          }
        : undefined;

export const ConfirmModal = ({ visible, pending, config, handlers }) => {
    const {
        title,
        message,

        okText,
        okButtonProps,
        cancelText,
        cancelButtonProps,
        customButtons,

        autoFocusButton,
        width,
    } = config;
    const disabled = pending || !visible; // animation phase
    const okButton = maybeButton(okText, okButtonProps, {
        onClick: handlers.onConfirm,
        type: "primary",
        disabled,
    });
    const cancelButton = maybeButton(cancelText, cancelButtonProps, {
        onClick: handlers.onCancel,
        type: "text",
        disabled,
    });

    const buttons =
        typeof customButtons === "function"
            ? customButtons(handlers, { disabled })
            : customButtons
            ? customButtons
            : [okButton, cancelButton];

    return (
        <ErrorBoundary>
            <Modal
                visible={visible}
                onClose={disabled ? undefined : handlers.onClose}
                afterClose={handlers.afterClose}
                autoFocusButton={autoFocusButton}
                width={width}
                className="confirm-modal" // test selector, Modal does not pass data-test, renders in body and when container is changed, z-index cannot fix problems with other modals
                // data-test="confirm-modal"
            >
                <UnityLayout>
                    <UnityLayout.Header size={3} title={title} />
                    <UnityLayout.Content padding={[false, true]}>
                        {message}
                    </UnityLayout.Content>
                    <ErrorBoundary>
                        <UnityLayout.Footer
                            actionButtons={<ButtonMenu buttons={buttons} />}
                        />
                    </ErrorBoundary>
                </UnityLayout>
            </Modal>
        </ErrorBoundary>
    );
};

ConfirmModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    pending: PropTypes.bool,
    config: PropTypes.shape({
        title: PropTypes.node.isRequired,
        message: PropTypes.node,

        okText: PropTypes.string, // must be string, UC are calling .replace on it
        okButtonProps: PropTypes.object,
        cancelText: PropTypes.string,
        cancelButtonProps: PropTypes.object,
        customButtons: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),

        autoFocusButton: PropTypes.bool,
        width: PropTypes.number,
    }).isRequired,
    handlers: PropTypes.object.isRequired,
};
