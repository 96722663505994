import { Button, ButtonMenu, UnityLayout } from "@/components/DesignSystem";
import { filterVisibleButtons } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { map, pipe } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";

export const ModalOrNothing = ({
    isEnabled,
    children,
    actionButtons = [],
    alwaysWithButtons, // buttons with formId needs to have Component: SubmitButtonTempFix
}) => {
    if (isEnabled) {
        return (
            <>
                <UnityLayout.Content padding={[false, true]}>
                    {children}
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={<ButtonMenu buttons={actionButtons} />}
                />
            </>
        );
    } else {
        return (
            <>
                {children}
                {alwaysWithButtons &&
                    pipe(
                        filterVisibleButtons,
                        map(({ Component, ...buttonProps }) => (
                            <Button
                                key={buttonProps.label}
                                Component={Component}
                                {...buttonProps}
                            />
                        )),
                    )(actionButtons)}
            </>
        );
    }
};

ModalOrNothing.propTypes = {
    isEnabled: PropTypes.string,
    children: PropTypes.node,
    actionButtons: PropTypes.array,
    alwaysWithButtons: PropTypes.bool,
};
