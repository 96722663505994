import { Input } from "@/components/DesignSystem";
import { t } from "@/translations";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./Search.styles.less";

export const searchSizesEnum = {
    STANDARD: "STANDARD",
    RESPONSIVE: "RESPONSIVE",
};

export function Search({
    size = searchSizesEnum.RESPONSIVE,
    placeholder,
    className,
    onSearch,
    ...props
}) {
    const classes = cx(
        {
            pmSearchSize: true,
            pmSearchSizeStandard: size === searchSizesEnum.STANDARD,
        },
        className,
    );

    return (
        <Input
            {...props}
            placeholder={placeholder || t("search.placeholder")}
            className={classes}
            suffix={
                onSearch ? (
                    <SearchOutlined
                        onClick={onSearch}
                        style={{ padding: "6px" }}
                    />
                ) : null
            }
        />
    );
}

Search.propTypes = {
    className: PropTypes.string,
    onSearch: PropTypes.func,
    placeholder: PropTypes.node,
    size: PropTypes.string,
};
