import { Switch } from "@/components/DesignSystem";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    hasValue,
    isLoading,
    useMutationLoadableWithNotification,
} from "@/modules/loadable";
import { identity } from "lodash/fp";
import React, { useEffect, useRef, useState } from "react";

const createUseMutation =
    ({ performCall, success, error }) =>
    () =>
        useMutationLoadableWithNotification(performCall, [], success, error);

const defaultGetIsEnabled =
    enabledResponseProp =>
    ({ data, optimisticValue }) => {
        if (enabledResponseProp) return data?.[enabledResponseProp];
        else return optimisticValue;
    };

export const SwitchAsync = ({
    initialValue,
    onChange,
    performCall,
    success,
    error,
    useMutation = createUseMutation({ performCall, success, error }),
    from = identity,
    enabledResponseProp,
    getIsEnabled = defaultGetIsEnabled(enabledResponseProp),
    disabled,
    size = "small",
    ...props
}) => {
    const [checked, setChecked] = useState(initialValue);
    const { mutate, loadable } = useMutation() ?? {};
    const handleChange = checked => {
        setChecked(checked);
        mutate(from({ checked }));
        onChange?.(checked);
    };

    const loadableRef = useRef(loadable);
    loadableRef.current = loadable;
    const getIsEnabledCurrent = useCurrentHandler(getIsEnabled);
    useEffect(() => {
        const onResponse = loadable => {
            setChecked(optimisticValue =>
                hasValue(loadable)
                    ? getIsEnabledCurrent({
                          optimisticValue,
                          data: loadable.valueMaybe(),
                      })
                    : !optimisticValue,
            );
        };
        if (isLoading(loadable))
            return () => {
                if (!isLoading(loadableRef.current))
                    onResponse(loadableRef.current);
            };
    }, [getIsEnabledCurrent, loadable]);

    return (
        <Switch
            value={checked}
            onChange={handleChange}
            loading={isLoading(loadable)}
            disabled={disabled}
            size={size}
            {...props}
        />
    );
};
