import { useAppProperties } from "@/components/AppProperties/useAppProperties.hook";
import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { logger } from "@/modules/logger";
import { useRoute } from "@/modules/router/index";
import { removeTokensFromStorage } from "@/security/security";
import { appcuesIdentifyUser } from "@/utils/appcues";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { atom, useRecoilState } from "recoil";
import { TrackingContext } from "../mixpanel/TrackingContextProvider";
import { RESET } from "../reducers/reset_reducer";

const NOT_DEFINED_ACCOUNT_ID = -1;

export const userAccountId = atom({
    key: "userAccountId",
    default: NOT_DEFINED_ACCOUNT_ID,
});

const initialState = {
    basicInfo: null,
    context: null,
    alwaysRenderChildren: false,
};

const userReducer = (state = initialState, action) => {
    logger.debug({
        logGroupKey: ["USER", "UserContext", "reducer"],
        msg: action.type,
        color: "yellow",
        data: { state, action },
    });
    switch (action.type) {
        case "INIT_STATE":
            return initialState;
        case "REFETCH_USER":
            return {
                ...state,
                basicInfo: action.payload,
            };
        case "SET_ALWAYS_RENDER_CHILDREN":
            return {
                ...state,
                alwaysRenderChildren: action.payload,
            };

        default:
            return state;
    }
};

export const UserContext = React.createContext(initialState);

const UserContextComponent = ({ children, value = initialState }) => {
    const reduxDispatch = useDispatch();
    const [state, dispatch] = React.useReducer(userReducer, value);
    const [defaultAccountId, setDefaultAccountId] =
        useRecoilState(userAccountId);

    const { handleUserInfo } = useContext(TrackingContext);
    const { appPropertiesResource } = useAppProperties();
    const {
        axiosService,
        routerService,
        authenticationService,
        mixpanelService,
    } = useDic();
    const { route } = useRoute();

    routerService.subscribe(({ route }) => {
        if (route.params?.accountId) {
            setDefaultAccountId(parseInt(route.params.accountId, 10));
        }
    });

    const tryToAssignDefaultProject = useCallback(
        basicInfo => {
            if (defaultAccountId === NOT_DEFINED_ACCOUNT_ID) {
                if (route.params?.accountId) {
                    setDefaultAccountId(parseInt(route.params.accountId, 10));
                } else if (basicInfo.userInfo.user.defaultProjectId) {
                    setDefaultAccountId(
                        basicInfo.userInfo.user.defaultProjectId,
                    );
                } else {
                    setDefaultAccountId(
                        get(
                            basicInfo,
                            ["projects", "0", "id"],
                            NOT_DEFINED_ACCOUNT_ID,
                        ),
                    );
                }
            }
        },
        [defaultAccountId, route.params.accountId, setDefaultAccountId],
    );

    const refetchUserAndAppProperties = useCallback(() => {
        return axiosService
            .post("/api/authentication/user-basic-info")
            .then(({ data: basicInfo }) => {
                appcuesIdentifyUser(basicInfo);

                tryToAssignDefaultProject(basicInfo);
                appPropertiesResource.reload();

                dispatch({
                    type: "REFETCH_USER",
                    payload: basicInfo,
                });
                try {
                    handleUserInfo(basicInfo);
                } catch (e) {
                    console.log("--->", e);
                }
                return Promise.resolve(basicInfo);
            });
    }, [axiosService, handleUserInfo, tryToAssignDefaultProject]);

    useEffect(() => {
        if (authenticationService.isLoggedIn() && !state.basicInfo) {
            refetchUserAndAppProperties();
        }
    }, [authenticationService, refetchUserAndAppProperties, state.basicInfo]);

    const logout = useCurrentHandler(() => {
        console.log("%c[UserContext] logout", "color:crimson", {
            isLoggedIn: authenticationService.isLoggedIn(),
        });

        if (!authenticationService.isLoggedIn()) return;

        // reset global redux store
        reduxDispatch({ type: RESET });
        removeTokensFromStorage();

        dispatch({ type: "UNAUTHORIZED" });

        return authenticationService.logout();
    });
    const setAlwaysRenderChildren = useCallback(
        alwaysRenderChildren =>
            dispatch({
                type: "SET_ALWAYS_RENDER_CHILDREN",
                payload: alwaysRenderChildren,
            }),
        [],
    );

    return (
        <UserContext.Provider
            value={{
                context: state.context,
                basicInfo: state.basicInfo,
                logout,
                refetchUser: refetchUserAndAppProperties,
                setAlwaysRenderChildren,
            }}
        >
            {(!authenticationService.isLoggedIn() &&
                !state.alwaysRenderChildren) ||
            (authenticationService.isLoggedIn() && state.basicInfo)
                ? children
                : null}
        </UserContext.Provider>
    );
};

UserContextComponent.propTypes = {
    children: PropTypes.node.isRequired,
    value: PropTypes.object,
};

export default UserContextComponent;
