import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { noop } from "lodash/fp";
import { useMemo } from "react";
import { STATUSES } from "./useQueryLoadable.hook";

export const useMapLoadableMemoized = (
    loadable,
    mapper, // TODO: interface Loadable -> map
    onError = noop,
) => {
    const onErrorCurrent = useCurrentHandler(onError);
    return useMemo(() => {
        if (loadable.state === STATUSES.loading) {
            return loadable;
        }
        if (loadable.state === STATUSES.hasError) {
            onErrorCurrent(loadable.contents);
            return loadable;
        }
        if (loadable.state === STATUSES.hasValue) {
            const contents = mapper(loadable.contents);
            return { ...loadable, contents, valueMaybe: () => contents };
        }
    }, [loadable, mapper, onErrorCurrent]);
};
