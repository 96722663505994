import { useThrowErrorOnArrayLengthChange } from "@/components/hooks/useThrowErrorOnArrayLengthChange";
import { t } from "@/translations";
import { isBlank } from "@/utils/passwordUtils";
import { isEmailValid } from "@/utils/validation";
import { Forms } from "@pricefx/unity-components";
import { fromPairs, gte, lte, map, omit, values } from "lodash/fp";
import defaultTo from "lodash/fp/defaultTo";
import entries from "lodash/fp/entries";
import every from "lodash/fp/every";
import filter from "lodash/fp/filter";
import isBoolean from "lodash/fp/isBoolean";
import isEmpty from "lodash/fp/isEmpty";
import isNumber from "lodash/fp/isNumber";
import pick from "lodash/fp/pick";
import pipe from "lodash/fp/pipe";
import trim from "lodash/fp/trim";
import { stubTrue } from "lodash/stubTrue";
import { useCallback } from "react";

const { success, error } = Forms;

export const noValueFn = value => {
    if (Array.isArray(value)) {
        return isEmpty(value);
    } else if (isNumber(value)) {
        return isNaN(value);
    } else if (isBoolean(value)) {
        return false;
    } else {
        return !value;
    }
};

export const hasValueFn = x => !noValueFn(x);

export const isRequired = value => {
    return noValueFn(value)
        ? error(t("general.validation.required"))
        : success();
};

export const INTEGER_PATTERN = new RegExp(/^[0-9]*$/);

export const isInteger = value => {
    return createPatternValidator(
        INTEGER_PATTERN,
        t("general.validation.numbers-only"),
    )(value);
};

export const notBlank =
    (errorKey = "general.validation.blank") =>
    value =>
        isBlank(value) ? error(t(errorKey)) : success();

export const notStartsWith = str => value =>
    value.startsWith(str)
        ? error(t("general.validation.cannot-start-with", { str }))
        : success();

export const noWhiteSpaces = value =>
    /\s/.test(value)
        ? error(t("general.validation.no-white-spaces"))
        : success();

export const validatePasswordLength = minLength => value => {
    return value && value.length >= minLength
        ? success()
        : error(t("general.validation.password.minimal-length", { minLength }));
};

export const createMaxLengthValidation =
    (maxLength, translationKey = "general.validation.max-length") =>
    value =>
        value?.length <= maxLength
            ? success()
            : error(t(translationKey, { maxLength, length: value?.length }));

export const createMinLengthValidation =
    (
        minLength,
        { map = trim, messageKey = "general.validation.text.min-length" } = {},
    ) =>
    value =>
        map(value)?.length >= minLength
            ? success()
            : error(t(messageKey, { minLength }));

export const createForbidValuesValidation =
    (forbiddenValues = [], message = t("general.validation.value-forbidden")) =>
    value =>
        forbiddenValues.some(fVal => fVal === value)
            ? error(message)
            : success();

export const emailValid = email =>
    isEmailValid(email)
        ? success()
        : error(t("general.validation.email-not-valid"));

export const areEmailsValid = emails =>
    !emails || emails.every(email => isEmailValid(email))
        ? success()
        : error(t("general.validation.emails-not-valid"));

export const createUniqueValueValidation =
    (filterFn = stubTrue, message = t("general.validation.duplicated-value")) =>
    async (value, getBag) => {
        const { values } = await getBag();
        const entriesWithValidatedValue = Object.entries(values)
            .filter(([name, value]) => filterFn({ name, value }))
            .filter(valueEntry => value === valueEntry[1]);

        return entriesWithValidatedValue.length > 1
            ? Forms.error(message)
            : Forms.success();
    };

export const createUniqueFieldsValidation =
    (fieldNames, message = t("general.validation.duplicated-value")) =>
    async (validatedValue, getBag) => {
        const { values } = await getBag();
        const entriesWithValidatedValue = pipe(
            pick(fieldNames),
            entries,
            filter(([name, value]) => value === validatedValue),
        )(values);

        return entriesWithValidatedValue.length > 1
            ? Forms.error(message)
            : Forms.success();
    };

export const createAllowedValuesValidator =
    (
        allowedValues,
        message = notAllowed => "Not allowed values: " + notAllowed.join(", "),
    ) =>
    async value =>
        allowedValues.includes(value) ? Forms.success() : Forms.error(message);

export const createMultiAllowedValuesValidator =
    (allowedValues, message = "Not allowed value") =>
    async values => {
        const notAllowed = values.filter(
            value => !allowedValues.includes(value),
        );

        if (!notAllowed.length) return Forms.success();

        const messageString =
            typeof message === "function"
                ? message(notAllowed, allowedValues)
                : message;

        return Forms.error(messageString);
    };

export const createEachArrayItemValidator = (isValid, errorMessage) =>
    pipe(defaultTo([]), every(isValid), valid =>
        valid ? Forms.success() : Forms.error(errorMessage),
    );

export const orEmpty =
    validator =>
    (value, ...restArgs) => {
        return noValueFn(value) ? success() : validator(value, ...restArgs);
    };

const useCreateFieldValidator = ({ otherValues, message }) =>
    useCallback(
        value => {
            if (noValueFn(value) && otherValues.every(noValueFn))
                return error(message);
            else return success();
        },
        [message, otherValues, ...otherValues],
    );

export const useAtLeastOneRequired = ({
    formId,
    fieldNames: fieldNamesProp,
    message = "One of fields is required",
}) => {
    // number of fields stays same during component lifetime:
    const fieldNames = useThrowErrorOnArrayLengthChange(fieldNamesProp);
    const valuesMap = pipe(
        // eslint-disable-next-line prettier/prettier, react-hooks/rules-of-hooks
        map(name => [name, Forms.useFieldValue({ formId, name })]),
        fromPairs,
    )(fieldNames);

    return pipe(
        map(name => [
            name,
            // eslint-disable-next-line prettier/prettier, react-hooks/rules-of-hooks
            useCreateFieldValidator({
                otherValues: pipe(omit(name), values)(valuesMap),
                message,
            }),
        ]),
        fromPairs,
    )(fieldNames);
};

export const createPatternValidator =
    (re, msg = t("general.validation.pattern")) =>
    value => {
        const result = re.test(value) ? success() : error(msg);

        // https://medium.com/codesnips/js-careful-when-reusing-regex-636b92c6bf07
        re.lastIndex = 0;

        return result;
    };

export const durationMinimalMinutes = minutes => value => {
    return value && value.hour() * 60 + value.minute() >= minutes
        ? success()
        : error(t("general.validation.duration-minimal", { minutes }));
};

export const min =
    (min, comparator = gte) =>
    value => {
        return !comparator(value, min)
            ? error(t("general.validation.gte", { min }))
            : success();
    };

export const max =
    (max, comparator = lte) =>
    value => {
        return !comparator(value, max)
            ? error(t("general.validation.lte", { max }))
            : success();
    };

export const noValueOr = fn => value => {
    return noValueFn(value) ? success() : fn(value);
};

export const noValueOrConditional = (condition, fn) => value => {
    return !condition && noValueFn(value) ? success() : fn(value);
};

export const isValidUrl = value => {
    try {
        new URL(value);
        return success();
    } catch (_) {
        return error(t("general.validation.is-valid-url"));
    }
};

export const isValidJSON = value => {
    try {
        JSON.parse(value);
        return success();
    } catch (e) {
        return error(t("general.validation.is-valid-json"));
    }
};

export const isValidDomain = value => {
    try {
        const match = value.match(/^.+\..+$/);
        if (match) {
            return success();
        } else {
            throw new Error();
        }
    } catch (_) {
        return error(t("general.validation.is-valid-url"));
    }
};
