import { useCallback, useMemo, useRef, useState } from "react";
import uuid from "uuid/v4";

export function useDetailDrawerState(initialRecord, initialValue = undefined) {
    const idRef = useRef(uuid());
    const [record, setRecord] = useState(initialRecord);
    const [value, setValue] = useState(initialValue);
    const show = useCallback(
        (record, value) => {
            setRecord(record);
            setValue(value);
        },
        [setRecord, setValue],
    );
    const hide = useCallback(() => {
        setRecord(undefined);
        setValue(undefined);
    }, [setRecord, setValue]);
    const control = useMemo(
        () => ({ record, value, show, hide, id: idRef.current }),
        [record, show, value, hide],
    );

    return control;
}
