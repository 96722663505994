import isEqual from "lodash/isEqual";
import { useCallback, useEffect } from "react";
import { hooks } from "../Table.component";

// synchronizes parent selection state from props to table selection and back
// allows passing extra arguments to parentOnSelectionChange (useful for nested tables)
export const useSyncRowSelection = (
    parentSelection,
    parentOnSelectionChange,
    parentOnSelectionExtraArgs = [],
    isSelectionDisabled = () => false,
    useRowSelectionParams,
) => {
    const onSelectionChange = useCallback(
        selection => {
            if (!isEqual(parentSelection, selection)) {
                parentOnSelectionChange(
                    selection,
                    ...parentOnSelectionExtraArgs,
                );
            }
        },
        [
            parentOnSelectionChange,
            parentSelection,
            ...parentOnSelectionExtraArgs,
        ],
    );

    const rowSelection = hooks.useRowSelection({
        selectedRowKeys: parentSelection,
        onSelectionChange,
        ...useRowSelectionParams,
    });
    useEffect(() => {
        if (!isEqual(rowSelection.selectedRowKeys, parentSelection)) {
            rowSelection.changeRowKeys(parentSelection);
        }
    }, [rowSelection, parentSelection]);

    rowSelection.getCheckboxProps = record => ({
        disabled: isSelectionDisabled(record),
    });

    return rowSelection;
};
