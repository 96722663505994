import { Forms } from "@pricefx/unity-components";
import identity from "lodash/identity";
import React from "react";
import { InputNumber as DesignSystemInputNumber } from "../../index";

const { Field } = Forms;

export const InputNumber = props => {
    return <Field from={identity} as={DesignSystemInputNumber} {...props} />;
};
