import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
// assets
import "./Text.style.less";

const TEXT_PREFIX = "pmText--";

export function Text({ className, children, size, type, dataTest }) {
    const classes = cx(
        "pmText",
        `${TEXT_PREFIX}${size}`,
        className,
        `${TEXT_PREFIX}text-color-${type}`,
    );

    return (
        <p data-test={dataTest} className={classes}>
            {children}
        </p>
    );
}

Text.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    size: PropTypes.string,
    type: PropTypes.string,
    dataTest: PropTypes.string,
};

Text.defaultProps = {
    size: "medium",
    type: "primary",
    dataTest: "text-universal-data-test",
};
