import { Switch } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

export const SwitchWithState = ({
    defaultValue,
    value = defaultValue,
    ...props
}) => {
    const [checked, setChecked] = useState(defaultValue);

    useEffect(() => {
        setChecked(value);
    }, [value]);

    return (
        <Switch
            {...props}
            value={checked}
            onChange={checked => {
                setChecked(checked);
                props?.onChange(checked);
            }}
        />
    );
};

SwitchWithState.propTypes = {
    defaultValue: PropTypes.bool,
    value: PropTypes.bool,
    onChange: PropTypes.func,
};
