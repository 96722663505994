import { t } from "@/translations";
import { Table as UnityTable } from "@pricefx/unity-components";
import cx from "classnames";
import { isBoolean } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import uuid from "uuid/v4";
import "./Table.style.less";
import { HEADER_WITH_PAGE_TITLE } from "./constants";
import useColumnResizing from "./hooks/useColumnResizing.hook";

const {
    useExpandable,
    useLocalFiltering,
    useRowSelection,
    useServerFiltering,
    useColumnDragDrop,
    rowDragDrop,
    useColumnFreezing,
} = UnityTable;

export const hooks = {
    useExpandable,
    useRowSelection,
    useLocalFiltering,
    useServerFiltering,
    useColumnDragDrop,
    rowDragDrop,
    useColumnFreezing,
};

const formatCalc = height => `calc(100vh - ${height}px)`;

export const createEmptyColumn = (props = {}) => ({
    label: "",
    name: uuid(),
    render: () => null,
    ...props,
});

export function Table({
    className,
    condensed = false,
    columns: propColumns,
    hasColumnAutofit = false,
    hasColumnResizing = false,
    fixed = false,
    fixedHeight,
    restHeight,
    dataSource: propDataSource,
    pagination,
    rowKey,
    pmExpandable = {},
    headerContextMenu: propHeaderContextMenu = [],
    allExpanded: propAllExpanded,
    maybeExpandRecordInfo,
    ...props
}) {
    const [columns, setColumns] = useState(propColumns);
    //TODO: This is introducing state duplication and should be removed
    // since this state should be presented only in preferences #PFIM-4582
    const [isAllExpanded, setIsAllExpanded] = useState(
        propAllExpanded?.isAllExpandedDefault,
    );

    const columnResizing = useColumnResizing(hasColumnResizing, setColumns);
    const columnAutofit = hasColumnAutofit && { setColumns };

    useEffect(() => {
        setColumns(propColumns);
    }, [propColumns]);

    const {
        expandedRowRender: pmExpandedRowRender,
        expandedRowHeight: pmExpandedRowHeight = 300,
        rowExpandable: pmRowExpandable,
    } = pmExpandable;

    const { dataSource, expandable, setExpandedRowKeys } = useExpandable({
        dataSource: propDataSource,
        pagination,
        rowKey,
        hasExpandable: !!pmExpandedRowRender || !!propAllExpanded,
        expandedRowRender: pmExpandedRowRender,
        expandedRowHeight: pmExpandedRowHeight,
        rowExpandable: pmRowExpandable,
    });

    const toggleExpandAll = () => {
        setIsAllExpanded(state => {
            propAllExpanded?.onAllExpandedChange({ isAllExpanded: !state });
            return !state;
        });
    };

    useEffect(() => {
        if (isBoolean(propAllExpanded?.allExpandedPreference?.isAllExpanded)) {
            setIsAllExpanded(
                propAllExpanded?.allExpandedPreference?.isAllExpanded,
            );
        } else setIsAllExpanded(propAllExpanded?.isAllExpandedDefault);
    }, [propAllExpanded]);

    const allRowKeysStringified = useMemo(
        () => JSON.stringify((dataSource || []).map(r => r[rowKey])),
        [dataSource, rowKey],
    );

    const expandedRef = useRef(false);
    useEffect(() => {
        if (!setExpandedRowKeys) return;

        if (isAllExpanded) {
            setExpandedRowKeys(JSON.parse(allRowKeysStringified));
        } else if (maybeExpandRecordInfo && !expandedRef.current) {
            const keys = [maybeExpandRecordInfo.key];
            setExpandedRowKeys(keys);
            expandedRef.current = true;
        } else if (!expandedRef.current) {
            setExpandedRowKeys([]);
        }
    }, [
        isAllExpanded,
        allRowKeysStringified,
        setExpandedRowKeys,
        maybeExpandRecordInfo,
    ]);

    const headerContextMenu = useMemo(() => {
        return propAllExpanded
            ? [
                  ...propHeaderContextMenu,
                  {
                      anchor: isAllExpanded
                          ? t("general.collapse-all")
                          : t("general.expand-all"),
                      onClick: toggleExpandAll,
                      divider: true,
                  },
              ]
            : [...propHeaderContextMenu];
    }, [isAllExpanded, propAllExpanded, propHeaderContextMenu]);

    return (
        <div
            className={cx(
                "pmTable",
                className,
                !!props.rowSelection && "pmTable--rowSelection",
                condensed && "pmTable--condensed",
            )}
            style={
                fixed
                    ? {
                          width: `100%`,
                          height:
                              fixedHeight ||
                              formatCalc(restHeight || HEADER_WITH_PAGE_TITLE),
                      }
                    : {}
            }
        >
            <UnityTable
                columns={columns}
                columnAutofit={columnAutofit}
                columnResizing={columnResizing}
                fixed={fixed}
                dataSource={dataSource}
                pagination={pagination}
                rowKey={rowKey}
                expandable={expandable}
                headerContextMenu={headerContextMenu}
                {...props}
            />
        </div>
    );
}

Table.propTypes = {
    rowKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    className: PropTypes.string,
    condensed: PropTypes.bool,
    columns: PropTypes.array,
    hasColumnAutofit: PropTypes.bool,
    hasColumnResizing: PropTypes.bool,
    hasSettingsMenu: PropTypes.bool,
    fixed: PropTypes.bool,
    restHeight: PropTypes.number,
    fixedHeight: PropTypes.number,
    pmExpandable: PropTypes.object,
    dataSource: PropTypes.array,
};
