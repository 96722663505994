import { Forms as UCForms } from "@pricefx/unity-components";
import map from "lodash/fp/map";
import pipe from "lodash/fp/pipe";
import sum from "lodash/fp/sum";
import identity from "lodash/identity";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import {
    Forms,
    SelectWithAddItem as DesignSystemSelectWithAddItem,
} from "../../index";
import { useOptionsWithAddItem } from "../../Select";

const { Field } = UCForms;

const DEFAULT_LOOK_BACK_OPTIONS = [
    { label: "30 min", value: 30 },
    { label: "1 hour", value: 60 },
    { label: "2 hours", value: 120 },
    { label: "3 hours", value: 180 },
    { label: "4 hours", value: 240 },
    { label: "5 hours", value: 300 },
    { label: "6 hours", value: 360 },
    { label: "12 hours", value: 720 },
    { label: "24 hours", value: 1440 },
];

const euclideanDivide = (dividend, divisor) => {
    const quotient = Math.floor(dividend / divisor);
    const remainder = dividend % divisor;
    return [quotient, remainder];
};

const tokenize = (str = "") => {
    const [match, g1, g2, g3] =
        str
            .replace(/\s+/g, "")
            .match(/^(\d+[mhd])(\d+[mhd])?(\d+[mhd])?$/, " ") ?? [];

    const res = [g1, g2, g3].filter(Boolean);

    if (!match || !res.length) throw new Error(`Cannot parse ${str}`);

    return res;
};

const serialize = token => {
    const [match, num, str] =
        token.replace(/\s/g, "").match(/^(\d+)([mhd])$/) ?? [];
    if (!match) return NaN;

    const minutes = parseInt(num, 10) * { m: 1, h: 60, d: 24 * 60 }[str];
    return minutes;
};

const toMinutes = (input = "") => {
    try {
        const result = pipe(tokenize, map(serialize), sum)(input);
        return result;
    } catch (e) {
        return NaN;
    }
};

const fromMinutes = minutes => {
    const multipliers = [
        ["d", 24 * 60],
        ["h", 60],
        ["m", 1],
    ];
    const [humanReadable] = multipliers.reduce(
        ([humanReadable, restMins], [char, divisor]) => {
            const [quotient, reminder] = euclideanDivide(restMins, divisor);
            const token = quotient ? `${quotient}${char}` : "";

            return [(humanReadable += token), reminder];
        },
        ["", minutes],
    );
    return humanReadable;
};
const humanizedOption = minutes => ({
    value: minutes,
    label: fromMinutes(minutes),
});

const lookBackInputToOption = input => ({
    label: fromMinutes(toMinutes(input)),
    value: toMinutes(input),
});

const addDeduplicated = (options, option) =>
    options.some(({ value }) => value === option.value)
        ? options
        : [...options, option];

const notNaN = value =>
    isNaN(toMinutes(value))
        ? Forms.error("Cannot be parsed. Valid examples: 1d, 2h, 30m")
        : Forms.success();

export const lessThanDay = days => value =>
    toMinutes(value) <= days * 24 * 60
        ? Forms.success()
        : Forms.error(`Max ${days} day${value > 1 ? "s" : ""}]`);

export const SelectTimeWithAdd = ({
    formId,
    name,
    addItemValidator: userAddItemValidator,
    ...props
}) => {
    const [lookBackOptions, addLookBackOption] = useOptionsWithAddItem(
        DEFAULT_LOOK_BACK_OPTIONS,
        addDeduplicated,
    );
    const lookBackMinutes = Forms.useFieldValue({
        formId,
        name,
    });
    const lookBackOptionsWithCustom =
        !lookBackMinutes ||
        lookBackOptions.some(({ value }) => value === lookBackMinutes)
            ? lookBackOptions
            : lookBackOptions.concat(humanizedOption(lookBackMinutes));

    const addItemValidator = useMemo(
        () =>
            Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                notNaN,
                userAddItemValidator,
            ]),
        [userAddItemValidator],
    );

    return (
        <Field
            as={DesignSystemSelectWithAddItem}
            from={identity}
            name={name}
            options={lookBackOptionsWithCustom}
            onAddNewItem={addLookBackOption}
            inputToOption={lookBackInputToOption}
            addItemFieldProps={{
                validator: addItemValidator,
            }}
            {...props}
        />
    );
};

SelectTimeWithAdd.propTypes = {
    addItemValidator: PropTypes.func,
    formId: PropTypes.any,
    name: PropTypes.any,
};
