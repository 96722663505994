import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import React from "react";
import { NewPassword } from "../../../components/NewPassword/NewPassword.component";
import { LoginError } from "./LoginError";

const FirstPassword = ({ userContext, loginMutation }) => {
    const setFirstPassword = values => {
        loginMutation.mutate({
            username: userContext.username,
            password: userContext.password,
            newPassword: values.password,
        });
    };

    return (
        <div className="pmFirstPassword">
            <LoginError />
            <NewPassword
                isLoading={isLoading(loginMutation.loadable)}
                onSubmit={setFirstPassword}
                subtitle={t("login.new-password.subtitle")}
                showBack={false}
            />
        </div>
    );
};

export default FirstPassword;
