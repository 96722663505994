import { Button } from "@/components/DesignSystem";
import { DraggerVariant } from "@/components/Upload/Upload";
import { ReactComponent as Trash } from "@pricefx/unity-components/src/icons/unicons/trash-alt.svg";
import PropTypes from "prop-types";
import React, { useState } from "react";

const FormUpload = ({ multiple = false, onChange, ...rest }) => {
    const [fileList, setFileList] = useState([]);

    return (
        <DraggerVariant
            listType="text"
            variant="withIcon"
            fileList={fileList}
            customRequest={({ onSuccess }) => {
                setTimeout(() => {
                    onSuccess("ok");
                }, 0);
            }}
            onChange={({ fileList }) => {
                const newFileList = fileList.map(file => ({
                    ...file,

                    url: URL.createObjectURL(file.originFileObj),
                }));
                setFileList(() => newFileList);
                onChange?.(newFileList);
            }}
            multiple={multiple}
            showUploadList={{
                showDownloadIcon: false,
                showRemoveIcon: true,
                removeIcon: <Trash />,
            }}
            {...rest}
        >
            <Button label="Upload" />
        </DraggerVariant>
    );
};

FormUpload.propTypes = {
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
};

export default FormUpload;
