import { useCallback, useMemo, useState } from "react";

export function useVisibility(initialVisible = false) {
    const [visible, setVisible] = useState(initialVisible);
    const show = useCallback(() => setVisible(true), [setVisible]);
    const hide = useCallback(() => setVisible(false), [setVisible]);
    const toggle = useCallback(() => setVisible(!visible), [visible]);
    const visibility = useMemo(
        () => ({ visible, show, hide, toggle }),
        [visible, show, hide, toggle],
    );

    return visibility;
}
