import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";
import { Row } from "antd";

// assets
import "./ButtonGroup.style.less";

const B_PREFIX = "pmButtonGroup--spacing-";
const BUTTONS_PREFIX = "pmButtonGroup--buttonsSpacing-";

export function ButtonGroup({
    className,
    children,
    spacing,
    buttonsSpacing,
    buttonsPosition,
}) {
    const classes = cx(
        "pmButtonGroup",
        `${B_PREFIX}${spacing}`,
        `${BUTTONS_PREFIX}${buttonsSpacing}`,
        className,
    );

    return (
        <Row display="flex" justify={buttonsPosition} className={classes}>
            {children}
        </Row>
    );
}

ButtonGroup.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    spacing: PropTypes.string,
    buttonsSpacing: PropTypes.string,
    buttonsPosition: PropTypes.string,
};

ButtonGroup.defaultProps = {
    className: undefined,
    children: undefined,
    spacing: "small",
    buttonsSpacing: "24px",
};
