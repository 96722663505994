import { useRef } from "react";

export const useThrowErrorOnArrayLengthChange = array => {
    const ref = useRef(array);

    if (ref.current.length !== array.length) {
        throw "Not possible to change initial array length during component lifetime!";
    }

    return array;
};
