import { Container, H1, P } from "@/components/DesignSystem";
import { isProduction } from "@/utils/env.utils";
import PropTypes from "prop-types";
import React from "react";

export const ErrorBoundaryFallback = ({ componentStack, error }) => (
    <Container>
        <H1>Oops! An error occurred!</H1>
        <P>Please reload this page. Here’s what we know…</P>
        <P>
            <strong>Error:</strong> {error.toString()}
        </P>
        {!isProduction() && <pre>{componentStack}</pre>}
    </Container>
);

ErrorBoundaryFallback.propTypes = {
    componentStack: PropTypes.string.isRequired,
    error: PropTypes.object.isRequired,
};
