import { useDic } from "@/components/Dic/useDic.hook";
import { useMutationLoadableWithNotification } from "@/modules/loadable";
import { downloadFile } from "@/utils/downloadUtils";
import { map, matchesProperty, pipe, reject } from "lodash/fp";

export const buildExportColumns = pipe(
    reject(matchesProperty("visible", false)),
    reject(matchesProperty("exportHidden", true)),
    map(c => ({
        name: c.exportName || c.name,
        alias: c.label,
        condition: c.condition,
        skipParseDotNotation: c.skipParseDotNotation,
    })),
);

export const useExportMutation = ({
    exportUrl: defaultExportUrl = "missing-export-url",
    fileName: defaultFileName = "Export.csv",
    columns: defaultColumns,
} = {}) => {
    const { axiosService } = useDic();

    return useMutationLoadableWithNotification(
        async ({
            exportUrl = defaultExportUrl,
            fileName = defaultFileName,
            columns = defaultColumns,
            ...payload
        } = {}) =>
            axiosService
                .post(exportUrl, {
                    ...payload,
                    columns: buildExportColumns(columns),
                })
                .then(fileResponse => {
                    downloadFile(fileResponse.data, fileName);
                }),
        [axiosService, defaultColumns, defaultExportUrl, defaultFileName],
        false,
    );
};
