import { useContext, useEffect } from "react";
import { PageLayoutContext } from "./PageLayoutContext";

export const useSetExportButtonSettings = () => {
    const { setExportButtonSettings } = useContext(PageLayoutContext);

    useEffect(() => {
        return () => {
            setExportButtonSettings(null);
        };
    }, [setExportButtonSettings]);

    return { setExportButtonSettings };
};
