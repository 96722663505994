import PropTypes from "prop-types";
import React from "react";
import cx from "classnames";

// assets
import "./Gap.style.less";

const GAP_PREFIX = "pmGap--";

export function Gap({ className, size = "medium", children }) {
    const classes = cx("pmGap", `${GAP_PREFIX}${size}`, className);

    return <div className={classes}>{children} </div>;
}

Gap.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium", "large", "x-large", "huge"]),
    children: PropTypes.node,
};
