import moment from "moment-timezone";
import { findIana, findWindows } from "windows-iana";

const formatTimezone = tzString =>
    `(GMT${moment.tz(tzString).format("Z")}) ${tzString}`;

const getTimezoneProps = tzString => {
    const tz = moment.tz(tzString);
    const tzStringOffset = tz
        .format("Z")
        .replace(":00", "")
        .replace(":30", ".5");
    let x = tzStringOffset === 0 ? 0 : parseInt(tzStringOffset).toFixed(2);

    return {
        label: formatTimezone(tzString),
        value: `${tzString}`,
        time: `${x}`,
        offset: tz._offset,
    };
};

export const getTimeZonesOptions = () => {
    return moment.tz
        .names()
        .filter(transformIanaToWindows) // "CET" is not in IANA aliases: https://github.com/unicode-org/cldr/blob/main/common/bcp47/timezone.xml
        .map(getTimezoneProps);
};

export const getUserTimezone = () => {
    return moment.tz.guess();
};

export const transformIanaToWindows = ianaTimezone =>
    findWindows(ianaTimezone)?.[0];

export const transformWindowsToIana = (windowsTimezone, territory) =>
    findIana(windowsTimezone, territory);

export const trimTimezone = dateTimeZone =>
    dateTimeZone.replace(/([-+]\d{2}:\d{2})$/, "");
