import cloneDeep from "lodash/cloneDeep";
import pickBy from "lodash/pickBy";
import React from "react";

export const pickDefined = obj => pickBy(obj, val => val !== undefined);
export const findActivePref = (preferences = []) =>
    preferences.find(p => p.active);
export const applyPreferenceChanges = (preference, changedPreference) => {
    const json = JSON.stringify({
        ...preference?.viewState,
        ...pickDefined(changedPreference?.viewState),
    });
    return {
        ...preference,
        ...changedPreference,
        json,
        viewState: JSON.parse(json),
    };
};

export const addActionMenuRender = (columns, actionMenu) => {
    if (!actionMenu) return columns;

    let newColumns = cloneDeep(columns);
    const firstVisibleColumnIndex = newColumns.findIndex(
        column => !("visible" in column) || column.visible,
    );
    const originalRender = newColumns[firstVisibleColumnIndex].render;

    newColumns[firstVisibleColumnIndex].render = (
        text,
        record,
        index,
        formatter,
        column,
        prefix,
        suffix,
    ) => {
        return (
            <>
                {originalRender
                    ? originalRender(
                          text,
                          record,
                          index,
                          formatter,
                          column,
                          prefix,
                          suffix,
                      )
                    : formatter(text, record, column, prefix, suffix)}
                {actionMenu(record)}
            </>
        );
    };
    return newColumns;
};
