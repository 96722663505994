import { useDic } from "@/components/Dic/useDic.hook";
import { logger } from "@/modules/logger";
import {
    UnauthorizedContent,
    VisibleWhenHasPermission,
} from "@/security/authorization";
import { storeCurrentUrl } from "@/security/security";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import { useRoute } from "..";
import { isRouteActive } from "../router.utils";

export function Route({
    name,
    component,
    permission,
    permissionFunc,
    exact = false,
    unauthorizedContent = UnauthorizedContent,
    authenticatedOnly = true,
    ...props
}) {
    const {
        locationRouterService,
        authenticationService,
        locations: { authRedirLocation, defaultRouteLocation },
    } = useDic();
    const { route } = useRoute();
    const routeName = route && route.name;
    const isActive = useMemo(
        () => !!route && isRouteActive(name, exact, route),
        [name, routeName],
    );
    const isDefaultLocation = route.name === defaultRouteLocation.routeName;

    useEffect(() => {
        if (
            !!route &&
            isRouteActive(name, exact, route) &&
            authenticatedOnly &&
            !authenticationService.isLoggedIn()
        ) {
            logger.debug({
                logGroupKey: ["ROUTING", "Route", "eff"],
                color: "deeppink",
                msg: `Navigating to loginLocation${
                    !isDefaultLocation ? " and storing URL" : ""
                }`,
                data: {
                    isAuthenticated: authenticationService.isLoggedIn(),
                    isDefaultLocation,
                    name,
                    route,
                },
            });
            if (!isDefaultLocation) storeCurrentUrl();
            locationRouterService.navigate(authRedirLocation);
        }
    }, [
        name,
        exact,
        route,
        isDefaultLocation,
        authenticatedOnly,
        authenticationService,
        locationRouterService,
        authRedirLocation,
    ]);

    if (isRouteActive(name, exact, route))
        logger.debug({
            logGroupKey: ["ROUTING", "Route", "rndr"],
            color: "deeppink",
            msg: `active route (${routeName} - ${route?.path})`,
            data: {
                isAuthenticated: authenticationService.isLoggedIn(),
                isActiveMemo: isActive,
                isActive: isRouteActive(name, exact, route),
                isDefaultLocation,
                name,
                route,
                authenticatedOnly,
            },
        });

    if (!isActive) {
        return null;
    }

    const Com = component;

    if (authenticatedOnly && !authenticationService.isLoggedIn()) {
        return <UnauthorizedContent />;
    }

    return permission || permissionFunc ? (
        <VisibleWhenHasPermission
            permission={permission}
            permissionCheckFunc={permissionFunc}
            unauthorizedContent={unauthorizedContent}
        >
            <Com $route={route} {...props} />
        </VisibleWhenHasPermission>
    ) : (
        <Com $route={route} {...props} />
    );
}

Route.propTypes = {
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
        .isRequired,
    exact: PropTypes.bool,
    name: PropTypes.string.isRequired,
    permissionFunc: PropTypes.func,
    permission: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    unauthorizedContent: PropTypes.func,
    authenticatedOnly: PropTypes.bool,
};
