import { Tabs as UnityTabs } from "@pricefx/unity-components";
import PropTypes from "prop-types";
import React from "react";
import "./tabs.global.style.less";

export const Tabs = ({ fullSize = true, ...rest }) => (
    <UnityTabs fullSize={fullSize} {...rest} />
);

export const TabPanePadded = {};

Tabs.propTypes = {
    fullSize: PropTypes.bool,
};

Tabs.TabPane = ({ visible = true, children, ...props }) =>
    !visible ? null : (
        <UnityTabs.TabPane
            layout="tabs"
            // FIXME: passing className or wrapper for child not working in Unity
            {...props}
        >
            {children}
        </UnityTabs.TabPane>
    );

Tabs.TabPane.propTypes = {
    visible: PropTypes.bool,
};
