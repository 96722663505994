// import React from "react";
import { wrapComponent } from "../utils";

/**
 * Component which enforces every image to have description
 */
const Image = wrapComponent("img", {
    className: "ucImage"
});

export default Image;
