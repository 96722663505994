import {
    algorithm,
    client,
    code_challenge_method,
    issuer,
    redirect_uri,
} from "@/components/Authentication/constants";
import { logger } from "@/modules/logger";
import * as oauth from "oauth4webapi";
const getCurrentUnixTimestamp = () => Math.floor(Date.now() / 1000);

const lgk = (tail = []) => ["AUTH", "oidc", "service", ...tail];

// for localhost testing
// export const LOGIN_URI = `${AUTH_SERVICE_URL}/login`;
export const LOGIN_URI = `/login`;
export class AuthenticationService {
    _as = null;
    _initialized = false;
    _initPromise = null;
    _refreshPromise = null;

    _nonce;

    constructor() {
        this._initPromise = this.initialize();
    }

    async initialize() {
        this._as = await oauth
            .discoveryRequest(issuer, {
                algorithm,
            })
            .then(response => oauth.processDiscoveryResponse(issuer, response))
            .catch(error => {
                logger.debug({
                    logGroupKey: lgk(["initialize", "catch"]),
                    color: "chocolate",
                    data: { error },
                });
            });

        this._initialized = true;
    }

    _getAuthInfo() {
        return JSON.parse(localStorage.getItem("auth-service"));
    }

    getToken() {
        return this._getAuthInfo()?.access_token;
    }

    async redirectToAuthServer() {
        await this._initPromise;
        const code_verifier = oauth.generateRandomCodeVerifier();
        sessionStorage.setItem("code_verifier", code_verifier);
        const code_challenge = await oauth.calculatePKCECodeChallenge(
            code_verifier,
        );

        // redirect user to as.authorization_endpoint
        const authorizationUrl = new URL(this._as.authorization_endpoint);
        authorizationUrl.searchParams.set("client_id", client.client_id);
        authorizationUrl.searchParams.set("redirect_uri", redirect_uri);
        authorizationUrl.searchParams.set("response_type", "code");
        authorizationUrl.searchParams.set("scope", "openid");
        authorizationUrl.searchParams.set("code_challenge", code_challenge);
        authorizationUrl.searchParams.set(
            "code_challenge_method",
            code_challenge_method,
        );

        /**
         * We cannot be sure the AS supports PKCE so we're going to use nonce too. Use of PKCE is
         * backwards compatible even if the AS doesn't support it which is why we're using it regardless.
         */
        if (
            this._as.code_challenge_methods_supported?.includes(
                code_challenge_method,
            ) !== true
        ) {
            this._nonce = oauth.generateRandomNonce();
            authorizationUrl.searchParams.set("nonce", this._nonce);
        }

        // now redirect the user to authorizationUrl.href
        window.location = authorizationUrl.href;
    }

    async _refreshToken() {
        await this._initPromise;
        const refresh_token = this._getAuthInfo()?.refresh_token;

        if (refresh_token) {
            await this._initPromise;

            const response = await oauth.refreshTokenGrantRequest(
                this._as,
                client,
                refresh_token,
            );

            let challenges;
            if ((challenges = oauth.parseWwwAuthenticateChallenges(response))) {
                for (const challenge of challenges) {
                    console.error("WWW-Authenticate Challenge", challenge);
                }
                throw new Error(); // Handle WWW-Authenticate Challenges as needed
            }

            const result = await oauth.processRefreshTokenResponse(
                this._as,
                client,
                response,
            );
            if (oauth.isOAuth2Error(result)) {
                logger.debug({
                    logGroupKey: lgk(["oauth", "error"]),
                    color: "chocolate",
                    data: { result },
                });
                throw new Error(); // Handle OAuth 2.0 response body error
            }
            logger.debug({
                logGroupKey: lgk(["refreshToken", "response"]),
                color: "chocolate",
                data: { result },
            });

            this._setLocalStorage(result);
            this._refreshPromise = null;
        }
    }
    async refreshToken() {
        logger.debug({
            logGroupKey: lgk(["oauth", "isLoggedIn", "refreshToken"]),
            color: "chocolate",
            data: { refreshPromise: this._refreshPromise },
        });

        if (!this._refreshPromise) {
            this._refreshPromise = this._refreshToken();
            logger.debug({
                logGroupKey: lgk(["oauth", "isLoggedIn", "refreshToken"]),
                color: "chocolate",
                data: { refreshPromise: this._refreshPromise },
            });
        }
        return this._refreshPromise;
    }

    isLoggedIn() {
        logger.debug({
            logGroupKey: lgk(["oauth", "isLoggedIn", "response"]),
            color: "chocolate",
            data: { isLoggedIn: this.getToken() !== undefined },
        });

        return this.getToken() !== undefined;
    }

    isAccessTokenExpired() {
        const data = this._getAuthInfo();
        return data?.exp < getCurrentUnixTimestamp();
    }

    _setLocalStorage(authInfo) {
        localStorage.setItem(
            "auth-service",
            JSON.stringify({
                exp: getCurrentUnixTimestamp() + authInfo.expires_in,
                ...authInfo,
            }),
        );
    }

    async gainToken() {
        const currentUrl = new URL(window.location);

        if (currentUrl.searchParams.get("code")) {
            try {
                await this._initPromise;

                const code_verifier = sessionStorage.getItem("code_verifier");
                sessionStorage.removeItem("code_verifier");

                const params = oauth.validateAuthResponse(
                    this._as,
                    client,
                    currentUrl,
                );
                if (oauth.isOAuth2Error(params)) {
                    console.error("Error Response", params);
                    throw new Error(); // Handle OAuth 2.0 redirect error
                }

                const response = await oauth.authorizationCodeGrantRequest(
                    this._as,
                    client,
                    params,
                    redirect_uri,
                    code_verifier,
                );

                let challenges;
                if (
                    (challenges =
                        oauth.parseWwwAuthenticateChallenges(response))
                ) {
                    for (const challenge of challenges) {
                        console.error("WWW-Authenticate Challenge", challenge);
                    }
                    throw new Error(); // Handle WWW-Authenticate Challenges as needed
                }

                const result =
                    await oauth.processAuthorizationCodeOpenIDResponse(
                        this._as,
                        client,
                        response,
                        this._nonce,
                    );
                if (oauth.isOAuth2Error(result)) {
                    console.error("Error Response", result);
                    throw new Error(); // Handle OAuth 2.0 response body error
                }

                this._setLocalStorage(result);

                // const claims = oauth.getValidatedIdTokenClaims(result);
            } catch (error) {
                logger.debug({
                    logGroupKey: lgk(["oauth", "error"]),
                    color: "chocolate",
                    data: { info: "TOKEN > gainToken error ", error },
                });
            }
        }
    }

    clearSession() {
        localStorage.removeItem("auth-service");
    }

    async logout() {
        const id_token_hint = this._getAuthInfo()?.id_token;
        const logoutUrl = `${this._as.end_session_endpoint}?id_token_hint=${id_token_hint}`;
        this.clearSession();
        window.location.href = logoutUrl;
    }
}
