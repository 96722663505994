import { t } from "@/translations";
import { Alert as UcAlert } from "@pricefx/unity-components";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
// assets
import "./Alert.style.less";

export const Alert = ({
    className,
    message,
    messageId,
    description,
    ...rest
}) => {
    const classes = cx("pmAlert", className, message && description && "big");

    return (
        <UcAlert
            className={classes}
            message={messageId ? t(messageId) : message}
            description={description}
            {...rest}
        />
    );
};

Alert.propTypes = {
    className: PropTypes.string,
    description: PropTypes.node,
    message: PropTypes.node,
    messageId: PropTypes.string,
};
