import { Alert } from "@/components/DesignSystem/Alert";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";

const DangerAlert = ({ message, messageId, ...props }) => {
    const alert = messageId ? t(messageId, { message }) : message;
    return alert ? <Alert {...props} message={alert} type="error" /> : "";
};

DangerAlert.propTypes = {
    message: PropTypes.string,
    messageId: PropTypes.string,
};
export default DangerAlert;
