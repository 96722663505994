import { Forms } from "@pricefx/unity-components";
import React from "react";

// TODO: waiting for UC update
// import { CheckboxGroup as DesignSystemCheckboxGroup } from "../../index";

const DesignSystemCheckboxGroup = () => {
    return (
        <div>
            <a
                href={
                    "https://developer.pricefx.eu/internal/storybook/develop/?path=/docs/data-entry-checkbox-group--documentation"
                }
            >
                TODO: update UC to get CheckboxGroup
            </a>
        </div>
    );
};

export const CheckboxGroup = ({ ...props }) => {
    return (
        <Forms.Field
            as={DesignSystemCheckboxGroup}
            // valueProp="checked"
            // from={({ target: { checked } }) => checked}
            {...props}
        />
    );
};
