import { Col, Gap, Row } from "@/components/DesignSystem";
import { colors } from "@/components/DesignSystem/colors";
import Progress from "@/components/Progress/Progress";
import {
    PROGRESS_STATUS,
    useUploadStorage,
} from "@/components/Upload/useUploadStorage.hook";
import { useDisplayMessage } from "@/components/hooks/useDisplayMessage.hook";
import { t } from "@/translations";
import { formatFilesizeBytes } from "@/utils/formats/bytes";
import { CloseOutlined } from "@ant-design/icons";
import cx from "classnames";
import { noop } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import DangerAlert from "./DangerAlert";
import Dragger from "./Dragger.component";
import DraggerWithIcon from "./DraggerWithIcon.component";
import styles from "./Upload.less";

export const DefaultErrorComponent = ({ errorMessage }) => {
    if (errorMessage)
        return (
            <>
                <DangerAlert message={errorMessage} />
                <Gap size="small" />
            </>
        );
    return null;
};

export const NotificationErrorComponent = ({ errorMessage }) => {
    useDisplayMessage("error", !!errorMessage, errorMessage);

    return null;
};

export const DraggerVariant = ({ variant, ...draggerProps }) => {
    switch (variant) {
        case "withIcon":
            return <DraggerWithIcon {...draggerProps} />;
        case "default":
        default:
            return <Dragger {...draggerProps} />;
    }
};

const Upload = ({
    storage,
    className,
    hintMessage = "",
    variant = "default",
    ErrorComponent = DefaultErrorComponent,
    onError = noop,
    onUploadStart,
    onUploadError,
    onAbort: onAbortPassed,
    // S3 params
    destination,
    destinationId,
    // non S3 params
    onFileUploaded,
    // others
    ...draggerProps
}) => {
    const { maxFileSize, state, onAbort, onChange, processFile } =
        useUploadStorage({
            storageVariant: storage,
            onUploadStart,
            onUploadSuccess: onFileUploaded,
            onUploadError,
            onError,
            onAbort: onAbortPassed,
            destination,
            destinationId,
        });

    return (
        <div
            className={cx({
                pmUpload: true,
                pmUploadWithIcon: variant === "withIcon",
                className,
            })}
        >
            <ErrorComponent errorMessage={state.errorMessage} />

            <DraggerVariant
                variant={variant}
                {...draggerProps}
                showUploadList={false}
                onChange={onChange}
                customRequest={processFile}
                hintMessage={
                    <>
                        {hintMessage ? (
                            <>
                                {hintMessage}
                                <br />
                            </>
                        ) : (
                            hintMessage
                        )}
                        {maxFileSize > 0
                            ? `Max. file size limit is ${formatFilesizeBytes(
                                  maxFileSize,
                              )}`
                            : null}
                    </>
                }
            />

            {state.progress?.file?.status && (
                <div className={styles.uploadProgressBox}>
                    <Row>
                        <Col span={10}>{state.progress.file.name}</Col>
                        <Col
                            className={styles.textAlignRight}
                            offset={12}
                            span={2}
                        >
                            <CloseOutlined onClick={onAbort} />
                        </Col>
                    </Row>
                    <Progress
                        strokeColor={
                            state.progressStatus === PROGRESS_STATUS.EXCEPTION
                                ? undefined
                                : colors.primary
                        }
                        status={state.progressStatus}
                        percent={state.progress.file.percent}
                        message={
                            formatFilesizeBytes(
                                (state.progress.file.size / 100) *
                                    state.progress.file.percent,
                            ) +
                            " " +
                            t("upload-filesize-of") +
                            " " +
                            formatFilesizeBytes(state.progress.file.size) +
                            " (" +
                            Math.round(state.progress.file.percent) +
                            "% " +
                            t("upload-filesize-progress-done") +
                            ")"
                        }
                    />
                </div>
            )}
        </div>
    );
};

Upload.propTypes = {
    accountId: PropTypes.number,
    accept: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.string,
    hintMessage: PropTypes.node,
    onFileUploaded: PropTypes.func.isRequired,
    ErrorComponent: PropTypes.elementType,
    onError: PropTypes.func,
    onAbort: PropTypes.func,
};

export default Upload;
