const pfxColors = {
    white: "#ffffff",

    blue1: "#EAF5FF",
    blue2: "#81CFFF",
    blue3: "#0099CD",
    blue4: "#0078A2",
    blue5: "#005979",

    green1: "#F1FCEC",
    green2: "#95DA7C",
    green3: "#36A300",
    green4: "#298000",

    gold1: "#FFFBE6",
    gold2: "#FFF1B8",
    gold3: "#FFE58F",
    gold4: "#FFD666",

    red1: "#FFF0F1",
    red2: "#FFB2B7",
    red3: "#FF4B5D",
    red4: "#E00031",
};

export const colors = {
    white: pfxColors.white,
    primary: pfxColors.blue4,
    secondary: pfxColors.blue3,
    hover: pfxColors.blue3,
    active: pfxColors.blue5,
    backgroundHover: pfxColors.blue1,
    border: pfxColors.blue2,

    info: {
        primary: pfxColors.blue4,
        primaryHover: pfxColors.blue3,
        border: pfxColors.blue2,
        background: pfxColors.blue1,
    },
    success: {
        primary: pfxColors.green4,
        primaryHover: pfxColors.green3,
        border: pfxColors.green2,
        background: pfxColors.green1,
    },
    warning: {
        primary: pfxColors.gold4,
        primaryHover: pfxColors.green3,
        border: pfxColors.gold2,
        background: pfxColors.gold1,
    },
    danger: {
        primary: pfxColors.red4,
        primaryHover: pfxColors.red3,
        border: pfxColors.red2,
        background: pfxColors.red1,
    },
};
