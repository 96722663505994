import { Authorized } from "@/components/Authorized/Authorized";
import { DropdownMenu, UnityMenu as Menu } from "@/components/DesignSystem";
import {
    hasValue,
    isLoading,
    useMapLoadableMemoized,
} from "@/modules/loadable";
import { HasValueLoadable } from "@/modules/loadable/useQueryLoadable.hook";
import { hasError, isResourceWithReload } from "@/modules/loadable/utils";
import { useCustomModal } from "@/modules/modal/CustomModal";
import { useConfirmModal } from "@/modules/modal/imperative/useConfirmModal.hook";
import { SecurityContext } from "@/security/authorization";
import { includesPermission } from "@/security/permission.utils";
import { ReactComponent as EllipsisH } from "@pricefx/unity-components/src/icons/unicons/ellipsis-h.svg";
import { Skeleton as AntdSkeleton } from "antd";
import cx from "classnames";
import { isEmpty } from "lodash";
import { filter, identity, map, pipe } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useMemo } from "react";
import { useTrackButtonClick } from "../../../../../mixpanel/hooks/useTrackButtonClick.hook";
import "./ActionButton.style.less";

// PRIMARY, DANGER?
export const CONFIRM_TYPE = {
    DELETE: "DELETE",
    CONFIRM: "CONFIRM",
};

export const useWithConfirm = () => {
    const confirmModal = useConfirmModal();
    const createWithConfirm =
        ({
            type,
            title,
            message,
            okText,
            cancelText,
            onConfirm,
            onCancel,
            onHandlerError,
        }) =>
        () => {
            const config = {
                title,
                message,
                okText,
                cancelText,
                onConfirm,
                onCancel,
                onHandlerError,
            };
            if (type === CONFIRM_TYPE.DELETE)
                return confirmModal.confirmDelete(config);

            return confirmModal.confirm(config);
        };

    return useCallback(createWithConfirm, [confirmModal]);
};

const Placeholder = () => (
    <Menu className="pmTableActionButton-placeholder">
        {Array.from(Array(4).keys()).map(key => (
            <Menu.Item key={key}>
                <AntdSkeleton
                    active
                    title={false}
                    round={false}
                    paragraph
                    rows={1}
                />
            </Menu.Item>
        ))}
    </Menu>
);

const createMapItem = (withConfirm, customModal, trackHandler, record) => {
    const mapItem = item => {
        let mappedItemProps = {};
        const maybeTrack = item.track === false ? identity : trackHandler;

        if (item.disabled) {
            mappedItemProps.disabled = true;
        }

        if (item.customModal) {
            mappedItemProps.onClick = maybeTrack(
                () => customModal.show(item.customModal),
                { defaultName: item.title, ...item.track },
            );
        } else if (item.confirm || item.onConfirm) {
            const title =
                item.confirm?.title || item.confirmTitle || item.title;
            const trackConfig = {
                defaultName: title,
                ...item.track,
            };

            mappedItemProps.onClick = withConfirm(
                item.confirm
                    ? {
                          title,
                          type: CONFIRM_TYPE.DELETE,
                          ...item.confirm,
                          onConfirm: maybeTrack(
                              () => item.confirm.onConfirm(record),
                              trackConfig,
                          ),
                      }
                    : {
                          type: item.confirmType ?? CONFIRM_TYPE.DELETE,
                          title,
                          message: item.confirmMessage,
                          okText: item.confirmButtonTitle,
                          onConfirm: maybeTrack(
                              () => item.onConfirm(record),
                              trackConfig,
                          ),
                          cancelText: item.cancelButtonTitle,
                      },
            );
        } else if (item.onClick) {
            mappedItemProps.onClick = maybeTrack(() => item.onClick(record), {
                defaultName: item.title,
                ...item.track,
            });
        }

        if (item.color === "red") {
            mappedItemProps.className = "pmActionButtonItem--red";
        }
        if (item.items) {
            mappedItemProps.items = item.items
                .filter(item => !!item) // TODO: permissions + refactor
                .map(mapItem);
        }

        return {
            anchor: item.title,
            ...mappedItemProps,
        };
    };
    return mapItem;
};

export const filterVisibleButtons = pipe(
    filter(Boolean),
    filter(({ visible = true }) => !!visible),
);

const useMapActionButtons = ({ itemsLoadable, record }) => {
    const withConfirm = useWithConfirm();
    const customModal = useCustomModal();
    const securityContext = useContext(SecurityContext);
    const { trackHandler } = useTrackButtonClick();
    const itemsMapper = useCallback(
        pipe(
            filterVisibleButtons,
            filter(
                ({ permission }) =>
                    !permission ||
                    includesPermission(securityContext, permission),
            ),
            map(createMapItem(withConfirm, customModal, trackHandler, record)),
        ),
        [withConfirm, customModal, trackHandler, record, securityContext],
    );
    const mappedItemsLoadable = useMapLoadableMemoized(
        itemsLoadable,
        itemsMapper,
    );
    return { mappedItemsLoadable };
};

const ActionButtonInner = ({
    className,
    items: itemsOrResource = [],
    record,
    wrapperProps,
    buttonDataTest = "dropdown-button",
    overlayDataTest = "dropdown-overlay",
}) => {
    const itemsLoadable = useMemo(
        () =>
            isResourceWithReload(itemsOrResource)
                ? itemsOrResource.loadable
                : HasValueLoadable(itemsOrResource),
        [itemsOrResource],
    );
    const { mappedItemsLoadable } = useMapActionButtons({
        itemsLoadable,
        record,
    });
    const mappedItems = mappedItemsLoadable.valueMaybe() ?? [];

    const onClick = useCallback(
        e => {
            if (
                isResourceWithReload(itemsOrResource) &&
                hasError(itemsOrResource.loadable)
            ) {
                itemsOrResource.reload?.();
            }
            wrapperProps?.onClick?.(e);
        },
        [itemsOrResource, wrapperProps],
    );

    if (hasValue(mappedItemsLoadable) && isEmpty(mappedItems)) return null;

    return (
        <div
            className={cx("pmTableActionButton", className)}
            {...wrapperProps}
            onClick={onClick}
        >
            <DropdownMenu
                withArrow={false}
                button={{
                    type: "text",
                    icon: EllipsisH,
                    size: "small",
                    "data-test": buttonDataTest,
                }}
                overlay={{
                    component: isLoading(mappedItemsLoadable)
                        ? Placeholder
                        : hasValue(mappedItemsLoadable)
                        ? Menu
                        : () => null,
                    contextMenu: false,
                    items: mappedItems,
                    overlayDataTest,
                }}
            />
        </div>
    );
};

ActionButtonInner.propTypes = {
    className: PropTypes.string,
    record: PropTypes.object.isRequired,
    items: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    onClick: PropTypes.func,
                    permission: PropTypes.array,
                }),
                PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    confirmTitle: PropTypes.string,
                    confirmMessage: PropTypes.string,
                    onConfirm: PropTypes.func,
                    permission: PropTypes.array,
                }),
                PropTypes.any,
            ]),
        ),
        PropTypes.object,
    ]),
    wrapperProps: PropTypes.object,
};

export const ActionButton = ({
    permission,
    projectId,
    instanceId,
    partitionId,
    ...props
}) => {
    return (
        <Authorized
            permission={permission}
            projectId={projectId}
            instanceId={instanceId}
            partitionId={partitionId}
        >
            <ActionButtonInner {...props} />
        </Authorized>
    );
};

ActionButton.propTypes = {
    ...ActionButtonInner.propTypes,
    permission: PropTypes.array,
};
