import {
    Col,
    Gap,
    Progress as AntProgress,
    Row,
} from "@/components/DesignSystem";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./Progress.less";

const Progress = ({ percent, message, className, ...props }) => {
    return (
        <div className={cx("pmProgress", className)}>
            <Gap size="small" />
            <Row>
                <Col>
                    <span className="pmProgress-message">{message}</span>
                </Col>
            </Row>
            <Row>
                <AntProgress
                    strokeWidth={5}
                    strokeLinecap="square"
                    percent={percent}
                    showInfo={false}
                    {...props}
                />
            </Row>
        </div>
    );
};

Progress.propTypes = {
    className: PropTypes.string,
    percent: PropTypes.number.isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default Progress;
