import intersection from "lodash/intersection";
import union from "lodash/union";

import {
    INTEGRATION_EDIT_PERMISSIONS,
    INTEGRATION_READ_EDIT_PERMISSIONS,
} from "@/security/permission.utils";

const OVERVIEW_PERMISSIONS = ["integration.dashboard_logs.read"];

const PARTITION_OVERVIEW_PERMISSIONS = ["partition.activity_log.read"];

const containsAnyFunc = arr1 => arr2 => intersection(arr1, arr2).length > 0;

export const getDefaultIntegrationLocation = (
    integrationId,
    securityContext,
    accountLocationMap,
) => {
    const containsAny = containsAnyFunc(
        union(
            securityContext.globalPermissions,
            securityContext.projectSpecificPermissions,
            securityContext.getPermissions("INTEGRATION", integrationId),
        ),
    );

    if (containsAny(OVERVIEW_PERMISSIONS)) {
        return {
            location: accountLocationMap.instanceOverviewLocation,
            translationKey: "general.instance-overview",
        };
    }

    if (containsAny(INTEGRATION_READ_EDIT_PERMISSIONS)) {
        return {
            location: accountLocationMap.integrationsLocation,
            translationKey: "general.integrations",
        };
    }

    if (containsAny(INTEGRATION_EDIT_PERMISSIONS)) {
        return {
            location: accountLocationMap.settingsLocation,
            translationKey: "general.settings",
        };
    }

    return {
        location: accountLocationMap.instanceOverviewLocation,
        translationKey: "general.instance-overview",
    };
};

export const getDefaultPartitionLocation = (
    partitionId,
    securityContext,
    accountLocationMap,
) => {
    const containsAny = containsAnyFunc(
        union(
            securityContext.globalPermissions,
            securityContext.projectSpecificPermissions,
            securityContext.getPermissions("PARTITION", partitionId),
        ),
    );

    if (containsAny(PARTITION_OVERVIEW_PERMISSIONS)) {
        return {
            location: accountLocationMap.partitionOverviewLocation,
            translationKey: "general.partition-overview",
        };
    }

    return {
        location: accountLocationMap.partitionUploadsLocation,
        translationKey: "general.partition-uploads",
    };
};
