import { AUTH_SERVICE_URL } from "@/components/Authentication/constants";
import { CopyrightText } from "@/components/Copyright/CopyrightText";
import {
    Button,
    Card,
    Col,
    Forms,
    Gap,
    Row,
    Text,
} from "@/components/DesignSystem";
import { PM_DOCUMENTATION } from "@/constants/CommonConstats";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import { useFieldValue } from "@pricefx/unity-components/dist/es/components/Forms/hooks";
import cx from "classnames";
import React, { useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import LoginHeader from "../LoginHeader.component";
import { loginErrorState } from "../LoginPage";
import { LOGIN_ERROR_TYPE } from "../constants";
import styles from "../styles.less";
import { LoginError } from "./LoginError";
import { useLoginMethodsResource } from "./loadables";

const STEPS = {
    USERNAME: "USERNAME",
    PASSWORD: "PASSWORD",
};

const { useForm, Form, Fields, pmValidators } = Forms;

const Login = ({ error, loginMutation }) => {
    const [step, setStep] = useState(STEPS.USERNAME);
    const [params, setParams] = useState();
    const [touchedWithInput, setTouchedWithInput] = useState(false);
    const [, setError] = useRecoilState(loginErrorState);

    const handleLogin = ({ values }) => {
        loginMutation.mutate(values);
    };
    const handleNextStep = ({ values }) => {
        setParams({ username: values.username });
    };

    const { submit, formId, handleSubmit } = useForm({
        onSubmit: step === STEPS.USERNAME ? handleNextStep : handleLogin,
    });
    const usernameInput = useFieldValue({ formId, name: "username" });

    const loginMethodsResource = useLoginMethodsResource({
        params,
        onStart: () => setError(null),
        onSuccess: () => {
            setStep(STEPS.PASSWORD);
        },
        onError: axiosError =>
            setError({
                type: LOGIN_ERROR_TYPE.USERNAME,
                message: getErrorMessageFromError(axiosError),
            }),
    });

    const handleSamlLogin = () => {
        window.location.href =
            loginMethodsResource.loadable.valueMaybe()?.saml?.redirectUrl;
    };
    const canUseSaml =
        loginMethodsResource.loadable.valueMaybe()?.saml?.allowed;
    const loading =
        isLoading(loginMethodsResource) || isLoading(loginMutation.loadable);
    const usernameValidator = useMemo(
        () =>
            step === STEPS.USERNAME
                ? Forms.validators.failOnFirst([
                      pmValidators.isRequired,
                      pmValidators.emailValid,
                  ])
                : undefined,
        [step],
    );

    return (
        <div className={cx("pmLogin", styles.centeredContent)}>
            <LoginError error={error} />
            <Card type="raised" spacing="huge" className={styles.card}>
                <LoginHeader title={t("login.title")} />
                <Form formId={formId} layout="vertical" onSubmit={handleSubmit}>
                    <Fields.Input
                        hidden={step === STEPS.PASSWORD}
                        width="min"
                        id="pfx-username"
                        name="username"
                        data-test="username-input"
                        label={t("login.input.label.username")}
                        validator={usernameValidator}
                        autoFocus
                        disabled={loading}
                        placeholder={t("login.placeholder.username")}
                        extraComponent={
                            Forms.pmComponents.FieldValidationResult
                        }
                        showValidationStatus={
                            touchedWithInput ? "touched" : "never"
                        }
                        onBlur={() => {
                            if (usernameInput) {
                                setTouchedWithInput(true);
                            }
                        }}
                    />

                    <Fields.InputPassword
                        hidden={step === STEPS.USERNAME}
                        width="min"
                        id="pfx-password"
                        name="password"
                        data-test="password-input"
                        label={t("login.input.label.password")}
                        validator={
                            step === STEPS.PASSWORD
                                ? pmValidators.isRequired
                                : undefined
                        }
                        placeholder={t("login.placeholder.password")}
                        validateOnBlur={false}
                        validateOnMount={false}
                        disabled={loading}
                    />

                    <Row justify="space-between">
                        <Button
                            type="primary"
                            onClick={() => {
                                setTouchedWithInput(true);
                                submit();
                            }}
                            label={
                                step === STEPS.USERNAME
                                    ? "Next"
                                    : t("login.button.login")
                            }
                            data-test="login-button"
                            loading={loading}
                        />
                        {step === STEPS.USERNAME && (
                            <Button
                                type="default"
                                href={`${AUTH_SERVICE_URL}/oauth2/authorization/azure-dev`}
                                label={t("login.button.o365")}
                                data-test="O365login-button"
                            />
                        )}
                        {step === STEPS.PASSWORD && canUseSaml && (
                            <Button
                                type="default"
                                onClick={handleSamlLogin}
                                label={"Login with SSO"}
                                data-test="ssologin-button"
                            />
                        )}
                    </Row>
                </Form>
            </Card>
            <Gap size="large" />
            <Col type="flex" align="middle" className={styles.footerText}>
                <Text size="small" type="current">
                    {t("login.footer.need-help-question.text")}
                    <a
                        href={PM_DOCUMENTATION}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {" "}
                        {t("login.footer.see-documentation.text")}
                    </a>
                    .
                </Text>

                <Gap size="small" />
                <CopyrightText />
            </Col>
        </div>
    );
};

Login.propTypes = {};

export default Login;
