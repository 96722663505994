import { Forms } from "@pricefx/unity-components";
import identity from "lodash/identity";
import React from "react";
import { CheckGroupButton as DesignSystemCheckGroupButton } from "../../index";

const { Field } = Forms;

export const CheckGroupButton = props => {
    return (
        <Field as={DesignSystemCheckGroupButton} from={identity} {...props} />
    );
};
