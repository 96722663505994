import React from "react";
import { Collapse as AntCollapse } from "antd";

export function Collapse({ children, props }) {
    return <AntCollapse {...props}>{children} </AntCollapse>;
}

export const Panel = AntCollapse.Panel;

Collapse.Panel = Panel;
