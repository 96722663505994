import { InputNumber as UnityInputNumber } from "@pricefx/unity-components";
import PropTypes from "prop-types";
import React from "react";

export const InputNumber = ({ size = "middle", ...rest }) => {
    return <UnityInputNumber size={size} {...rest} />;
};

InputNumber.propTypes = {
    size: PropTypes.string,
};
