export const getErrorMessage = error => {
    const rawMessage = error?.messages?.[0] || error?.message || error?.error;
    const message = getPfxError(rawMessage);

    if (typeof message === "string") return addUUID(message, error.uuid);

    console.log("error message not parsed:", { error, message });
    return addUUID("Something went wrong", error?.uuid);
};

export const getPfxError = message => {
    if (
        typeof message === "string" &&
        message.startsWith("HTTP request failed")
    ) {
        let start = message.split("{", 1);
        return JSON.parse(message.replace(start, "")).response.data;
    }
    return message;
};

export const getErrorMessageFromError = e => {
    const message =
        e?.response?.data?.message ||
        e?.response?.data?.messages?.join?.(", ") ||
        e?.response?.data?.error ||
        e?.message;

    return message ? addUUID(message, e?.response?.data?.uuid) : "";
};

const formatUUID = uuid => `(UUID: ${uuid})`;
export const addUUID = (message, uuid) => {
    if (message && uuid) return `${message} ${formatUUID(uuid)}`;
    return message;
};
