import identity from "lodash/identity";
import isObject from "lodash/isObject";
import pick from "lodash/pick";
import reduce from "lodash/reduce";

export function createRoutesFromLocations(locations) {
    return createLocationsList(locations).map(l => {
        const { routeName, path } = l;

        if (!routeName || !path) {
            console.error("Invalid location", l); // eslint-disable-line no-console
        }

        return {
            name: l.routeName,
            path: l.path,
            defaultParams: l.defaultParams,
            forwardTo: l.forwardTo,
        };
    });
}

export function registerLocations(routerService, locations) {
    routerService.add(createRoutesFromLocations(locations));
}

export function mergeWithCurrentParams(routerService, routeName, params) {
    return {
        ...getParamsFromParents(routerService.getState(), routeName),
        ...params,
    };
}

export function isLocation(maybeLocation) {
    return isObject(maybeLocation) && maybeLocation.__isLocation;
}

export function getLocationRouteName(node, name) {
    const nodeName = isLocation(node) ? node.routeName : node;

    return getRouteName(nodeName, name);
}

export function getLocationPath(node, path) {
    return [node.completePath, path].join("");
}

export function createLocationsList(locations) {
    return isObject(locations) ? Object.values(locations) : locations;
}

function getRouteName(node, name) {
    return [node, name].filter(identity).join(".");
}

function getParamsFromParents(state, routeName) {
    const paramsToPick = reduce(
        state.meta.params,
        (acc, params, rn) => {
            // we want to add params only for routes which are parents of
            // the route we want to navigate to
            if (!routeName.startsWith(rn)) {
                return acc;
            }

            return [...acc, ...Object.keys(params)];
        },
        [],
    );

    return pick(state.params, paramsToPick);
}

export const getRootName = name => name.substring(0, name.indexOf("."));
