import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { cancelable } from "cancelable-promise";
import { noop, pipe } from "lodash/fp";
import React, { useRef } from "react";
import { useCallback, useEffect, useState, useMemo } from "react";
import uuid from "uuid/v4";

export const STATUSES = {
    loading: "loading",
    hasError: "hasError",
    hasValue: "hasValue",
};

const isLoading = ([status]) => status === STATUSES.loading;
const getPromise = ([status, promise]) =>
    isLoading([status]) && !promise.isCanceled() ? promise : undefined;
const getResponse = ([status, response]) =>
    status === STATUSES.hasValue ? response : undefined;

export const LoadingLoadable = () => ({
    state: STATUSES.loading,
    contents: cancelable(new Promise(() => {})),
    valueMaybe: () => undefined,
});

export const HasValueLoadable = value => ({
    state: STATUSES.hasValue,
    contents: value,
    valueMaybe: () => value,
});

export const HasErrorLoadable = error => ({
    state: STATUSES.hasError,
    contents: error,
    valueMaybe: () => undefined,
});

const createSuspendable = ({
    promise,
    createPromise,
    onSuccess,
    onError,
    debugLog,
}) => {
    debugLog("%c[createSuspendable.create]", "color:blue", {
        promise,
        createPromise,
    });
    let status = "pending";
    let result;
    const _promise = promise || createPromise();
    const suspender = _promise.then(
        response => {
            debugLog("%c[createSuspendable.success]", "color:green", {
                response,
            });
            status = "success";
            result = response;
            onSuccess?.(response);
            // return response;
        },
        error => {
            debugLog("%c[createSuspendable.error]", "color:crimson", {
                error,
            });
            status = "error";
            result = error;
            onError?.(error);
            // throw error;
        },
    );
    return {
        read() {
            debugLog("[createSuspendable.read]", { status, result });
            if (status === "pending") {
                throw suspender;
            } else if (status === "error") {
                throw result;
            }
            return result;
        },
        promise: suspender,
    };
};

export function useQueryLoadable(fn, deps = [], { debug = false } = {}) {
    const debugLog = useCurrentHandler(
        debug ? console.log.bind(console) : noop,
    );
    const handleError = useCallback(error => {
        debugLog("[useQueryLoadable.error]", { error });
        setCallState(([, , sus]) => [STATUSES.hasError, error, sus]);
        if (process.env.NODE_ENV !== "production")
            console.error("[useQueryLoadable] err", error);
    }, []);
    const handleSuccess = useCallback(response => {
        debugLog("[useQueryLoadable.success]", { response });
        setCallState(([, , sus]) => [STATUSES.hasValue, response, sus]);
    }, []);
    const createSuspendableWithHandlers = useCurrentHandler(() =>
        createSuspendable({
            debugLog,
            createPromise: pipe(fn, cancelable),
            onSuccess: handleSuccess,
            onError: handleError,
        }),
    );

    const isInitialRef = useRef(true);
    const initialSuspendable = useMemo(
        () => createSuspendableWithHandlers(),
        [createSuspendableWithHandlers],
    );
    const [refetchToken, setRefetchToken] = useState();
    const [callState, setCallState] = useState([
        STATUSES.loading,
        initialSuspendable.promise,
        initialSuspendable,
    ]);

    const callStateRef = useRef(callState);
    callStateRef.current = callState;
    useEffect(() => {
        return isLoading(callState)
            ? () => {
                  if (isLoading(callStateRef.current)) {
                      debugLog("[useQueryLoadable.cancel]");
                      getPromise(callState).cancel();
                  }
              }
            : undefined;
    }, [callState]);

    useEffect(() => {
        if (isInitialRef.current) {
            isInitialRef.current = false;
            return;
        }
        const suspendable = createSuspendableWithHandlers();
        debugLog("[useQueryLoadable.fetchEff]", { suspendable });
        setCallState([STATUSES.loading, suspendable.promise, suspendable]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, refetchToken]);

    // TODO: get rid of double setState and rerender on refetch
    const reload = useCallback(
        () => debugLog("[useQueryLoadable.reload]") || setRefetchToken(uuid()),
        [],
    );
    const [state, contents, suspendable] = callState;
    const valueMaybe = useMemo(() => () => getResponse(callState), [callState]);
    const loadable = useMemo(
        () => ({
            state,
            contents,
            valueMaybe,
        }),
        [state, contents, valueMaybe],
    );
    const resource = useMemo(
        () => ({ loadable, reload, setCallState, suspendable }),
        [loadable, reload, suspendable],
    );
    debugLog("[useQueryLoadable.rndr]", loadable.state, {
        loadable,
        suspendable,
        resource,
    });

    return resource;
}
