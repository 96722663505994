import IconUploadComponent from "@/components/Upload/IconUpload";
import { getBase64, validateFileSize } from "@/utils/image/imageUtils";
import { Forms } from "@pricefx/unity-components";
import { isEmpty } from "lodash";
import React from "react";

const { Field, validators } = Forms;

export const IconUpload = ({ name, validator, ...props }) => {
    return (
        <Field
            name={name}
            as={IconUploadComponent}
            from={async fileList => {
                if (!isEmpty(fileList)) {
                    const imageAsBase64 = await getBase64(
                        fileList[0]?.originFileObj,
                    );
                    return imageAsBase64;
                } else {
                    return null;
                }
            }}
            validator={validators.failOnFirst([validateFileSize(), validator])}
            {...props}
        />
    );
};
