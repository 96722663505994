export const isPasswordResetNeeded = headers => {
    return !!headers?.["x-pricefx-authentication-password-reset"];
};

export const getSecret = headers => {
    return headers?.["x-pricefx-authentication-secret-token"];
};

export const isSecondLoginStepNeeded = headers => {
    return !!headers?.["x-pricefx-authentication-need-second-step"];
};
