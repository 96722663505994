import { Cascader as AntdCascader } from "antd";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./Cascader.style.less";

const COMPONENT_CLASS = "pmCascader";

// FIXME: Simulate Cascader as groupped input, antd now doesn't support addonAfter
const wrapper = (addonAfter, children) => {
    if (!addonAfter) {
        return children;
    } else {
        return (
            <div className="ant-input-group-wrapper ant-cascader-input">
                <div className="ant-input-wrapper ant-input-group">
                    {children}
                    <div className="ant-input-group-addon">{addonAfter}</div>
                </div>
            </div>
        );
    }
};

export const Cascader = React.forwardRef(
    ({ className, size = "middle", addonAfter, ...props }, ref) => {
        const classes = cx(COMPONENT_CLASS, className);

        return wrapper(
            addonAfter,
            <AntdCascader
                ref={ref}
                size={size}
                className={classes}
                {...props}
            />,
        );
    },
);

Cascader.propTypes = {
    children: PropTypes.node,
    addonAfter: PropTypes.node,
    size: PropTypes.string,
    className: PropTypes.string,
};
