import { Forms } from "@/components/DesignSystem/Forms";
import { t } from "@/translations";

export const MEGA_BYTE = 1000000;
export const MAX_FILE_SIZE = 500000;
export const MAX_FILE_SIZE_IN_MB = MAX_FILE_SIZE / MEGA_BYTE;
export const ACCEPTED_IMAGE_FORMATS = [".svg", ".png", ".jpeg", ".jpg"];

export const getFileList = url =>
    url
        ? [
              {
                  uid: "-1",
                  name: "image",
                  status: "done",
                  url,
              },
          ]
        : [];

export const getBase64 = file =>
    new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
    });

export const validateFileSize =
    (maxSize = MAX_FILE_SIZE) =>
    async value => {
        const fileContent = await value;
        if (fileContent?.length > maxSize) {
            return Forms.error(
                t("offer-edit.error.max-image-size", {
                    maxSize: maxSize / MEGA_BYTE,
                }),
            );
        } else {
            return Forms.success();
        }
    };
