import { yamlLinter } from "@/components/DesignSystem/CodeMirror/linters/yaml.linter";
import { json, jsonLanguage, jsonParseLinter } from "@codemirror/lang-json";
import { xml, xmlLanguage } from "@codemirror/lang-xml";
import { StreamLanguage } from "@codemirror/language";
import { properties } from "@codemirror/legacy-modes/mode/properties";
import { yaml } from "@codemirror/legacy-modes/mode/yaml";
import { linter, lintGutter } from "@codemirror/lint";
import { lineNumbers } from "@codemirror/view";
import ReactCodeMirror from "@uiw/react-codemirror";
import cx from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import "./CodeMirror.style.less";

const extensionsByMode = mode => {
    switch (mode) {
        case "json":
            return [
                json(),
                StreamLanguage.define(jsonLanguage),
                linter(jsonParseLinter()),
            ];
        case "xml":
            return [xml(), StreamLanguage.define(xmlLanguage)];

        case "properties":
            return [StreamLanguage.define(properties)];

        case "yaml":
            return [StreamLanguage.define(yaml), yamlLinter];

        default:
            return [];
    }
};

export const CodeMirror = ({
    className,
    mode = "properties",
    value,
    onChange,
    extensions: otherExtensions = [],
    ...rest
}) => {
    const loadedExtensions = useMemo(
        () => [
            lineNumbers(),
            lintGutter(),
            ...extensionsByMode(mode),
            ...otherExtensions,
        ],
        [mode, otherExtensions],
    );

    // FIXME: Can be removed after resolved this issue: https://github.com/uiwjs/react-codemirror/issues/280
    const [extensions, setExtensions] = useState([]);
    useEffect(() => {
        setExtensions(loadedExtensions);
    }, []);

    return (
        <div className={cx("pmCodeMirror", className)}>
            <ReactCodeMirror
                value={value}
                onChange={onChange}
                extensions={extensions}
                {...rest}
            />
        </div>
    );
};

CodeMirror.propTypes = {
    className: PropTypes.string,
    mode: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.string,
    extensions: PropTypes.array,
};
