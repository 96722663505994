import { Forms } from "@pricefx/unity-components";
import identity from "lodash/identity";
import React from "react";
import { TableSelect as DesignSystemTableSelect } from "../../index";

const { Field } = Forms;

export const TableSelect = ({ ...props }) => {
    return <Field as={DesignSystemTableSelect} from={identity} {...props} />;
};
