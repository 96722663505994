import FormUploadComponent from "@/components/Upload/FormUpload";
import { Forms } from "@pricefx/unity-components";
import identity from "lodash/identity";
import React from "react";

const { Field } = Forms;

export const Upload = ({ ...props }) => {
    return <Field as={FormUploadComponent} from={identity} {...props} />;
};
