import React, { Suspense } from "react";
import identity from "lodash/fp/identity";
import { Forms } from "..";

const componentClass = "ucFormsField__explain";
const errorClass = `${componentClass} ${componentClass}--error`;
const warningClass = `${componentClass} ${componentClass}--warning`;

const DefaultValidationResult = ({ formId, name, translate }) => {
    const validation = Forms.useFieldValidation({ formId, name });

    if (Forms.isSuccess(validation)) {
        return "";
    }
    if (Forms.isError(validation)) {
        return (
            <span className={errorClass}>
                {translate(validation.value, validation.other)}
            </span>
        );
    }
    return (
        <span className={warningClass}>
            {translate(validation.value, validation.other)}
        </span>
    );
};

export const FieldValidationResult = ({
    name,
    formId,
    visible,
    validatingFallback = "", // solves skipping during typing, this component can be removed when UC will support passing it
    translate = identity,
}) => {
    return (
        <Suspense fallback={validatingFallback}>
            {visible && (
                <DefaultValidationResult
                    formId={formId}
                    name={name}
                    translate={translate}
                />
            )}
        </Suspense>
    );
};
