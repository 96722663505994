import { number, object, string } from "yup";

const schema = object().shape({
    accountId: number().required(),
    partitionId: number(),
    isvConnectionId: number(),
    uploadId: number(),
    instanceId: number(),
    integrationId: number(),
    mapperId: string(),
    activeTab: string(),
    alertRuleId: number(),
    userId: string(),
    expand: string(),
});

export const routeParamsService = {
    cast: params => schema.cast(params),
};
