import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useContext, useEffect } from "react";
import { SSEContext } from "./SSEContext";

const DEFAULT_PARSER = input => {
    try {
        return JSON.parse(input);
    } catch (error) {
        console.warn("SSE parsing Error", input, error.message);
        return {};
    }
};

const useSSE = (type, callback, parser = DEFAULT_PARSER) => {
    const { eventSource } = useContext(SSEContext);
    const handler = useCurrentHandler(event => callback(parser(event.data)));

    useEffect(() => {
        if (eventSource) {
            eventSource.addEventListener(type, handler);

            // TODO: check that handler is not removed completely when effect reruns (exec order of effect/cleanupFn)
            return () => eventSource.removeEventListener(type, handler);
        }
    }, [eventSource, handler, type]);
};

export default useSSE;
