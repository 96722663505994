import { constants } from "@pricefx/unity-components";

export const fieldTypes = constants.FIELD_TYPES;

export const HEADER_HEIGHT = 40;
export const UNITY_TITLE_HEADER = 57;
export const BREADCRUMB_HEIGHT = 23;
export const TAB_HEIGHT = 36;
export const DRAWER_HEADER_HEIGHT = 56;
export const DRAWER_CONTENT_PADDING = 16;
export const DRAWER_FOOTER_HEIGHT = 64;

export const HEADER_WITH_PAGE_TITLE = HEADER_HEIGHT + UNITY_TITLE_HEADER;
export const HEADER_HEIGHT_WITH_BREADCRUMB =
    HEADER_WITH_PAGE_TITLE + BREADCRUMB_HEIGHT;
export const HEADER_WITH_TAB_HEIGHT = HEADER_HEIGHT + TAB_HEIGHT;
export const HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT =
    HEADER_HEIGHT_WITH_BREADCRUMB + TAB_HEIGHT;
