const SSEType = {
    HEARTBEAT: "HEARTBEAT",
    NOTIFICATION: "NOTIFICATION",
    PACKAGE_TERMINATION: "PACKAGE_TERMINATION",
    SFTP_LIST_STATUS: "SFTP_LIST_STATUS",
    TASK: "TASK",
    UPDATE_IM_STATE: "UPDATE_IM_STATE",
    S3_FILE_UPLOADED: "S3_FILE_UPLOADED",
    S3_FILE_PREVIEW_UPLOADED: "S3_FILE_PREVIEW_UPLOADED",
};

export default SSEType;
