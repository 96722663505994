import router5 from "router5";
import router5PluginBrowser from "router5-plugin-browser";
import router5PluginLogger from "router5-plugin-logger";

export const buildHashedUrl = url => `#${url}`;

export function createRouter() {
    const router = router5([], {
        defaultRoute: "login",
    });

    if (process.env.NODE_ENV !== "production") {
        router.usePlugin(router5PluginLogger);
    }

    router.usePlugin(
        router5PluginBrowser({
            useHash: true,
        }),
    );

    return router;
}
