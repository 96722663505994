import { createIntl, createIntlCache } from "react-intl";

export function translationsFactory(locale, messages) {
    // This is optional but highly recommended
    // since it prevents memory leak
    const cache = createIntlCache();

    return createIntl(
        {
            locale,
            messages,
        },
        cache,
    );
}
