import { Radio } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import React from "react";

export const RadioButton = ({
    className,
    options,
    onChange,
    value,
    size,
    buttonStyle = "outline",
}) => (
    <Radio.Group
        value={value}
        size={size}
        buttonStyle={buttonStyle}
        className={className}
    >
        {options &&
            options.map(option => (
                <Radio.Button
                    onChange={onChange}
                    value={option.value}
                    key={option.value}
                >
                    {option.label}
                </Radio.Button>
            ))}
    </Radio.Group>
);

RadioButton.propTypes = {
    size: PropTypes.oneOf(["large", "middle", "small"]),
    buttonStyle: PropTypes.oneOf(["solid", "outline"]),
};
