import { linter } from "@codemirror/lint";
import jsyaml from "js-yaml";

export const yamlLinter = linter(view => {
    let found = [];
    // console.log(view); This is the EditorView
    // console.log(view.state); This is the EditorState
    // console.log(view.state.doc); This is the object type Textleaf containing Editor text and related info.
    try {
        jsyaml.load(view.state.doc);
    } catch (e) {
        var loc = e.mark;
        var from = loc ? loc.position : 0;
        var to = from;
        var severity = "error";
        found.push({
            from: from,
            to: to,
            message: e.message,
            severity: severity,
        });
    }
    return found;
});
