import { ICON_SIZE } from "@/components/DesignSystem";
import { InboxOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import React from "react";
import Dragger from "./Dragger.component";
import "./DraggerWithIcon.style.less";

const DraggerWithIcon = ({ ...draggerProps }) => {
    return (
        <Dragger className="pmDraggerWithIcon" {...draggerProps}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined size={ICON_SIZE.BIG} />
            </p>
        </Dragger>
    );
};

DraggerWithIcon.propTypes = {
    accept: PropTypes.string,
    hintMessage: PropTypes.node,
    onFileUploaded: PropTypes.func.isRequired,
    ErrorComponent: PropTypes.elementType,
};

export default DraggerWithIcon;
