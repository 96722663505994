export const postRedirect = (url, data, options) => {
    const form = document.createElement("form");
    document.body.appendChild(form);
    form.method = "post";
    form.action = url;
    if (options?.isBlank) {
        form.target = "_blank";
    }
    const paramKeys = Object.keys(data);
    paramKeys.forEach(key => {
        let formVal = document.createElement("input");
        formVal.type = "hidden";
        formVal.name = key;
        formVal.value = data[key];
        form.appendChild(formVal);
    });
    form.submit();

    document.body.removeChild(form);
};

const readCookie = name =>
    document.cookie
        ?.split("; ")
        .find(row => row.startsWith(`${name}=`))
        ?.split("=")?.[1];

const readPartitionRedirectCookies = () => {
    const user = readCookie("x-pricefx-username");
    const token = readCookie("x-pricefx-access-token");
    const redirectUrl = readCookie("x-pricefx-redirect-url");

    return { user, token, redirectUrl };
};

export const partitionRedirect = () => {
    const { redirectUrl, user, token } = readPartitionRedirectCookies();
    if (redirectUrl && user && token) {
        postRedirect(redirectUrl, { user, token });
    } else {
        console.error("Missing cookies for partition redirect.");
    }
};
