import React from "react";
import PropTypes from "prop-types";
import { ButtonMenu as UCButtonMenu } from "@pricefx/unity-components";
import { useMemo } from "react";
import { useTrackButtonClick } from "../../../mixpanel/hooks/useTrackButtonClick.hook";
import "./ButtonMenu.style.less";
import { map, pipe } from "lodash/fp";
import { filterVisibleButtons } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";

export const ButtonMenu = ({ buttons, align, ...props }) => {
    const { wrapButtonDef } = useTrackButtonClick();
    const buttonsTracked = useMemo(
        () => pipe(filterVisibleButtons, map(wrapButtonDef))(buttons),
        [buttons, wrapButtonDef],
    );
    const buttonMenu = <UCButtonMenu {...props} buttons={buttonsTracked} />;

    if (align === "left") {
        return <div className={"pmButtonMenu-alignLeft"}>{buttonMenu}</div>;
    }

    return buttonMenu;
};

ButtonMenu.propTypes = {
    buttons: PropTypes.array,
    align: PropTypes.oneOf(["left"]),
};

ButtonMenu.buttons = UCButtonMenu.buttons;
