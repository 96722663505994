import { Tooltip } from "@/components/DesignSystem/Tooltip";
import { Checkbox as UnityCheckbox } from "@pricefx/unity-components";
import React from "react";

const withTooltip = (element, tooltip) => {
    return (
        <Tooltip title={tooltip}>
            <span>{element}</span>
        </Tooltip>
    );
};

// https://pricefx.atlassian.net/browse/PFUN-24882
export function Checkbox({ tooltip, ...props }) {
    return tooltip ? (
        withTooltip(<UnityCheckbox {...props} />, tooltip)
    ) : (
        <UnityCheckbox {...props} />
    );
}

Checkbox.Group = UnityCheckbox.Group;
