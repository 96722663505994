import { logger } from "@/modules/logger";
import axios from "axios";
import axiosDefaults from "axios/lib/defaults";

export function createAxiosClient({ baseURL }) {
    logger.debug({
        logGroupKey: ["AXIOS", "createAxiosClient"],
        color: "pink",
        data: {
            "process.env.PROXY": process.env.PROXY,
            baseURL,
            stack: new Error().stack,
        },
    });
    const axiosClient = axios.create({
        baseURL,
        headers: {
            "Content-Type": "application/json;charset=UTF-8",
        },
        ...(process.env.PROXY === "STUB" ? { withCredentials: true } : {}), // cassette and variant cookies for vcr.js
        transformRequest: axiosDefaults.transformRequest,
    });

    return axiosClient;
}
