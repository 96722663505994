import moment from "moment";
import transit from "transit-js";

const Moment = moment.fn;

const MomentHandler = transit.makeWriteHandler({
    tag: () => "moment",
    rep: v => {
        if (!v.isValid()) {
            console.warn(
                new Error(
                    "Transmitting an invalid moment object has undefined behavior",
                ),
            );
        }
        return v.toJSON();
    },
    stringRep: () => null,
});

const transitWriter = transit.writer("json", {
    handlers: transit.map([Moment.constructor, MomentHandler]),
});

const transitReader = transit.reader("json", {
    handlers: {
        moment: rep => moment(rep),
    },
    mapBuilder: {
        init: () => ({}),
        add: (ret, key, val) => {
            ret[key] = val;
            return ret;
        },
        finalize: ret => ret,
    },
});

export const transitSerializer = value => transitWriter.write(value);
export const transitDeserializer = value => transitReader.read(value);
