import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { isValidElement, useCallback, useContext } from "react";
import { TrackingContext } from "../TrackingContextProvider";
import { onlyText } from "react-children-utilities";

export const nameFromElementOrString = elementOrString => {
    if (isValidElement(elementOrString)) {
        if (elementOrString?.type?.displayName === "FormattedMessage")
            return elementOrString?.props?.id;
        else if (onlyText(elementOrString)) return onlyText(elementOrString);
        else {
            console.log("[nameFromElementOrString] Unlabeled element", {
                stack: new Error().stack,
                elementOrString,
                text: onlyText(elementOrString),
            });
            return "unlabeled_element";
        }
    }

    // UC Button objects - table preferences dropdown etc
    if (typeof elementOrString === "object") {
        if (elementOrString?.title) return elementOrString?.title;
        else {
            console.log("[nameFromElementOrString] Unknown object-like", {
                stack: new Error().stack,
                elementOrString,
            });
            return "unknown_object";
        }
    }

    if (!elementOrString) {
        console.log(
            "[nameFromElementOrString] Missing label, tracked as unknown",
            {
                stack: new Error().stack,
                elementOrString,
            },
        );
        return "unknown";
    }

    return elementOrString?.toString(); // react element as attribute kills mixpanel
};

export const useTrackButtonClick = () => {
    const { trackButtonClick } = useContext(TrackingContext);
    const trackButtonClickCurrent = useCurrentHandler(trackButtonClick);
    const trackHandler = useCallback(
        (fn, { name, defaultName, clusterUpgradeType, isMajor }) =>
            (...args) => {
                trackButtonClickCurrent({
                    name: nameFromElementOrString(name || defaultName),
                    clusterUpgradeType,
                    isMajor,
                });
                return fn?.(...args);
            },
        [trackButtonClickCurrent],
    );
    const wrapButtonDef = useCallback(
        buttonDef => ({
            ...buttonDef, // TODO: map nested items (PageLayoutContext -> DislocatedPreferencesDropdown)
            onClick: trackHandler(buttonDef.onClick, {
                defaultName:
                    buttonDef.label || buttonDef.tooltip || buttonDef.anchor, // nested items
                ...buttonDef.track,
            }),
        }),
        [trackHandler],
    );
    return {
        trackButtonClick: trackButtonClickCurrent,
        trackHandler,
        wrapButtonDef,
    };
};
