import isEqual from "lodash/isEqual";
import React, { useRef } from "react";

export const useMemoByDeepEquality = value => {
    const ref = useRef(value);

    if (!isEqual(ref.current, value)) ref.current = value;

    return ref.current;
};
