import { useDic } from "@/components/Dic/useDic.hook";
import { pendingPromise, useQueryLoadable } from "@/modules/loadable";
import PropTypes from "prop-types";
import React from "react";

const initialState = {
    appPropertiesResource: null,
};

export const AppPropertiesContext = React.createContext(initialState);

const AppPropertiesContextProvider = ({ children }) => {
    const { applicationPropertiesService, authenticationService } = useDic();

    const appPropertiesResource = useQueryLoadable(
        () =>
            !authenticationService.isLoggedIn()
                ? pendingPromise()
                : applicationPropertiesService.getApplicationProperties(),
        [applicationPropertiesService, authenticationService],
    );

    return (
        <AppPropertiesContext.Provider value={{ appPropertiesResource }}>
            {children}
        </AppPropertiesContext.Provider>
    );
};

AppPropertiesContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AppPropertiesContextProvider;
