import { Forms } from "@pricefx/unity-components";
import identity from "lodash/identity";
import React from "react";
import { SelectWithAddItem as DesignSystemSelectWithAddItem } from "../../index";

const { Field } = Forms;

export const SelectWithAddItem = ({ ...props }) => {
    return (
        <Field as={DesignSystemSelectWithAddItem} from={identity} {...props} />
    );
};
