import {
    isAuthorizedByPermissionOr,
    SecurityContext,
} from "@/security/authorization";
import UserSecurityContentContext from "@/security/UserSecurityContentContext";
import PropTypes from "prop-types";
import React, { useContext } from "react";

export const Authorized = ({
    permission,
    children,
    fallback = null,
    projectId,
    instanceId,
    partitionId,
}) => {
    const securityContext = useContext(SecurityContext);
    if (!permission) return children;

    return (
        <UserSecurityContentContext
            projectId={projectId}
            instanceId={instanceId}
            partitionId={partitionId}
        >
            {isAuthorizedByPermissionOr(permission, securityContext.permissions)
                ? children
                : fallback}
        </UserSecurityContentContext>
    );
};

Authorized.propTypes = {
    permission: PropTypes.array,
    children: PropTypes.node,
    fallback: PropTypes.node,
    projectId: PropTypes.number,
    instanceId: PropTypes.number,
    partitionId: PropTypes.number,
};
