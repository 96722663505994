import {
    CLEAN_BREADCRUMB_DATA,
    SET_BREADCRUMB_BUTTONS,
    SET_BREADCRUMB_DATA,
} from "./actionTypes";
import { createReducer } from "../../../redux/factories/reducer.factory";

const INITIAL_STATE = [];

export default createReducer(INITIAL_STATE, {
    [SET_BREADCRUMB_DATA]: (state, action) => [
        {
            label: action.label,
            onClick: action.onClick,
            permissions: action.permissions,
            config: action.config,
        },
    ],
    [SET_BREADCRUMB_BUTTONS]: (state, action) => action.buttons,
    [CLEAN_BREADCRUMB_DATA]: () => INITIAL_STATE,
});
