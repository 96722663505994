import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { t } from "@/translations";
import { useEffect, useMemo } from "react";
import { useSetExportButtonSettings } from "../PageLayout/useSetExportButtonSettings.hook";

export const DislocatedExportButton = ({
    exportVisible,
    onExport,
    text = t("button.export-csv"),
    exporting = false,
    exportDisabled,
}) => {
    const { setExportButtonSettings } = useSetExportButtonSettings();

    const onClick = useCurrentHandler(onExport);

    const exportButtonSettings = useMemo(
        () => ({
            exportDisabled,
            loading: exporting,
            exportVisible,
            text,
            // this component could require to get memoized onExport from parent, but it would be verbose
            onClick,
        }),
        [exportDisabled, exporting, exportVisible, text, onClick],
    );

    useEffect(() => {
        setExportButtonSettings(exportButtonSettings);
    }, [exportButtonSettings, setExportButtonSettings]);

    return null;
};
