import { Forms } from "@pricefx/unity-components";
import identity from "lodash/identity";
import React from "react";
import TimezoneSelectRaw from "../../../TimezoneSelect/TimezoneSelect.component";

const { Field } = Forms;

export const TimezoneSelect = ({ ...props }) => {
    return <Field as={TimezoneSelectRaw} from={identity} {...props} />;
};
