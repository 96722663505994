import { t } from "@/translations";
import { Select as UnitySelect } from "@pricefx/unity-components";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./Select.style.less";

export const Option = UnitySelect.Option;
export const OptionGroup = UnitySelect.OptGroup;

const COMPONENT_CLASS = "pmSelect";

/**
 * Some current usage limitations
 * 1) Current Select width is set by the longest option length => should be limited by maxWidth prop
 * 2) Options are shrinked by Select width, => dropdownMatchSelectWidth => if set false, in large datasets it's slow
 */

export const Select = React.forwardRef(
    (
        {
            dropdownMatchSelectWidth = true,
            maxWidth = "none",
            className,
            placeholder = t("placeholder.please-select"),
            withoutBorder,
            size = "medium",
            style,
            ...props
        },
        ref,
    ) => {
        const classes = cx(
            "pmSelect",
            {
                [`${COMPONENT_CLASS}--withoutBorder`]: withoutBorder,
            },
            className,
        );

        return (
            <UnitySelect
                ref={ref}
                size={size}
                className={classes}
                placeholder={placeholder}
                dropdownMatchSelectWidth={dropdownMatchSelectWidth}
                style={{ maxWidth, ...style }}
                {...props}
            />
        );
    },
);

Select.propTypes = {
    dropdownMatchSelectWidth: PropTypes.bool,
    maxWidth: PropTypes.number,
    children: PropTypes.node,
    placeholder: PropTypes.node,
    size: PropTypes.string,
    className: PropTypes.string,
    withoutBorder: PropTypes.bool,
    style: PropTypes.object,
};
