export const loadableFromMaybeValue = maybeValue => {
    if (maybeValue === undefined)
        return {
            contents: new Promise(() => {}),
            state: "loading",
            valueMaybe: () => undefined,
        };
    return {
        contents: maybeValue,
        state: "hasValue",
        valueMaybe: () => maybeValue,
    };
};
