import "./Container.style.less";

import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

const componentClass = "pmContainer";

export const Container = ({
    size = "md",
    type = "margin",
    scrollable = false,
    children,
    className,
    ...rest
}) => (
    <div
        className={cx([
            className,
            componentClass,
            `${componentClass}--${type}-${size}`,
            scrollable && `${componentClass}--scrollable`,
        ])}
        {...rest}
    >
        {children}
    </div>
);

Container.propTypes = {
    size: PropTypes.oneOf(["lg", "md", "sm", "xs"]),
    type: PropTypes.oneOf(["padding", "margin"]),
    scrollable: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
};
