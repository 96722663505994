import { useContext, useMemo } from "react";
import { ConfirmModalContext } from "./ConfirmModalProvider";

export const useConfirmModal = () => {
    const { open, cancelAll, confirm, confirmDelete } =
        useContext(ConfirmModalContext);

    return useMemo(
        () => ({
            confirm,
            confirmDelete,
            cancelAll,

            /**
             * @deprecated - internal method, can mimic legacy confirm usages
             */
            open,
        }),
        [confirm, confirmDelete, cancelAll, open],
    );
};
