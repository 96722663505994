import { useDic } from "@/components/Dic/useDic.hook";
import { useMutationLoadable } from "@/modules/loadable";
import { logger } from "@/modules/logger";
import {
    getSecret,
    isPasswordResetNeeded,
    isSecondLoginStepNeeded,
} from "@/security/isPasswordResetNeeded";
import { useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { loginErrorState } from "./LoginPage";
import { LOGIN_ERROR_TYPE, LoginStates } from "./constants";

const lgk = (tail = []) => ["AUTH", "login", "service", ...tail];

export const useLogin = () => {
    const { loginService } = useDic();
    const context = useRef({});
    const [loginState, setLoginState] = useState(LoginStates.NONE);
    const [, setError] = useRecoilState(loginErrorState);

    const loginMutation = useMutationLoadable(
        async loginParams => {
            context.current = { ...context.current, ...loginParams };
            return loginService
                .login(loginParams)
                .then(response => {
                    logger.debug({
                        logGroupKey: lgk(["response"]),
                        color: "chocolate",
                        data: {
                            response,
                        },
                    });
                    setError(null);

                    if (response.status === 200) {
                        // We cannot access Location header, so we need to redirect manually
                        const redirectUrl = response.data.redirectUrl;
                        logger.debug({
                            logGroupKey: lgk(["LOGIN SUCCESS, REDIR"]),
                            color: "chocolate",
                            data: redirectUrl,
                        });
                        window.location.href = redirectUrl;
                    }

                    return response;
                })
                .catch(error => {
                    const secret = getSecret(error.response?.headers);
                    logger.debug({
                        logGroupKey: lgk(["error"]),
                        color: "chocolate",
                        data: { secret },
                    });
                    if (isPasswordResetNeeded(error.response?.headers)) {
                        logger.debug({
                            logGroupKey: lgk(["error"]),
                            color: "chocolate",
                            data: {
                                loginState: LoginStates.NEEDS_PASSWORD_CHANGE,
                            },
                        });
                        setLoginState(LoginStates.NEEDS_PASSWORD_CHANGE);
                    } else if (secret) {
                        context.current = { ...context.current, secret };
                        setLoginState(
                            LoginStates.NEEDS_AUTHENTICATION_SECOND_STEP,
                        );
                    } else if (
                        isSecondLoginStepNeeded(error.response?.headers)
                    ) {
                        logger.debug({
                            logGroupKey: lgk(["error"]),
                            color: "chocolate",
                            data: {
                                loginState:
                                    LoginStates.NEEDS_AUTHENTICATION_SECOND_STEP,
                            },
                        });
                        setLoginState(
                            LoginStates.NEEDS_AUTHENTICATION_SECOND_STEP,
                        );
                    } else {
                        if (loginParams?.newPassword) {
                            setError({
                                type: LOGIN_ERROR_TYPE.FIRST_PASS,
                                message: error.response.data?.message,
                            });
                        } else if (loginParams?.code) {
                            setError({
                                type: LOGIN_ERROR_TYPE.OTP,
                                message: error.response.data?.message,
                            });
                        } else {
                            setError({
                                type: LOGIN_ERROR_TYPE.LOGIN,
                                message: error.response.data?.message,
                            });
                        }
                    }
                });
        },
        [loginService, setError],
    );
    return {
        loginState,
        userContext: context.current,
        loginMutation,
    };
};
