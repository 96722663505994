import { Button, Gap, Input, UnityHelp } from "@/components/DesignSystem";
import { T, t } from "@/translations";
import { getBase64 } from "@/utils/image/imageUtils";
import { PlusOutlined } from "@ant-design/icons";
import { Collapse } from "@pricefx/unity-components";
import { Upload } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import "../styles.less";

const ACCEPTED_FORMATS = [".xml", ".xsd", ".json"];

export const tapFn =
    (msg, fn) =>
    (...args) =>
        console.log("[tapFn]", msg, ...args) || fn(...args);

const Dropzone = ({ label }) => {
    return (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{label}</div>
        </div>
    );
};

export const MetadataAdvancedOptions = ({ setFetchParams }) => {
    const [metadataUri, setMetadataUri] = useState("");
    const [file, setFile] = useState(null);
    const handleFile = useCallback(
        async file => {
            if (file) {
                const metadataBase64WithDeclaration = await getBase64(
                    file?.originFileObj,
                );
                const metadataType =
                    file.type.split("/").pop().toUpperCase() ||
                    file.name.split(".").pop().toUpperCase();
                const metadataBase64 = metadataBase64WithDeclaration.replace(
                    /^data:[\w/-]*;base64,/,
                    "",
                );
                setFetchParams({ metadataBase64, metadataType });
                setFile(file);
                setMetadataUri("");
            } else {
                setFetchParams({});
                setFile(null);
            }
        },
        [setFetchParams],
    );
    useEffect(() => () => setFetchParams({}), [setFetchParams]);
    const handleUpload = ({ fileList }) => {
        handleFile(fileList[0]);
    };
    const fetchMetadataFromUri = useCallback(() => {
        setFile(null);
        setFetchParams({ metadataUri });
    }, [metadataUri, setFetchParams]);

    return (
        <Collapse>
            <Collapse.Panel header="Advanced Options" key="1">
                {/* <Forms.UIField name="metadataUri"></Forms.UIField> */}
                <T id="metadata-params-form.metadata-uri.label" />
                <UnityHelp
                    content={t("metadata-params-form.metadata-uri.help")}
                />
                <div
                    style={{
                        display: "inline-flex",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Input
                        placeholder="Metadata uri"
                        onChange={e => setMetadataUri(e.target.value)}
                        value={metadataUri}
                    />
                    <Button
                        style={{ marginLeft: "8px" }}
                        label="Fetch metadata"
                        onClick={fetchMetadataFromUri}
                    />
                </div>
                <Gap size="small" />
                <div style={{ width: "100%", textAlign: "center" }}>or</div>
                <Gap size="small" />
                <Upload
                    customRequest={tapFn("customRequest", handleUpload)}
                    multiple={false}
                    beforeUpload={() => false}
                    className="uploadPreviewFullSize"
                    listType="picture-card"
                    fileList={file ? [file] : []}
                    onChange={tapFn("onChange", handleUpload)}
                    accept={ACCEPTED_FORMATS.join(",")}
                >
                    {file ? null : (
                        <Dropzone
                            label={t(
                                "metadata-params-form.dropzone.empty.label",
                            )}
                        />
                    )}
                </Upload>
            </Collapse.Panel>
        </Collapse>
    );
};
