import { useRoute } from "react-router5";
export { useRouteNode, useRoute } from "react-router5";

export function useRouteParams() {
    const routeContext = useRoute();

    if (routeContext && routeContext.route) {
        return {
            routeName: routeContext.route.name,
            ...routeContext.route.params,
        };
    }

    return {};
}
