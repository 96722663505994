import { t } from "@/translations";
import cx from "classnames";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
// import styles from "./Drawer.style.less";
import {
    ButtonMenu,
    Drawer as UnityDrawer,
    Layout,
} from "@pricefx/unity-components";
import { PanelContainerContext } from "./PanelContainer";

export const DEFAULT_WIDTH = 500;

export function Drawer({
    title,
    cancelButtonProps,
    okButtonProps,
    children,
    className,
    panel,
    width = DEFAULT_WIDTH,
    mask,
    testId,
    ...rest
}) {
    const displayMask = typeof mask === "boolean" ? mask : panel ? false : true;
    const classNames = cx(["pmDrawer", className]);
    const panelContainerRef = useContext(PanelContainerContext);
    const panelProps = panel
        ? {
              getContainer: panelContainerRef || false,
              style: { position: "absolute" },
          }
        : {};

    // force clean unmount of component
    useEffect(() => () => {
        document.body.style.overflow = "";
    });

    return (
        <UnityDrawer
            onClose={cancelButtonProps?.onClick}
            className={classNames}
            width={width}
            mask={displayMask}
            {...panelProps}
            {...rest}
        >
            <Drawer.Content
                title={title}
                okButtonProps={okButtonProps}
                cancelButtonProps={cancelButtonProps}
                testId={testId}
            >
                {children}
            </Drawer.Content>
        </UnityDrawer>
    );
}

const buttonPropsPropType = PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([false]),
]);

Drawer.propTypes = {
    title: PropTypes.string,
    cancelButtonProps: buttonPropsPropType,
    children: PropTypes.node,
    className: PropTypes.string,
    okButtonProps: buttonPropsPropType,
    panel: PropTypes.bool,
    width: PropTypes.number,
    mask: PropTypes.bool,
    testId: PropTypes.string,
};

Drawer.Content = ({
    title,
    children,
    cancelButtonProps,
    okButtonProps,
    testId,
}) => {
    return (
        <Layout data-test={testId}>
            <Layout.Header title={title} />
            <Layout.Content padding={[false, false]}>{children}</Layout.Content>
            {okButtonProps || cancelButtonProps ? (
                <Layout.Footer
                    actionButtonsLeft={
                        <ButtonMenu
                            buttons={[
                                {
                                    type: "primary",
                                    ...okButtonProps,
                                    label:
                                        okButtonProps.children ||
                                        t("general.ok"),
                                },
                                {
                                    type: "text",
                                    ...cancelButtonProps,
                                    label:
                                        cancelButtonProps.children ||
                                        t("general.cancel"),
                                },
                            ]}
                        />
                    }
                />
            ) : null}
        </Layout>
    );
};

Drawer.Content.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    okButtonProps: buttonPropsPropType,
    cancelButtonProps: buttonPropsPropType,
    testId: PropTypes.string,
};
