import {
    Spinner as BasicSpinner,
    Skeleton,
    Text,
} from "@/components/DesignSystem";
import { T } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { LoadablePropType } from "./PropTypesCreators";
import { responseErrorMessage } from "./utils";
import { ErrorBoundary } from "react-error-boundary";

const Error = ({
    axiosError,
    children = responseErrorMessage(axiosError),
    translationKey = "general.initial-load-failed",
}) => (
    <Skeleton
        title={
            <Text type="error">{children || <T id={translationKey} />}</Text>
        }
    />
);

Error.propTypes = {
    children: PropTypes.node,
    axiosError: PropTypes.shape({ response: PropTypes.object.isRequired }),
    translationKey: PropTypes.string,
};

const Spinner = ({ translationKey = "general.loading", ...props }) => (
    <BasicSpinner text={<T id={translationKey} />} {...props} />
);

Spinner.propTypes = {
    translationKey: PropTypes.string,
};

const SkeletonLoader = ({ translationKey = "general.loading-data" }) => (
    <Skeleton
        active
        title={
            translationKey ? (
                <Text>
                    <T id={translationKey} />
                </Text>
            ) : null
        }
    />
);

SkeletonLoader.propTypes = {
    translationKey: PropTypes.string,
};

const SuspendableRendererInner = ({ suspendable, hasValue }) => {
    return hasValue(suspendable.read());
};

export const SuspendableRenderer = ({
    suspendable,
    hasValue,
    hasError = error => <Error />,
    loading = () => <SkeletonLoader />,
}) => {
    return (
        <ErrorBoundary fallbackRender={hasError}>
            <React.Suspense fallback={loading()}>
                <SuspendableRendererInner
                    suspendable={suspendable}
                    hasValue={hasValue}
                />
            </React.Suspense>
        </ErrorBoundary>
    );
};

export const LoadableRenderer = ({
    loadable,
    hasValue,
    hasError = error => <Error />,
    loading = () => <SkeletonLoader />,
}) => {
    const renderers = { hasValue, hasError, loading };
    return renderers[loadable.state](loadable.contents);
};

LoadableRenderer.Error = Error;
LoadableRenderer.Spinner = Spinner;
LoadableRenderer.SkeletonLoader = SkeletonLoader;

LoadableRenderer.propTypes = {
    loadable: LoadablePropType(),
    hasValue: PropTypes.func.isRequired,
    hasError: PropTypes.func,
    loading: PropTypes.func,
};
