import EventEmitter from "events";
import {
    createLocationsList,
    mergeWithCurrentParams,
    registerLocations,
} from "./location.utils";

const locationChangedEvent = "locationChanged";

export class LocationRouterService {
    /**
     *
     * @type {Map}
     */
    locationsMap = null;
    /**
     *
     * @type {module:events.internal}
     */
    emitter = null;
    /**
     *
     * @type {router5}
     */
    routerService = null;

    /**
     *
     * @type {object}
     */
    lastMatchedLocation = null;

    constructor({ routerService, locations }) {
        this.locationsMap = new Map();
        this.emitter = new EventEmitter();
        this.routerService = routerService;

        this.registerLocations(locations);

        this._listenForRouteChanges();
    }

    registerLocations(locations) {
        const locationsList = createLocationsList(locations);

        registerLocations(this.routerService, locationsList);

        this.saveLocations(locationsList);
    }

    navigate(location, params = {}) {
        const defaultedParams = this.getRouteParams(location, params);
        return this.routerService.navigate(location.routeName, defaultedParams);
    }

    buildPath(location, params = {}) {
        const defaultedParams = this.getRouteParams(location, params);
        return this.routerService.buildPath(
            location.routeName,
            defaultedParams,
        );
    }

    getRouteParams(location, params = {}) {
        return mergeWithCurrentParams(
            this.routerService,
            location.routeName,
            params,
        );
    }

    onLocationChanged(clb) {
        this.emitter.on(locationChangedEvent, clb);

        // pass the last matched location also to the late coming callbacks
        if (this.lastMatchedLocation) {
            clb(this.lastMatchedLocation);
        }
    }

    removeLocationChangeListener(clb) {
        this.emitter.removeListener(locationChangedEvent, clb);
    }

    saveLocations(locationsList) {
        // save location in local map so it can be found fast
        // and given to the onLocationChanged listeners
        locationsList.forEach(location =>
            this.locationsMap.set(location.routeName, location),
        );
    }

    getLocationByRouteName(routeName) {
        return this.locationsMap.get(routeName);
    }

    _listenForRouteChanges() {
        this.routerService.subscribe(this._notifyLocationChanged);
    }

    _notifyLocationChanged = ({ route }) => {
        this.lastMatchedLocation = this.getLocationByRouteName(route.name);

        this.emitter.emit(locationChangedEvent, this.lastMatchedLocation);
    };
}
