import { Forms } from "@pricefx/unity-components";
import PropTypes from "prop-types";
import identity from "lodash/identity";
import React from "react";
import { EntityIdsPickerTable as DesignSystemEntityIdsPickerTable } from "../../index";

const { Field } = Forms;

export const EntityIdsPickerTable = ({ ...props }) => {
    return (
        <Field
            as={DesignSystemEntityIdsPickerTable}
            label={null}
            from={identity}
            {...props}
        />
    );
};

EntityIdsPickerTable.propTypes = {
    entities: PropTypes.array.isRequired,
    idKey: PropTypes.string.isRequired,
    createColumns: PropTypes.func.isRequired,
    title: PropTypes.node.isRequired,
    addButtonLabel: PropTypes.node.isRequired,
    addModalTitle: PropTypes.node.isRequired,
};
