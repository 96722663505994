// TODO: FIXME: Need this polyfill beacuse of passing auth headers, until we accept cookies auth
import { useDic } from "@/components/Dic/useDic.hook";
import { LOGIN_TOKEN_NAME } from "@/security/security";
import { isProduction } from "@/utils/env.utils";
import { EventSourcePolyfill } from "event-source-polyfill";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";

const initialState = {
    eventSource: null,
};

export const SSEContext = React.createContext(initialState);

const getSSEEndpoint = () =>
    isProduction()
        ? "https://platform-manager-be-eu-west-1.plprod.pricefx.com/api/sse"
        : "https://platform-manager-be-eu-west-1.plqa.pricefx.com/api/sse";

const connectSSE = ({ headers, onOpen, onError }) => {
    const eventSource = new EventSourcePolyfill(getSSEEndpoint(), {
        headers,
        heartbeatTimeout: 60000,
    });
    eventSource.onopen = onOpen;
    eventSource.onerror = onError;
};
const MAX_RECONNECTS = 5;
const MINUTE = 60 * 1000;

const SSEContextProvider = ({ children }) => {
    const [state, setState] = useState({
        eventSource: null,
        reconnectTry: 1,
    });
    const { authenticationService } = useDic();
    const eventSourceRef = useRef(null);

    const isLoggedIn = authenticationService.isLoggedIn();
    const getHeaderWithAuth = useCallback(async () => {
        if (localStorage.getItem(LOGIN_TOKEN_NAME) != null) {
            return {
                [LOGIN_TOKEN_NAME]: localStorage.getItem(LOGIN_TOKEN_NAME),
            };
        } else {
            return {
                Authorization: `Bearer ${authenticationService.getToken()}`,
            };
        }
    }, [authenticationService]);

    useEffect(() => {
        const connectSSEAsync = async () => {
            connectSSE({
                headers: await getHeaderWithAuth(),
                onOpen,
                onError,
            });
        };
        const onOpen = e => {
            eventSourceRef.current = e.target;
            setState({ eventSource: e.target, reconnectTry: 1 });
        };
        const onError = e => {
            if (e.status === 401 || e.status === 503) {
                console.info(
                    `Trying to reconnect SSE in ${state.reconnectTry} minute.`,
                );
                setTimeout(() => {
                    setState(s => ({
                        ...s,
                        reconnectTry: s.reconnectTry + 1,
                    }));
                }, state.reconnectTry * MINUTE);
            }
        };
        if (isLoggedIn && state.reconnectTry <= MAX_RECONNECTS) {
            connectSSEAsync();
            return () => eventSourceRef.current?.close();
        }
    }, [getHeaderWithAuth, isLoggedIn, state.reconnectTry]);

    return <SSEContext.Provider value={state}>{children}</SSEContext.Provider>;
};

SSEContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SSEContextProvider;
