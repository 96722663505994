export function isRouteActive(name, exact, matchedRoute) {
    if (!matchedRoute) {
        return false;
    } else if (exact && name !== matchedRoute.name) {
        return false;
    } else if (!exact && !matchedRoute.name.startsWith(name)) {
        return false;
    }

    return true;
}
