import FieldGridNotPadded from "@/components/DataUploads/SftpManagement/FieldGridNotPadded.component";
import { Button, Forms, Gap } from "@/components/DesignSystem";
import { DeleteRowButton } from "@/components/Mappers/MapperTableWithCustomization/components/DeleteRowButton";
import { t } from "@/translations";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import PropTypes from "prop-types";
import React from "react";
import "./Switch.style.less";

const LAYOUT = [{ width: "320px" }, { width: "85px" }];
export const List = ({
    layout = LAYOUT,
    name,
    label,
    initialValue = [""],
    ...props
}) => {
    return (
        <FieldGridNotPadded>
            <Forms.FieldGrid layout={layout}>
                <Forms.List name={name} initialValue={initialValue}>
                    {({ rows, add, remove }) => (
                        <>
                            {rows.map(({ id, fieldProps }, index) => (
                                <Forms.FieldGrid.Row key={id}>
                                    <Forms.Fields.Input
                                        label={label}
                                        {...fieldProps(name)}
                                        autoComplete={
                                            rows.length === index + 1
                                                ? "email"
                                                : "nofill"
                                        }
                                        {...props}
                                    />
                                    <Forms.UIField name="actions">
                                        <DeleteRowButton
                                            onClick={() => remove(id)}
                                        />
                                    </Forms.UIField>
                                </Forms.FieldGrid.Row>
                            ))}
                            <Gap size="small" />
                            <Button
                                icon={Plus}
                                label={t("general.add-field")}
                                onClick={add}
                                disabled={false}
                            />
                        </>
                    )}
                </Forms.List>
            </Forms.FieldGrid>
        </FieldGridNotPadded>
    );
};

List.propTypes = {
    layout: PropTypes.array,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    initialValue: PropTypes.array,
};
