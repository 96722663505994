import React from "react";
import Image from "./Image";
import { wrapComponent } from "../utils";

const componentClass = "ucImageVerticallyCenteredImage";

const ImageContainer = wrapComponent("div", {
    className: componentClass
});

/**
 * Vertically centers an image
 */
const VerticallyCenteredImage = ({
    src,
    alt,
    containerHeight,
    containerProps = {},
    ...props
}) => {
    // use height with px otherwise this component will break the whole page when used
    const heightPx = `${containerHeight}px`;

    return (
        <ImageContainer
            // eslint-disable-next-line react/forbid-component-props
            style={{ height: heightPx, lineHeight: heightPx }}
            {...containerProps}
        >
            <Image
                alt={alt}
                className={`${componentClass}__image`}
                src={src}
                {...props}
            />
        </ImageContainer>
    );
};

VerticallyCenteredImage.defaultProps = {
    containerProps: {}
};

export default VerticallyCenteredImage;
