import { useCallback, useEffect, useRef } from "react";

export const useCurrentHandler = fn => {
    const ref = useRef(fn);
    useEffect(() => {
        ref.current = fn;
    }, [fn]);

    const memoizedFn = useCallback((...args) => ref.current(...args), []);

    return memoizedFn;
};
