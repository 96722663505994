import { useDic } from "@/components/Dic/useDic.hook";
import { ErrorBoundary } from "@/components/Error";
import { useDefaultParams } from "@/components/hooks/useDefaultParams.hook";
import { defaults } from "lodash";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import React from "react";
import { ConnectedLink } from "react-router5";

/**
 * Preload lazy component on location item hover,
 * so it will be faster accessible to user.
 * @param {object} $location
 * @return {function}
 */
function resolveOnMouseOverFn($location) {
    const { component } = $location;
    return component && component.preload ? () => component.preload() : noop;
}

export function LocationLink({ $location, $params = {}, children, ...props }) {
    const { locationRouterService } = useDic();
    const defaultParams = useDefaultParams();
    const routeParams = locationRouterService.getRouteParams(
        $location,
        defaults($params, defaultParams),
    );
    const onError = (error, info) => {
        // Happens sometimes when $params are not explicitly passed to LocationLink
        // during transition to paramless route,
        // locationRouterService.getRouteParams is called before actual transition causes route to unmount
        console.error("LocationLink error", {
            error,
            info,
            $location,
            routeParams,
            $params,
        });
    };

    return (
        <ErrorBoundary onError={onError}>
            <ConnectedLink
                {...props}
                routeName={$location.routeName}
                routeParams={routeParams}
                onMouseOver={resolveOnMouseOverFn($location)}
            >
                {children}
            </ConnectedLink>
        </ErrorBoundary>
    );
}

LocationLink.propTypes = {
    $location: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    $params: PropTypes.object,
};
