import { Gap, H4 } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import PropTypes from "prop-types";
import React from "react";

const createDefaultColumns = ({
    labelColumnLabel = "TODO:pass labelColumnLabel to TableSelect",
}) => [
    {
        type: fieldTypes.TEXT,
        name: "label",
        label: labelColumnLabel,
        // TODO: TB
        canSort: false,
        canFilter: false,
    },
    {
        type: fieldTypes.TEXT,
        name: "value",
        label: "",
        visible: false,
        canSort: false,
        canFilter: false,
    },
];

export const TableSelect = ({
    value,
    onChange,
    dataSource,
    labelColumnLabel,
    columns = createDefaultColumns({ labelColumnLabel }),
    idKey = "value",
    tableProps = {},
}) => {
    const rowSelection = useSyncRowSelection(value, onChange);

    return (
        <div style={{ width: "100%" }}>
            <TableLocalFiltered
                columns={columns}
                dataSource={dataSource}
                rowSelection={rowSelection}
                pagination={false}
                rowKey={idKey}
                {...tableProps}
            />
        </div>
    );
};

TableSelect.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    dataSource: PropTypes.array.isRequired,
    labelColumnLabel: PropTypes.string,
    columns: PropTypes.array.isRequired,
    idKey: PropTypes.string.isRequired,
    tableProps: PropTypes.object,
};
