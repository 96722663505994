import { useAppProperties } from "@/components/AppProperties/useAppProperties.hook";
import { useDic } from "@/components/Dic/useDic.hook";
import SSEType from "@/components/ServerSideEvents/types";
import useSSE from "@/components/ServerSideEvents/useSSE.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useLoadableHasValueChangedEffect } from "@/modules/loadable/useLoadableHasValueChangedEffect.hook";
import { getData } from "@/services/utils";
import { t } from "@/translations";
import { formatFilesizeBytes } from "@/utils/formats/bytes";
import { getErrorMessageFromError } from "@/utils/state/error.utils";
import { useEffect, useState } from "react";
import { getAxiosCancelToken } from "../../axios";

export const PROGRESS_STATUS = {
    ACTIVE: "active",
    SUCCESS: "success",
    EXCEPTION: "exception",
};

export const STORAGE_VARIANT = {
    S3: "S3",
    DEFAULT: "DEFAULT",
};

const S3_MAX_FILE_SIZE = 2147483648;

const DEFAULT_STATE = {
    progress: null,
    progressStatus: null,
    abortToken: null,
    errorMessage: null,
};

export const useUploadStorage = ({
    storageVariant = STORAGE_VARIANT.DEFAULT,
    onError,
    onAbort: onAbortPassed,
    onUploadSuccess,
    onUploadStart,
    onUploadError,
    destination,
    destinationId,
}) => {
    const { axiosService, partitionService } = useDic();
    const [maxFileSize, setMaxFileSize] = useState(0);
    const [state, setState] = useState(DEFAULT_STATE);

    const onErrorCurrent = useCurrentHandler(onError);
    useEffect(() => {
        if (state.errorMessage)
            onErrorCurrent({ errorMessage: state.errorMessage });
    }, [state.errorMessage, onErrorCurrent]);

    const onChange = info => {
        setState(state => ({ ...state, progress: info }));
    };

    const { appPropertiesResource } = useAppProperties();

    useSSE(SSEType.NOTIFICATION, notification => {
        try {
            if (notification.type === SSEType.S3_FILE_PREVIEW_UPLOADED) {
                const fileInfo = JSON.parse(notification.data);

                if (fileInfo.storageName === state.objectKey) {
                    onUploadSuccess({
                        storageVariant,
                        id: fileInfo.fileUploadId,
                        originalName: fileInfo.originalFileName,
                    });
                }
            }
        } catch (error) {
            onErrorCurrent({ errorMessage: error.message });
        }
    });

    useLoadableHasValueChangedEffect(
        appPropertiesResource.loadable,
        () => {
            switch (storageVariant) {
                case STORAGE_VARIANT.S3:
                    setMaxFileSize(S3_MAX_FILE_SIZE);
                    break;

                case STORAGE_VARIANT.DEFAULT:
                default:
                    setMaxFileSize(
                        appPropertiesResource.loadable.valueMaybe().data
                            .maxUploadFileSize,
                    );
            }
        },
        [],
    );

    switch (storageVariant) {
        case STORAGE_VARIANT.S3:
            return {
                processFile: async ({ file, onError, onProgress, onSuccess }) =>
                    new Promise((resolve, reject) => {
                        if (file.size > maxFileSize)
                            return reject(
                                new Error(
                                    t("upload-filesize-exceeded", {
                                        maxFileSize:
                                            formatFilesizeBytes(maxFileSize),
                                    }),
                                ),
                            );
                        const xhr = new XMLHttpRequest();

                        setState(state => ({
                            ...state,
                            xhr,
                            progressStatus: PROGRESS_STATUS.ACTIVE,
                            errorMessage: null,
                        }));
                        onUploadStart?.();

                        xhr.upload.addEventListener("progress", e => {
                            e.percent = (e.loaded / e.total) * 100;
                            onProgress(e);
                        });

                        const formData = new FormData();
                        formData.append("file", file);

                        xhr.onreadystatechange = function () {
                            if (xhr.readyState === XMLHttpRequest.DONE) {
                                if (xhr.status === 200) {
                                    onSuccess(null, file);
                                    setState(state => ({
                                        ...state,
                                        progressStatus: PROGRESS_STATUS.SUCCESS,
                                        xhr: null,
                                        errorMessage: null,
                                    }));
                                } else {
                                    reject(xhr.response);
                                }
                            }
                        };
                        partitionService
                            .getPresignedUrlForFileUpload({
                                fileName: file.name,
                                destination,
                                destinationId,
                            })
                            .then(getData)
                            .then(response => {
                                setState(state => ({
                                    ...state,
                                    objectKey: response.objectKey,
                                }));
                                return response.url;
                            })
                            .then(url => {
                                xhr.open("PUT", url, true);
                                xhr.send(file);
                            })
                            .then(resolve)
                            .catch(reject);
                    }).catch(error => {
                        onUploadError?.();
                        onError(error);
                        setState(state => ({
                            ...state,
                            progress: null,
                            progressStatus: PROGRESS_STATUS.EXCEPTION,
                            xhr: null,
                            errorMessage:
                                getErrorMessageFromError(error) ||
                                error.message,
                        }));
                    }),
                onAbort: () => {
                    state.xhr?.abort();
                    setState(DEFAULT_STATE);
                    onAbortPassed?.();
                },
                onChange,
                state,
                maxFileSize,
            };
        case STORAGE_VARIANT.DEFAULT:
        default:
            return {
                processFile: async ({
                    data,
                    file,
                    headers,
                    onError,
                    onProgress,
                    onSuccess,
                }) => {
                    try {
                        if (file.size > maxFileSize)
                            throw new Error(
                                t("upload-filesize-exceeded", {
                                    maxFileSize:
                                        formatFilesizeBytes(maxFileSize),
                                }),
                            );
                        const formData = new FormData();
                        formData.append("file", file);
                        const source = getAxiosCancelToken();
                        const header = headers;
                        header["content-type"] =
                            "application/x-www-form-urlencoded";

                        setState(state => ({
                            ...state,
                            abortToken: source,
                            progressStatus: "active",
                            errorMessage: null,
                        }));
                        onUploadStart?.();

                        const result = await axiosService.post(
                            data.url,
                            formData,
                            {
                                onUploadProgress: e => {
                                    e.percent = (e.loaded / e.total) * 100;
                                    onProgress(e);
                                },
                                cancelToken: source.token,
                                header,
                            },
                        );
                        const uploadedFile = result.data;
                        onSuccess(null, uploadedFile);
                        onUploadSuccess({ storageVariant, ...uploadedFile });
                        setState(state => ({
                            ...state,
                            progressStatus: "success",
                            abortToken: null,
                            errorMessage: null,
                        }));
                    } catch (error) {
                        onError(error);
                        onUploadError?.();
                        setState(state => ({
                            ...state,
                            progress: null,
                            progressStatus: PROGRESS_STATUS.EXCEPTION,
                            abortToken: null,
                            errorMessage:
                                getErrorMessageFromError(error) ||
                                error.message,
                        }));
                    }
                },
                onChange,
                onAbort: () => {
                    if (state.abortToken) {
                        state.abortToken.cancel();
                    }
                    setState(DEFAULT_STATE);
                    onAbortPassed?.();
                },
                state,
                maxFileSize,
            };
    }
};
