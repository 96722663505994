const groupNamePattern = /^([\w-]+:)*[\w-]+(@([\w-]+\.)+[\w]+)?$/i;
export const isGroupNameValid = name => groupNamePattern.test(name);

const userNamePattern = /^([\w-]+\.)*[\w-]+(@([\w-]+\.)+[\w]+)?$/i;
export const isUserNameValid = name => userNamePattern.test(name);

const MAX_EMAIL_LENGTH = 254;
const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isEmailValid = email =>
    emailRegex.test(email.trim()) && email.trim().length <= MAX_EMAIL_LENGTH;

var urlPatern =
    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
export const isUrlValid = value => !!urlPatern.test(value);
