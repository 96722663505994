import { L18NContext } from "@pricefx/unity-components";
import PropTypes from "prop-types";
import React from "react";

import { createTranslations } from "./createTranslations";
// Some unity components translations checking mechanism would be nice,
// json fetched from unity translation server
import en_gb from "./unity.en.json";
import { getFormatters } from "@pricefx/pricefx-js/dist/es/src/l10n/formatters";

export const locale = "en_gb";

// TODO: refactor translations (platform vs unity) and fetch from server
const defaultTranslations = createTranslations(locale, { en_gb });

export const L18NProvider = ({
    children,
    translations = defaultTranslations,
}) => (
    <L18NContext.Provider value={{ translations, formatters: getFormatters() }}>
        {children}
    </L18NContext.Provider>
);

L18NProvider.propTypes = {
    children: PropTypes.node.isRequired,
    translations: PropTypes.object,
};
