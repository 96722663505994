import { Table } from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useExportMutation } from "@/components/DesignSystem/Table/hooks/useExportCsv.hook";
import { useMemoByDeepEquality } from "@/components/hooks/useMemoByDeepEquality.hook";
import { isLoading } from "@/modules/loadable";
import { useRouteParams } from "@/modules/router";
import { T, t } from "@/translations";
import { advancedFilterUtils } from "@pricefx/unity-components";
import { isEqual, isNil, omitBy } from "lodash";
import find from "lodash/find";
import { curryN, pipe } from "lodash/fp";
import mapValues from "lodash/mapValues";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useReducer, useRef } from "react";
import { DislocatedExportButton } from "../TableWithPreferences/DislocatedExportButton";
import {
    FILTER_CHANGE,
    INIT_STATE,
    PAGINATION_CHANGE,
    SORT_CHANGE,
    reducer,
} from "./PageableTable.reducer";

export const PAGE_SIZE = 500;
export const EXPORT_PAGE_SIZE = `page=1&size=${PAGE_SIZE}`;

export const MAX_EXPORT_SIZE = "10000";

const showTotalFunction = totalRows => (
    <T id="table-total-rows" values={{ totalRows }} />
);

export const normalizeFilters = ({ filter, columns }) =>
    // since our API doesn't accept 0 and 1 as boolean values (as unity table quickfilter provides them)
    // make sure those are converted true / false
    mapValues(filter, ({ value }, name) =>
        find(columns, { name })?.type === fieldTypes.BOOLEAN
            ? Boolean(value)
            : value,
    );

const ORDER = {
    ascending: "asc",
    descending: "desc",
};

export const PAGE_SIZE_OPTIONS = ["30", "50", "100", "150", "200", "300"];

export const buildSort = sorter =>
    sorter.fieldName
        ? sorter.sortDir && `${sorter.fieldName},${ORDER[sorter.sortDir]}`
        : undefined;

export const buildUrl = curryN(2, (exportUrl = "", sort = "") => {
    if (sort) {
        return exportUrl.includes("?")
            ? `${exportUrl}&${EXPORT_PAGE_SIZE}&sort=${sort}`
            : `${exportUrl}?${EXPORT_PAGE_SIZE}&sort=${sort}`;
    }
    return exportUrl.includes("?")
        ? `${exportUrl}&${EXPORT_PAGE_SIZE}`
        : `${exportUrl}?${EXPORT_PAGE_SIZE}`;
});

const PageableTable = ({
    columns,
    defaultSort = INIT_STATE.sorter, // TODO: remove
    pagination = INIT_STATE.pagination,
    sorter = INIT_STATE.sorter,
    filter = INIT_STATE.filter,
    subsetFilter,
    fetchFlag,
    fetchPage,
    hasColumnAutofit = true,
    hasColumnResizing = true,
    columnsAutoWidth, // for columns resizing either this needs to be true (set to true when fixed is true) or columns width must be specified
    hasQuickFilters = true,
    loading,
    page = {},
    onPaginationChange: onPaginationChangeProp,
    onSortChange: onSortChangeProp,
    onFilterChange: onFilterChangeProp,
    customPageSizeOptions,
    ExportButton = DislocatedExportButton,
    exportUrl,
    elkQuery,
    exportable = true,
    ...rest
}) => {
    const { tableFilters } = useRouteParams();
    const [state, dispatch] = useReducer(reducer, {
        ...INIT_STATE,
        sorter: sorter || defaultSort,
        pagination,
        filter: tableFilters || filter,
    });

    // sync pagination from prop
    useEffect(() => {
        if (!pagination || pagination.pageSize === state.pagination.pageSize)
            return;
        // console.log(
        //     "%cPageableTable - effect sync pagination",
        //     "color:green;",
        //     { pagination, state }
        // );
        dispatch({
            type: PAGINATION_CHANGE,
            data: { current: 1, pageSize: pagination.pageSize },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);
    // sync sort from prop
    useEffect(() => {
        if (!sorter || isEqual(sorter, state.sorter)) return;
        dispatch({
            type: SORT_CHANGE,
            data: sorter,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorter]);
    // sync filter from prop
    useEffect(() => {
        if (
            !filter ||
            isEqual(filter, state.filter) ||
            isEqual(tableFilters, state.filter)
        )
            return;
        dispatch({
            type: FILTER_CHANGE,
            data: filter,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const mapFilteredValues = enabledFilters =>
        enabledFilters
            ? Object.keys(enabledFilters).reduce(
                  (filters, columnName) => ({
                      ...filters,
                      [columnName]: {
                          column: columns.find(
                              column => column.name === columnName,
                          ),
                          value: state.filter[columnName]?.value,
                          prefix: state.filter[columnName]?.prefix,
                      },
                  }),
                  {},
              )
            : {};

    const buildFilterCriteriaForAPI = ({
        quickFilters,
        // advancedFilterRules property is ready for implementation of Advanced Filter table feature
        advancedFilterRules = null,
        columns,
        subsetFilter,
    }) =>
        advancedFilterUtils.combinePrefixFilters([
            advancedFilterUtils.mergeFiltersForAPI(
                mapFilteredValues(
                    normalizeFilters({ filter: quickFilters, columns }),
                ),
                advancedFilterRules,
            ),
            subsetFilter,
        ]);

    const fetchParams = useMemoByDeepEquality([
        state.pagination.current,
        state.pagination.pageSize,
        buildSort(state.sorter),
        buildFilterCriteriaForAPI({
            quickFilters: state.filter,
            columns,
            subsetFilter,
        }),
    ]);
    // console.log("%c[PT.rndr]", "color:crimson", {
    //     fetchParams,
    //     state,
    //     columns,
    //     subsetFilter,
    // });
    useEffect(() => {
        fetchPage(...fetchParams);
    }, [fetchPage, fetchParams, fetchFlag]);

    const onPaginationChangePropRef = useRef(onPaginationChangeProp);
    onPaginationChangePropRef.current = onPaginationChangeProp;
    const onPaginationChange = useCallback((current, pageSize) => {
        onPaginationChangePropRef.current?.({ current, pageSize });
        dispatch({
            type: PAGINATION_CHANGE,
            data: { current, pageSize },
        });
    }, []);

    useEffect(() => {
        if (page.number > 0 && page.content?.length < 1 && !loading) {
            onPaginationChange(page.number, page.size);
        }
    }, [
        onPaginationChange,
        page.content?.length,
        page.number,
        page.size,
        loading,
    ]);

    const onSortChange = sortOptions => {
        onSortChangeProp?.(sortOptions);
        dispatch({
            type: SORT_CHANGE,
            data: sortOptions ? sortOptions : {},
        });
    };

    const onFilterChange = (value, columnName, prefix) => {
        const newFilter = omitBy(
            { ...state.filter, [columnName]: { value, prefix } },
            ({ value }) => isNil(prefix) && (isNil(value) || value === ""),
        );
        onFilterChangeProp?.(newFilter);
        dispatch({
            type: FILTER_CHANGE,
            data: newFilter,
        });
    };

    const quickFilters = {
        values: mapFilteredValues(state.filter),
        setFilter: (value, column, prefix) => {
            onFilterChange(value, column.name, prefix);
        },
    };

    const exportMutation = useExportMutation({
        fileName: t("export-default-name"),
        exportUrl: pipe(buildSort, buildUrl(exportUrl))(state.sorter),
        columns,
    });
    const onExport = () => {
        exportMutation.mutate({
            filters: buildFilterCriteriaForAPI({
                quickFilters: state.filter,
                columns,
                subsetFilter,
            }),
            query: { ...elkQuery, size: PAGE_SIZE },
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
    };

    return (
        <>
            {exportable && (
                <ExportButton
                    exportDisabled={page.totalElements >= MAX_EXPORT_SIZE}
                    exportVisible={!!exportUrl}
                    onExport={onExport}
                    exporting={isLoading(exportMutation)}
                />
            )}
            <Table
                columns={columns}
                dataSource={page.content || []}
                hasColumnAutofit={hasColumnAutofit}
                hasColumnResizing={hasColumnResizing}
                pagination={{
                    onChange: onPaginationChange,
                    showTotal: showTotalFunction,
                    current: page.number + 1,
                    pageSize: page.size,
                    total: page.totalElements,
                    pageSizeOptions: customPageSizeOptions ?? PAGE_SIZE_OPTIONS,
                    showSizeChanger: true,
                    showQuickJumper: true,
                }}
                quickFilters={hasQuickFilters ? quickFilters : null}
                sorting={{
                    onSort: onSortChange,
                    rules: state.sorter.fieldName
                        ? {
                              fieldName: state.sorter.fieldName,
                              sortDir: state.sorter.sortDir,
                              sortSpecifiers: [
                                  // TODO: investigate why is state duplicated, preferences?
                                  {
                                      direction: state.sorter.sortDir,
                                      property: state.sorter.fieldName,
                                  },
                              ],
                          }
                        : null,
                }}
                columnsAutoWidth={columnsAutoWidth}
                loading={loading}
                {...rest}
            />
        </>
    );
};

PageableTable.propTypes = {
    columns: PropTypes.array.isRequired,
    defaultSort: PropTypes.object,
    fetchFlag: PropTypes.any,
    fetchPage: PropTypes.func.isRequired,
    hasColumnAutofit: PropTypes.bool,
    hasColumnResizing: PropTypes.bool,
    columnsAutoWidth: PropTypes.bool,
    hasQuickFilters: PropTypes.bool,
    loading: PropTypes.bool,
    page: PropTypes.object,
    customPageSizeOptions: PropTypes.array,
    subsetFilter: PropTypes.object,
};

export default PageableTable;
