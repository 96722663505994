import { t } from "@/translations";
import defaults from "lodash/fp/defaults";
import pipe from "lodash/fp/pipe";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useRef, useState } from "react";
import uuid from "uuid/v4";
import { ConfirmModal } from "./ConfirmModal";

const logMissingImplementation = () =>
    console.log("Provide implementation for ConfirmModalContext");

export const ConfirmModalContext = React.createContext({
    open: logMissingImplementation,
    cancelAll: logMissingImplementation,
    confirm: logMissingImplementation,
    confirmDelete: logMissingImplementation,
});

const CONFIG = {
    CONFIRM: {
        okText: t("general.yes"),
        cancelText: t("general.no"),
    },
    CONFIRM_DELETE: {
        autoFocusButton: null,
        okText: t("general.delete"),
        okButtonProps: {
            danger: true,
        },
        cancelText: t("general.cancel"),
    },
};

export const isThenable = maybePromise =>
    typeof maybePromise?.then === "function";

export const ConfirmModalProvider = ({ children }) => {
    const [modals, setModals] = useState([]);

    const open = useCallback(
        config =>
            new Promise(resolve => {
                const id = uuid();
                const changeModalProps = newProps => {
                    setModals(modals =>
                        modals.map(modal =>
                            modal.id !== id
                                ? modal
                                : {
                                      ...modal,
                                      ...newProps,
                                  },
                        ),
                    );
                };
                // Starts modal "close" animation
                const hide = () => changeModalProps({ visible: false });

                const handleAsync =
                    (maybeHandler, resolvePromiseValue) =>
                    async (...args) => {
                        let handlerResult = maybeHandler?.(...args);
                        if (isThenable(handlerResult)) {
                            changeModalProps({ pending: true });
                            handlerResult = await handlerResult.catch(e => {
                                changeModalProps({ pending: false });
                                config.onHandlerError?.(e, { hide });
                                throw e;
                            });
                            hide();
                            changeModalProps({ pending: false });
                            // During animation, modal is not rerendered
                            // animation needs to start in pending state, otherwise buttons can be clicked more than once
                        } else {
                            hide();
                        }
                        resolve(resolvePromiseValue);
                        return handlerResult;
                    };
                const afterClose = () => {
                    // destroy after "close" animation
                    config.afterClose?.();
                    setModals(modals =>
                        modals.filter(modal => id !== modal.id),
                    );
                };
                const modal = {
                    id,
                    visible: true,
                    pending: false,
                    config,
                    handlers: {
                        onConfirm: handleAsync(config.onConfirm, true),
                        onCancel: handleAsync(config.onCancel, false),
                        onClose: handleAsync(
                            config.onClose || config.onCancel,
                            false,
                        ),
                        afterClose,
                        resolve,
                    },
                };
                setModals(modals => [...modals, modal]);
            }),

        [],
    );
    const modalsRef = useRef(modals);
    if (modalsRef.current !== modals) modalsRef.current = modals;
    const cancelAll = useCallback(
        () => modalsRef.current.forEach(modal => modal.handlers.onCancel()),
        [],
    );
    const confirm = useCallback(pipe(defaults(CONFIG.CONFIRM), open), []);
    const confirmDelete = useCallback(
        pipe(defaults(CONFIG.CONFIRM_DELETE), open),
        [],
    );

    const modalContext = useMemo(
        () => ({ open, cancelAll, confirm, confirmDelete }),
        [open, cancelAll, confirm, confirmDelete],
    );

    return (
        <ConfirmModalContext.Provider value={modalContext}>
            {children}
            {modals.map(({ id, visible, pending, handlers, config }) => (
                <ConfirmModal
                    key={id}
                    id={id}
                    visible={visible}
                    config={config}
                    handlers={handlers}
                    pending={pending}
                />
            ))}
        </ConfirmModalContext.Provider>
    );
};

ConfirmModalProvider.propTypes = {
    children: PropTypes.node,
};
