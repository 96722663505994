import { Forms } from "@pricefx/unity-components";
import cx from "classnames";
import identity from "lodash/identity";
import PropTypes from "prop-types";
import React from "react";
import { Switch as DesignSystemSwitch } from "../../index";
import "./Switch.style.less";

const { Field } = Forms;

export const Switch = ({ layout, noMaxWidth = false, ...props }) => {
    return (
        <div
            className={cx({
                pmSwitchField: true,
                "pmSwitchField--vertical": layout === "vertical",
                "pmSwitchField--narrow": !noMaxWidth,
                "pmSwitchField--wider": noMaxWidth,
            })}
        >
            <Field as={DesignSystemSwitch} from={identity} {...props} />
        </div>
    );
};

Switch.propTypes = {
    layout: PropTypes.string,
};
