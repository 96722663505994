import { useCallback, useEffect, useReducer, useRef, useState } from "react";

export const useBagMaybe = ({ getBag }) => {
    const [bagMaybe, setBagMaybe] = useState(undefined);
    const [token, _refreshBag] = useReducer(x => x + 1, 0);
    const refreshBag = useCallback(() => _refreshBag(), []);

    const runRef = useRef(0);
    useEffect(() => {
        const run = ++runRef.current;
        const runAsync = async () => {
            setBagMaybe(undefined);
            const bag = await getBag();
            if (run === runRef.current) setBagMaybe(bag);
        };
        runAsync();
    }, [token]);

    return [bagMaybe, refreshBag];
};
