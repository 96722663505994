import {
    transitDeserializer,
    transitSerializer,
} from "@/utils/sessionState/serializers";

const matchesAnyOf = (fullKey, partialKeysArray) => {
    return partialKeysArray.some(keys =>
        keys.every((key, i) => fullKey[i] === key),
    );
};

const getDefinitionMaybe = ({ method, args }) => {
    const definition = method === "debug" ? args[0] : undefined;
    if (method === "debug" && typeof definition !== "object")
        console.error("debug method needs definition");
    return definition;
};

/* >>>>>>>        TL;DR        <<<<<<<

window.logger.updatePersistedConfig({
    logLevels: ["error", "warn", "log", "debug"],
});
*/

let instance;
class Logger {
    transports;
    savedLogs = [];
    defaultConfig = {
        saveLogs: false,
        logLevels: ["error", "warn", "log"],
        // following keys affect ONLY logger.debug method
        // array of partial logGroupKey
        //  []                                      matches nothing
        //  [[]]                                    matches all
        //  [[], ["USER", "something"], ["AUTH"]]   matches all
        //  [["USER", "something"], ["AUTH"]]       matches [USER,something,...whatever] and [AUTH,...whatever]
        matchLogGroupKeys: [[]], // any item is []  => log all according to logLevels set
        omitLogGroupKeys: [], //    any item is []  => omit all
        debugLogGroupKeys: [], //   any item is []  => debugger for all
    };
    constructor(transports = []) {
        if (!instance) {
            this.transports = transports;
            instance = this;
        }
        return instance;
    }

    // init({ logLevel }) {
    //     try {
    //         this.logLevel = logLevel;
    //         this.env = env;
    //         if (this.env !== "dev") { this.initYourLogger({ env: this.env, options }); }
    //     } catch (e) { console.error(e); }
    // }

    // initYourLogger({ env, options }) {
    //     this.transports.push(new TheLoggerofYourChoice({ env, options }));
    // }

    definitionMatch(def, conf) {
        const keyMatch = matchesAnyOf(def.logGroupKey, conf.matchLogGroupKeys);
        const keyOmitted = !conf.omitLogGroupKeys?.length
            ? false
            : matchesAnyOf(def.logGroupKey, conf.omitLogGroupKeys);
        return keyMatch && !keyOmitted;
    }
    formatDefinition({ logGroupKey, msg, color, data = "" }) {
        const text = "%c" + `[${logGroupKey.join(" ")}] ` + msg;
        return [text, "color:" + color, data];
    }

    getPersistedConfig() {
        return transitDeserializer(
            localStorage.getItem("logger_config") || "{}",
        );
    }
    setPersistedConfig(config = {}) {
        // TODO: validate
        return localStorage.setItem("logger_config", transitSerializer(config));
    }
    updatePersistedConfig(overrideConfig = {}) {
        const prevConfig = this.getPersistedConfig();
        const newConfig = { ...prevConfig, ...overrideConfig };
        console.log("[logger.updatePersistedConfig]", {
            prevConfig,
            overrideConfig,
            newConfig,
        });
        return localStorage.setItem(
            "logger_config",
            transitSerializer(newConfig),
        );
    }

    resetConfig() {
        this.setPersistedConfig();
    }

    getConfig() {
        const persistedConfig = this.getPersistedConfig();
        const config = { ...this.defaultConfig, ...persistedConfig };
        return config;
    }

    shouldLog({ method, transport, definition }) {
        const config = this.getConfig();
        if (!config.logLevels.includes(method)) return false;
        if (!definition) return true;

        if (definition.transport === "console-only" && transport !== console)
            return false;

        return this.definitionMatch(definition, config);
    }

    sendToTransport({ method, args, transport }) {
        const definition = getDefinitionMaybe({ method, args });
        const shouldLog = this.shouldLog({ method, definition, transport });

        if (shouldLog) {
            if (definition)
                return transport[method](...this.formatDefinition(definition));
            else return transport[method](...args);
        }
    }
    send(method, ...args) {
        try {
            if (this.getConfig().saveLogs) {
                this.savedLogs.push({ method, args });
            }
            this.transports.forEach(t =>
                this.sendToTransport({ method, args, transport: t }),
            );
        } catch (e) {
            console.error("[Logger] error", e);
        }
    }
    log(...args) {
        return this.send("log", ...args);
    }
    error(...args) {
        return this.send("error", ...args);
    }
    warn(...args) {
        return this.send("warn", ...args);
    }
    debug({
        logGroupKey,
        msg = "",
        color = "black",
        data,
        transport = "console-only",
    }) {
        if (!Array.isArray(logGroupKey) || logGroupKey.length < 2) {
            throw new Error(
                `Specify logGroupKey as array of strings with at least 2 elements for effective logs filtering, e.g. ["ROUTING", "storeCurrentUrl"]`,
            );
        }
        const definition = { logGroupKey, msg, color, data, transport };
        const config = this.getConfig();
        this.send("debug", definition);

        return (() => {
            // we want to debug caller, not callee
            const shouldSetDebugger = matchesAnyOf(
                definition.logGroupKey,
                config.debugLogGroupKeys,
            );
            if (shouldSetDebugger) {
                // eslint-disable-next-line no-debugger
                debugger;
            }
        })();
    }
}
const transports = [console];
export const logger = new Logger(transports);

// TODO: move
window.logger = logger;
