import { isEqual } from "lodash";
import { useEffect, useRef } from "react";

const useCurrentRef = fn => {
    const ref = useRef(fn);
    useEffect(() => {
        ref.current = fn;
    }, [fn]);

    return ref;
};

export const useLoadableHasValueChangedEffect = (
    loadable,
    fn,
    deps = [],
    equals = isEqual,
) => {
    const fnRef = useCurrentRef(fn);
    const previousValueRef = useRef();
    const previousDepsRef = useRef(deps);

    useEffect(() => {
        if (loadable.state !== "hasValue") return;
        const value = loadable.valueMaybe();

        if (
            !equals(value, previousValueRef.current) ||
            !equals(deps, previousDepsRef.current)
        ) {
            previousValueRef.current = value;
            previousDepsRef.current = deps;
            fnRef.current(value);
        }
    }, [loadable, ...deps]);
};
