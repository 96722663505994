import PropTypes from "prop-types";
import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { ErrorBoundaryFallback } from "./ErrorBoundaryFallback";

export const ErrorBoundary = ({
    children,
    fallbackComponent = ErrorBoundaryFallback,
    onError,
}) => (
    <ReactErrorBoundary FallbackComponent={fallbackComponent} onError={onError}>
        {children}
    </ReactErrorBoundary>
);

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
    fallbackComponent: PropTypes.node,
};
