import { isEmpty } from "lodash";
import { useEffect } from "react";

// May be removed in the future, when uc/Forms will support setting initial values with validation
// TODO: investigate initial values on field level
export const useSetValidatedInitialValues = (
    { initialValues = {}, setValues, setTouched },
    deps = [],
) => {
    useEffect(() => {
        setValidatedInitialValues({ initialValues, setValues, setTouched });
    }, deps);
};

export const setValidatedInitialValues = ({
    initialValues = {},
    setValues,
    setTouched,
}) => {
    const initialTouched = Object.fromEntries(
        Object.entries(initialValues)
            .filter(([k, v]) => Boolean(v))
            .map(([k, v]) => [k, true]),
    );

    if (!isEmpty(initialValues) && !isEmpty(initialTouched)) {
        setValues(initialValues);
        setTouched(initialTouched);
    }
};
