import React from "react";
import { Skeleton as AntdSkeleton } from "antd";
import { Container } from "../Container";

export function Skeleton({ title, ...props }) {
    return (
        <Container>
            {title}
            <AntdSkeleton {...props} />
        </Container>
    );
}
