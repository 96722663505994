export const bytesRangeLabel = [
    "Bytes",
    "KB",
    "MB",
    "GB",
    "TB",
    "PB",
    "EB",
    "ZB",
    "YB",
];

export const findBytesRangeIndex = bytes => {
    if (bytes === 0) return 0;
    let k = 1024;
    return Math.floor(Math.log(bytes) / Math.log(k));
};

export const convertBytesWithRangeIndex = (bytes, index) => {
    let k = 1024;
    let dm = 2;
    return parseFloat((bytes / Math.pow(k, index)).toFixed(dm));
};

export const getSizeWithLabel = bytes => {
    let rangeIndex = findBytesRangeIndex(bytes);
    return (
        convertBytesWithRangeIndex(bytes, rangeIndex) +
        " " +
        bytesRangeLabel[rangeIndex]
    );
};

export const getSizeWithLabelUnknownWrapper = number =>
    number ? getSizeWithLabel(number) : "Unknown";

export const formatFilesizeBytes = bytes => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k * 10));
    return (
        parseFloat((bytes / Math.pow(k, i)).toFixed(0)) +
        " " +
        bytesRangeLabel[i]
    );
};

export default {
    convertBytesWithRangeIndex: convertBytesWithRangeIndex,
    findBytesRangeIndex: findBytesRangeIndex,
};
