import { Button, Gap, H4 } from "@/components/DesignSystem";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import { PlusOutlined } from "@ant-design/icons";
import eq from "lodash/fp/eq";
import groupBy from "lodash/fp/groupBy";
import reject from "lodash/fp/reject";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { AddEntitiesModal } from "./AddEntitiesModal";

export const EntityIdsPickerTable = ({
    value: pickedIds,
    onChange,
    entities,
    idKey,
    createColumns,
    title,
    addButtonLabel,
    addModalTitle,
    addModalDescription,
    addButtonProps,
    rowHeight,
}) => {
    const addEntitiesModal = useVisibility(false);
    // TODO: pick + omit -> measure perf
    // const byId = useMemo(() => keyBy(get(idKey), entities), [entities]);
    const { pickedEntities, availableEntities } = useMemo(
        () =>
            groupBy(
                entity =>
                    pickedIds?.includes(entity[idKey])
                        ? "pickedEntities"
                        : "availableEntities",
                entities,
            ),
        [entities, pickedIds, idKey],
    );

    const onAdd = useCallback(
        ids => {
            const newValue = [...new Set([...(pickedIds ?? []), ...ids])];
            onChange(newValue);
        },
        [onChange, pickedIds],
    );
    const onRemove = useCallback(
        entity => {
            const newValue = reject(eq(entity[idKey]), pickedIds);
            onChange(newValue);
        },
        [pickedIds, onChange, idKey],
    );
    const columns = useMemo(
        () => createColumns({ onRemove }),
        [onRemove, createColumns],
    );

    return (
        <div style={{ width: "100%" }}>
            {title && (
                <>
                    <H4>{title}</H4>
                    <Gap size="small" />
                </>
            )}

            <TableLocalFiltered
                columns={columns}
                dataSource={pickedEntities}
                pagination={false}
                rowKey={idKey}
                rowHeight={rowHeight}
            />
            <Button
                icon={PlusOutlined}
                label={addButtonLabel}
                onClick={addEntitiesModal.show}
                {...addButtonProps}
            />
            <AddEntitiesModal
                rowKey={idKey}
                visible={addEntitiesModal.visible}
                onClose={addEntitiesModal.hide}
                availableEntities={availableEntities}
                onAdd={onAdd}
                createColumns={createColumns}
                addModalTitle={addModalTitle}
                addModalDescription={addModalDescription}
            />
        </div>
    );
};

EntityIdsPickerTable.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    entities: PropTypes.array.isRequired,
    idKey: PropTypes.string.isRequired,
    createColumns: PropTypes.func.isRequired,
    title: PropTypes.node,
    addButtonLabel: PropTypes.node.isRequired,
    addModalTitle: PropTypes.node.isRequired,
    addModalDescription: PropTypes.node,
    addButtonProps: PropTypes.object,
};
