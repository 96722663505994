import { tapFn } from "@/components/Mappers/MapperTableWithCustomization/OptionsResourceSelect/MetadataAdvancedOptions";
import { t } from "@/translations";
import { MAX_FILE_SIZE_IN_MB, getFileList } from "@/utils/image/imageUtils";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { Upload } from "antd";
import { isEmpty, noop } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "./IconUpload.less";

const IconUpload = ({ onChange, value, ...rest }) => {
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        if (!isEmpty(value)) {
            setFileList(getFileList(value));
        }
    }, [value]);

    const onImageChange = async ({ fileList }) => {
        onChange(fileList);
        setFileList(fileList);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>
                {t("offer-edit.upload.text", {
                    maxSize: MAX_FILE_SIZE_IN_MB,
                })}
            </div>
        </div>
    );

    return (
        <Upload
            className="IconUpload"
            customRequest={tapFn("customRequest", noop)}
            multiple={false}
            beforeUpload={() => false}
            fileList={fileList}
            listType="picture-card"
            onChange={tapFn("onChange", onImageChange)}
            {...rest}
        >
            {fileList.length >= 1 ? null : uploadButton}
        </Upload>
    );
};

IconUpload.propTypes = {
    accept: PropTypes.string,
    onChange: PropTypes.func,
};

export default IconUpload;
