import { Upload as AntdUpload } from "@/components/DesignSystem";
import { t } from "@/translations";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./Dragger.style.less";

const AntdDragger = AntdUpload.Dragger;

const Dragger = ({ children, className, hintMessage, ...draggerProps }) => {
    return (
        <div className={cx("pmDragger", className)}>
            <AntdDragger {...draggerProps}>
                {children}
                <p className="pmDragger-title">{t("upload-drag-and-drop")}</p>
                {hintMessage && <p className="pmDragger-hint">{hintMessage}</p>}
            </AntdDragger>
        </div>
    );
};

Dragger.propTypes = {
    className: PropTypes.string,
    accept: PropTypes.string,
    hintMessage: PropTypes.node,
    onFileUploaded: PropTypes.func.isRequired,
    ErrorComponent: PropTypes.elementType,
};

export default Dragger;
