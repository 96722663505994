import { T } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import { Button } from "../Button";

export function CancelButton({ children, ...props }) {
    return (
        <Button
            type="text"
            label={children ? children : <T id="general.cancel" />}
            {...props}
        />
    );
}

CancelButton.propTypes = {
    children: PropTypes.node,
};
