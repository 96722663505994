import { Menu as UnityMenu } from "@pricefx/unity-components";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./Menu.styles.less";

export function Menu({ condensed, ...props }) {
    const classes = cx({
        pmMenu: true,
        "pmMenu--condensed": condensed,
    });

    return <UnityMenu className={classes} {...props} />;
}

Menu.propTypes = {
    // if the menu should be displayed in a compact way
    condensed: PropTypes.bool,
};

Menu.Item = UnityMenu.Item;
Menu.SubMenu = UnityMenu.SubMenu;
Menu.ItemGroup = UnityMenu.ItemGroup;
Menu.Divider = UnityMenu.Divider;
