import { useContext, useEffect } from "react";
import { PreventTransitionContext } from "../PreventTransitionProvider";

export const useOnLeave = fn => {
    const { onLeaveRef } = useContext(PreventTransitionContext);

    useEffect(() => {
        if (fn) {
            onLeaveRef.current = fn;
            return () => (onLeaveRef.current = null);
        }
    }, [fn, onLeaveRef]);
};
