import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";
// assets
import style from "./Spinner.less";

export const Spinner = ({ size = "large", text }) => (
    <div className={style.container}>
        <Spin className={style.spinner} tip={text} size={size} />
    </div>
);

Spinner.propTypes = {
    size: PropTypes.string,
    text: PropTypes.node,
};
