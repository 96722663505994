export function createLocationModel({ locationRouterService }) {
    return {
        state: {},
        effects: {
            navigate({ location, ...params }) {
                locationRouterService.navigate(location, params);
            },
        },
    };
}
