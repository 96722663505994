import { useRoute } from "@/modules/router/index";
import { useContext, useMemo } from "react";
import { getResource, RouteDataContext } from "../RouteDataContextProvider";

export const useRouteAccountInstances = () => {
    const { route } = useRoute();
    const context = useContext(RouteDataContext);

    const accountInstancesLoadable = useMemo(() => {
        return getResource(
            "accountInstances",
            "accountId",
            route.params?.accountId,
            context.resources,
        );
    }, [context.resources, route.params?.accountId]);

    return {
        accountInstancesLoadable,
    };
};
