import { useMemo, useRef } from "react";

const useUnchangedLengthDynamicDeps = deps => {
    const depsRef = useRef(deps);
    if (depsRef.current?.length !== deps?.length) {
        throw new Error("Cannot dynamicaly change dependencies length");
    }
    return deps;
};

export const useComposeLoadablesMemoized = loadables => {
    const loadablesDeps = useUnchangedLengthDynamicDeps(loadables);
    const memoized = useMemo(() => {
        const hasValue = loadablesDeps.every(
            loadable => loadable.state === "hasValue",
        );
        const hasError = loadablesDeps.some(
            loadable => loadable.state === "hasError",
        );
        const state = hasValue ? "hasValue" : hasError ? "hasError" : "loading";
        const contents = loadablesDeps.map(loadable =>
            loadable.state === state ? loadable.contents : undefined,
        ); // TODO - errors vs values

        return {
            state,
            contents,
            valueMaybe: () => (!hasValue ? undefined : contents),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, loadablesDeps);
    return memoized;
};
