// This code should be taken from:
// import { createTranslations } from "@pricefx/pricefx-js";
// but it is not published, package.json -> main is pointing to nonexisting files
// TODO: publish @pricefx/pricefx-js createTranslations

import get from "lodash/get";
import has from "lodash/has";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import isObject from "lodash/isObject";
import reduce from "lodash/reduce";
import split from "lodash/split";
import toLower from "lodash/toLower";
import trim from "lodash/trim";

export function normalizeLanguage(language) {
    if (isEmpty(language)) {
        return "en_gb";
    }
    const tokens = split(language.toLowerCase().replace("-", "_"), "_");

    const shortLang = trim(tokens[0]);
    const region = toLower(trim(tokens[1]));

    const lang = shortLang + (isEmpty(region) ? "" : "_" + region);

    if ("en_us" === lang) {
        return lang;
    } else if (shortLang === "en") {
        return "en_gb";
    } else if (
        includes(["cs", "de", "es", "fr", "ru", "tr", "pt"], shortLang)
    ) {
        return shortLang;
    }
    return "en_gb";
}

// type TranslationsData = {
//     en_gb?: object,
//     en_us?: object,
//     cs?: object,
//     de?: object,
//     es?: object,
//     fr?: object,
//     pt?: object,
//     ru?: object,
//     tr?: object
// };

class MapTranslations {
    currentLocale = "";
    translations = {};
    formatters = {};

    constructor(initialLocale, initialTranslations) {
        this.currentLocale = initialLocale;
        this.translations = isNil(initialTranslations)
            ? {
                  en_gb: {},
              }
            : initialTranslations;
    }

    composeKey(key, value) {
        return !isEmpty(value) ? `${key}${value}` : key;
    }

    setLanguage(newLocale) {
        this.currentLocale = normalizeLanguage(newLocale);
    }

    addTranslation(localeName, key, value) {
        const correctLanguage = normalizeLanguage(localeName);
        if (!has(this.translations, correctLanguage)) {
            this.translations[correctLanguage] = {};
        }
        this.translations[correctLanguage][key] = value;
    }

    tp(key, params) {
        const localeParam = get(params, "locale");
        const defaultValue = get(params, "default", key);
        const locale = isNil(localeParam) ? this.currentLocale : localeParam;

        if (isNil(locale)) {
            return `No locale defined.  Unable to resolve translation: "${key}"`;
        }

        const translationText =
            get(this.translations, `${locale}.${key}`) ||
            get(this.translations, `en_gb.${key}`);

        if (typeof translationText !== "string") {
            return defaultValue;
        }
        if (!isObject(params) || translationText === "") {
            return translationText;
        }
        return reduce(
            params,
            (result, value, key) => {
                // skip 'default' and 'locale' because they are used for other reasons than replacing
                if (key === "default" || key === "locale") return result;
                return result.replace(`{${key}}`, value);
            },
            translationText,
        );
    }

    t(key, fallback = "") {
        return this.tp(key, { default: fallback });
    }

    pick(labelMap) {
        return labelMap[this.currentLocale] || labelMap[""];
    }
}

// export type Translations = MapTranslations;

export function createTranslations(initialLocale, initialTranslations) {
    return new MapTranslations(initialLocale, initialTranslations);
}
