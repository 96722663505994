import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { UnauthorizedContent } from "./components/UnauthorizedContent/UnauthorizedContent.component";
import { includesPermission } from "./permission.utils";

export const SecurityContext = React.createContext({});

export { UnauthorizedContent } from "./components/UnauthorizedContent/UnauthorizedContent.component";

function ShowWhenHasPermission({
    hasPermission,
    children,
    unauthorizedContent = UnauthorizedContent,
}) {
    if (hasPermission()) {
        return <div>{children}</div>;
    } else {
        const Com = unauthorizedContent;
        return <Com />;
    }
}

export function VisibleWhenHasPermission({
    permission,
    permissionCheckFunc,
    children,
    unauthorizedContent,
    ...props
}) {
    return (
        <VisibleWhenHasPermissionOr
            permissions={[].concat(permission)}
            permissionCheckFunc={permissionCheckFunc}
            unauthorizedContent={unauthorizedContent}
            {...props}
        >
            {children}
        </VisibleWhenHasPermissionOr>
    );
}

VisibleWhenHasPermission.propTypes = {
    permission: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    permissionCheckFunc: PropTypes.func,
    children: PropTypes.any,
    unauthorizedContent: PropTypes.any,
};

export const hasAccessToLocation = (
    securityContext,
    { permissionFunc, permission: permissions },
) =>
    permissionFunc
        ? permissionFunc(securityContext)
        : includesPermission(securityContext, permissions);

function VisibleWhenHasPermissionOr({
    permissions,
    permissionCheckFunc,
    children,
    unauthorizedContent = React.Fragment,
    debug,
}) {
    const securityContextValue = useContext(SecurityContext);

    const hasAccess = hasAccessToLocation(securityContextValue, {
        permissionFunc: permissionCheckFunc,
        permission: permissions,
    });
    if (debug) {
        console.log("%c[VisibleWhenHasPermissionOr]", "color:crimson", {
            permissions,
            permissionCheckFunc,
            hasAccess,
            securityContextValue,
        });
    }

    if (hasAccess) {
        return children;
    } else {
        const Com = unauthorizedContent;
        return <Com />;
    }
}

VisibleWhenHasPermissionOr.propTypes = {
    permissions: PropTypes.array,
    permissionCheckFunc: PropTypes.func,
    children: PropTypes.any,
    unauthorizedContent: PropTypes.any,
};

export function showWhenHasPermissionsOtherwise(
    requiredPermissionsOr,
    ifPart,
    elsePart,
    permissionResolverFunc,
) {
    function show(condition) {
        if (condition) {
            return ifPart;
        } else {
            return elsePart;
        }
    }

    return (
        <SecurityContext.Consumer>
            {permissionResolverFunc
                ? d => show(permissionResolverFunc(d, requiredPermissionsOr))
                : d => show(includesPermission(d, requiredPermissionsOr))}
        </SecurityContext.Consumer>
    );
}

export const showWhenAuthorizedByPermissionOr = (
    permissions,
    unauthorizedContent,
) => {
    return WrappedComponent => {
        return props => {
            return (
                <SecurityContext.Consumer>
                    {d => (
                        <ShowWhenHasPermission
                            hasPermission={() =>
                                d.permissions &&
                                intersection(d.permissions, permissions)
                                    .length > 0
                            }
                            unauthorizedContent={unauthorizedContent}
                        >
                            <WrappedComponent {...props} />
                        </ShowWhenHasPermission>
                    )}
                </SecurityContext.Consumer>
            );
        };
    };
};

export const enabledWhenAuthorizedByPermission = permission => {
    return enabledWhenAuthorizedByPermissionFunction(
        permissions => permissions && permissions.includes(permission),
    );
};

export const enabledWhenAuthorizedByPermissionOr = requiredPermissionsOr => {
    return enabledWhenAuthorizedByPermissionFunction(
        permissions =>
            permissions &&
            intersection(permissions, requiredPermissionsOr).length > 0,
    );
};

export const enabledWhenAuthorizedByPermissionFunction = authFunction => {
    return WrappedComponent => {
        return props => {
            return (
                <SecurityContext.Consumer>
                    {d =>
                        authFunction(d.permissions) ? (
                            <WrappedComponent {...props} />
                        ) : null
                    }
                </SecurityContext.Consumer>
            );
        };
    };
};

export const isAuthorizedByPermission = (requiredPermission, permissions) => {
    return permissions && permissions.includes(requiredPermission);
};

export const isAuthorizedByPermissionOr = (requiredPermission, permissions) => {
    return (
        permissions && !isEmpty(intersection(permissions, requiredPermission))
    );
};
