import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { UserContext } from "@/security/UserContext";
import { isEmpty } from "lodash/fp";
import { useContext } from "react";

const isEnabledFeature = (basicInfo, accountId) => featureFlag => {
    const enabledForAccounts = basicInfo?.enabledFeatureFlags[featureFlag];
    return accountId && !isEmpty(enabledForAccounts)
        ? enabledForAccounts?.includes(accountId)
        : !!enabledForAccounts;
};

export function useFeatureFlag(featureFlag) {
    const { basicInfo } = useContext(UserContext);
    const { accountId } = useAccountAppParams();

    return isEnabledFeature(basicInfo, accountId)(featureFlag);
}
export function useCheckFeatureFlag() {
    const { basicInfo } = useContext(UserContext);
    const { accountId } = useAccountAppParams();

    return isEnabledFeature(basicInfo, accountId);
}
