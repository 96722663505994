import PropTypes from "prop-types";
import React, { createContext, useEffect, useMemo, useRef } from "react";
import { useRouter } from "react-router5";

const mockRef = {
    _current: undefined,
    get current() {
        console.error("Provide implementation for PreventTransitionContext");
        return this._current;
    },
    set current(value) {
        console.error("Provide implementation for PreventTransitionContext");
        this._current = value;
    },
};

export const PreventTransitionContext = createContext({
    onLeaveRef: mockRef,
});

export const PreventTransitionProvider = ({ children }) => {
    const router = useRouter();
    const routerRef = useRef(router);
    routerRef.current = router;

    const onLeaveRef = useRef();

    useEffect(() => {
        const middleware = router => (to, from, done) => {
            if (onLeaveRef.current) {
                onLeaveRef.current(done);
            } else {
                done();
            }
        };

        routerRef.current.useMiddleware(middleware);
        window.onbeforeunload = () =>
            onLeaveRef.current
                ? "Are you sure? Your work can be lost"
                : undefined;
        return () => {
            window.onbeforeunload = null;
        };
    }, []);

    const context = useMemo(() => ({ onLeaveRef }), []);

    return (
        <PreventTransitionContext.Provider value={context}>
            {children}
        </PreventTransitionContext.Provider>
    );
};

PreventTransitionProvider.propTypes = {
    children: PropTypes.node,
};
