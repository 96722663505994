import { Forms } from "@pricefx/unity-components";
import identity from "lodash/identity";
import React from "react";
import { DatePicker as DesignSystemDatePicker } from "../../index";

const { Field } = Forms;

export const DatePicker = ({ ...props }) => {
    return <Field as={DesignSystemDatePicker} from={identity} {...props} />;
};
