import { RadioGroup } from "@/components/DesignSystem";
import { Forms } from "@pricefx/unity-components";
import React from "react";
const { Field } = Forms;

const mapOptionsAsDisabled = (options, isDisabed) => {
    return isDisabed
        ? options.map(option => ({ ...option, disabled: true }))
        : options;
};

export const Radio = ({
    options,
    disabled = false,
    horizontal = false,
    ...props
}) => {
    return (
        <Field from={({ value }) => value} {...props}>
            <RadioGroup
                options={mapOptionsAsDisabled(options, disabled)}
                horizontal={horizontal}
            />
        </Field>
    );
};
