import { T } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button } from "../Button";
import { Icon } from "../Icon";
import { Input } from "../Input";
import styles from "./TextAreaWithCopy.styles.less";

export function TextAreaWithCopy({ value, ...props }) {
    return (
        <>
            <CopyToClipboard text={value}>
                <Button
                    type="text"
                    size="small"
                    className={styles.copyButtonContainer}
                    label={
                        <>
                            <Icon type="copy" />
                            <T id="general.copy" />
                        </>
                    }
                />
            </CopyToClipboard>
            <Input.TextArea value={value} {...props} />
        </>
    );
}

TextAreaWithCopy.propTypes = {
    value: PropTypes.string,
};
