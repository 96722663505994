import React from "react";
import { LocationRoute } from "./LocationRoute.component";
import { useLocationsList } from "./useLocationsList.hook";

export function LocationRoutes({ locations }) {
    const locationsList = useLocationsList(locations);

    return (
        <>
            {locationsList.map(l => (
                <LocationRoute key={l.routeName} $location={l} />
            ))}
        </>
    );
}
