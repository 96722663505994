import { Alert } from "@/components/DesignSystem";
import { logger } from "@/modules/logger";
import { t } from "@/translations";
import React from "react";
import { useRecoilState } from "recoil";
import { loginErrorState } from "../LoginPage";
import pageStyles from "../styles.less";
const lgk = () => ["AUTH", "LoginError"];

export const LoginError = () => {
    const [error, setError] = useRecoilState(loginErrorState);
    logger.debug({
        logGroupKey: lgk(),
        color: "chocolate",
        data: error,
    });
    if (!error) return null;

    return (
        <Alert
            message={t(`login.error.message.message`)}
            description={
                error.message ??
                t(`login.error.${error.type}.message.description`)
            }
            type="error"
            closable
            onClose={() => setError(null)}
            className={pageStyles.errorAlert}
        />
    );
};

LoginError.propTypes = {};
