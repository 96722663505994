import { Table } from "@/components/DesignSystem/Table/Table.component";
import { useExportMutation } from "@/components/DesignSystem/Table/hooks/useExportCsv.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { isLoading } from "@/modules/loadable";
import { logger } from "@/modules/logger";
import {
    Table as UnityTable,
    advancedFilterUtils,
} from "@pricefx/unity-components";
import { isEqual, isNil, mapValues, omit, pipe } from "lodash/fp";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
    MAX_EXPORT_SIZE,
    PAGE_SIZE_OPTIONS,
    buildSort,
    buildUrl,
    normalizeFilters,
} from "../PagableTable/PageableTable";
import { DislocatedExportButton } from "../TableWithPreferences/DislocatedExportButton";

const encodeFilters = mapValues(({ value, prefix, column }) => ({
    value,
    prefix,
}));

const decodeFilters = (enabledFilters, columns) =>
    enabledFilters
        ? Object.keys(enabledFilters).reduce(
              (filters, columnName) => ({
                  ...filters,
                  [columnName]: {
                      value: enabledFilters[columnName]?.value,
                      prefix: enabledFilters[columnName]?.prefix,
                      column: columns.find(
                          column => column.name === columnName,
                      ),
                  },
              }),
              {},
          )
        : {};

export const isFilterEmpty = (value, prefix) =>
    (isNil(value) || value === "") && (isNil(prefix) || prefix === "");

const applyFilterChange = (oldFilters, columnName, value, prefix) => {
    const newFilters = isFilterEmpty(value, prefix)
        ? omit([columnName], oldFilters)
        : { ...oldFilters, [columnName]: { value, prefix } };
    return newFilters;
};

const getPage = (index, pageSize) => Math.floor(index / pageSize) + 1;

const getRecordInfo = (match, pageSize, dataSource, rowKey) => {
    const [property, value] = match;
    const index = dataSource.findIndex(record => record[property] === value);

    if (index === -1 || isNaN(pageSize)) return undefined;

    const recordInfo = {
        index,
        page: getPage(index, pageSize),
        key: dataSource[index][rowKey],
    };

    return recordInfo;
};
export function TableLocalFiltered({
    dataSource = [],
    hasColumnAutofit = true,
    hasColumnResizing = true,
    onChangeFilter,
    onChangePaging,
    onChangeSort,
    columns,
    // quickFilters = true,
    // sorting = true,

    disablePagination,
    pagination,
    onPaginationChange,

    sorter,
    onSortChange,

    filter,
    onFilterChange,
    controlled,
    ExportButton = DislocatedExportButton,
    exportUrl,
    expand,
    rowKey,
    subsetFilter,
    ...rest
}) {
    const paginationWithDefaults = useMemo(
        () =>
            disablePagination
                ? false
                : { pageSizeOptions: PAGE_SIZE_OPTIONS, ...pagination },
        [disablePagination, pagination],
    );

    const initialFilters = decodeFilters(filter, columns);
    const {
        tableProps,
        dataSource: filteredDataSource,
        state,
        actions: { setFilters, setSortingRules, setPagination },
    } = UnityTable.useLocalFiltering({
        sorting: true,
        quickFilters: true,
        dataSource,
        columns,
        pagination: paginationWithDefaults,
        onPaginationChange: useCurrentHandler(pagination => {
            onPaginationChange?.(pagination);
            onChangePaging?.(pagination);
        }),
        initialSortingRules: sorter,
        onSort: useCurrentHandler(sortingRules => {
            onSortChange?.(sortingRules);
            onChangeSort?.(sortingRules);
        }),
        initialFilters,
        onFilterChange: useCurrentHandler((change, previousQuickFilters) => {
            // console.log("onFilterChange", { change });
            onFilterChange?.(
                applyFilterChange(
                    encodeFilters(previousQuickFilters),
                    change.column.name,
                    change.value,
                    change.prefix,
                ),
            );
            onChangeFilter?.(change, previousQuickFilters);
        }),
        controlled,
    });
    useEffect(() => {
        const quickFilters = decodeFilters(filter, columns);
        const isChanged =
            (filter || controlled) &&
            !isEqual(quickFilters, state.quickFilters);
        logger.debug({
            logGroupKey: ["TableLocalFiltered", "syncFiltersEffect"],
            color: "deepskyblue",
            msg: isChanged ? "SYNCING" : "",
            data: {
                quickFilters,
                stateQuickFilters: state.quickFilters,
                filter,
                controlled,
                isChanged,
            },
        });
        if (isChanged) setFilters(quickFilters);
    }, [filter, state.quickFilters, columns, controlled, setFilters]);

    useEffect(() => {
        if ((sorter || controlled) && !isEqual(sorter, state.sortingRules)) {
            setSortingRules(sorter);
        }
    }, [controlled, setSortingRules, sorter, state.sortingRules]);

    const statePaginationRef = useRef(state.pagination);
    statePaginationRef.current = state.pagination;
    useEffect(() => {
        if (
            paginationWithDefaults?.pageSize &&
            paginationWithDefaults.pageSize !==
                statePaginationRef.current.pageSize
        ) {
            setPagination({
                current: 1,
                pageSize: paginationWithDefaults.pageSize,
            });
        }
    }, [paginationWithDefaults, setPagination]);

    const maybeExpandRecordInfo = useMemo(() => {
        if (!expand) return undefined;
        return getRecordInfo(
            expand,
            statePaginationRef.current.pageSize,
            dataSource,
            rowKey,
        );
    }, [dataSource, expand, rowKey]);

    const expandPaginationSetRef = useRef(false);
    useEffect(() => {
        if (maybeExpandRecordInfo && !expandPaginationSetRef.current) {
            const newPagination = {
                ...statePaginationRef.current,
                current: maybeExpandRecordInfo.page,
            };
            setPagination(newPagination);
            expandPaginationSetRef.current = true;
        }
    }, [maybeExpandRecordInfo, setPagination]);

    const exportMutation = useExportMutation({
        fileName: "Export.csv",
        exportUrl: pipe(buildSort, buildUrl(exportUrl))(sorter ?? {}),
        columns,
    });

    const mapFilteredValues = useCallback(
        (enabledFilters, qFilters) =>
            enabledFilters
                ? Object.keys(enabledFilters).reduce(
                      (filters, columnName) => ({
                          ...filters,
                          [columnName]: {
                              column: columns.find(
                                  column => column.name === columnName,
                              ),
                              value: qFilters?.[columnName]?.value,
                              prefix: qFilters?.[columnName]?.prefix,
                          },
                      }),
                      {},
                  )
                : {},
        [columns],
    );

    const buildFilterCriteriaForAPI = useCallback(
        ({
            quickFilters,
            // advancedFilterRules property is ready for implementation of Advanced Filter table feature
            advancedFilterRules = null,
            columns,
            subsetFilter,
        }) =>
            advancedFilterUtils.combinePrefixFilters([
                advancedFilterUtils.mergeFiltersForAPI(
                    mapFilteredValues(
                        normalizeFilters({ filter: quickFilters, columns }),
                        quickFilters,
                    ),
                    advancedFilterRules,
                ),
                subsetFilter,
            ]),
        [mapFilteredValues],
    );
    const onExport = useCallback(() => {
        const filters = buildFilterCriteriaForAPI({
            quickFilters: filter || {},
            columns,
            subsetFilter,
        });
        exportMutation.mutate({
            // filters: filter || {},
            filters,
            offsetInMinutes: moment().utcOffset(),
        });
    }, [
        buildFilterCriteriaForAPI,
        columns,
        exportMutation,
        filter,
        subsetFilter,
    ]);

    logger.debug({
        logGroupKey: ["TableLocalFiltered", "rndr"],
        color: "deepskyblue",
        data: {
            state,
            filter,
            initialFilters,
            columns,
            sorter,
            pagination,
            maybeExpandRecordInfo,
        },
    });

    return (
        <>
            <ExportButton
                exportDisabled={dataSource.length > MAX_EXPORT_SIZE}
                exportVisible={!!exportUrl}
                onExport={onExport}
                exporting={isLoading(exportMutation)}
            />
            <Table
                dataSource={filteredDataSource}
                hasColumnAutofit={hasColumnAutofit}
                hasColumnResizing={hasColumnResizing}
                columns={columns}
                maybeExpandRecordInfo={maybeExpandRecordInfo}
                rowKey={rowKey}
                {...tableProps}
                {...rest}
            />
        </>
    );
}

TableLocalFiltered.propTypes = {
    dataSource: PropTypes.array,
    hasColumnAutofit: PropTypes.bool,
    hasColumnResizing: PropTypes.bool,
    onChangeFilter: PropTypes.func,
    onChangePaging: PropTypes.func,
    onChangeSort: PropTypes.func,
    quickFilters: PropTypes.bool,
    sorting: PropTypes.bool,
};
