import { Col, Row, Text } from "@/components/DesignSystem";
import { T } from "@/translations";
import React from "react";
import unauthorizedContentIcon from "../../../assets/icons/unauthorized.svg";
import styles from "./UnauthorizedContent.style.less";

export function UnauthorizedContent() {
    return (
        <Row type="flex" justify="center" className={styles.content}>
            <Col className={styles.text}>
                <h4>
                    <T id="unauthorized-header" />
                </h4>
                <Text>
                    <T id="unauthorized-message" />
                </Text>
            </Col>
            <Col>
                <img src={unauthorizedContentIcon} />
            </Col>
        </Row>
    );
}
