import { Button as UnityButton } from "@pricefx/unity-components";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useTrackButtonClick } from "../../../mixpanel/hooks/useTrackButtonClick.hook";
import { Forms } from "@/components/DesignSystem/Forms";

const ClickWrapper = ({ onClick, children }) => (
    <span onClick={onClick}>{children}</span>
);

// Tracking not working - onClick passed to SubmitButton changes htmlType in UC
export const SubmitButtonTempFix = ({ onClick, ...props }) => {
    return (
        <ClickWrapper onClick={onClick}>
            <Forms.SubmitButton {...props} />
        </ClickWrapper>
    );
};

export const types = ["default", "primary", "link", "text"];
export function Button({
    className,
    icon,
    type,
    label,
    onClick,
    track,
    visible = true,
    Component = UnityButton, // SubmitButton
    ...props
}) {
    const classes = cx("pmButton", className);
    const { trackHandler } = useTrackButtonClick();

    if (!visible) return null;

    return (
        <Component
            className={classes}
            icon={icon}
            type={type}
            label={label}
            onClick={trackHandler(onClick, {
                defaultName: label || props.tooltip || icon?.name,
                ...track,
            })}
            {...props}
        />
    );
}

Button.propTypes = {
    className: PropTypes.string,
    label: PropTypes.node,
    // accepts imported svg only, see unity components docs
    icon: PropTypes.any,
    type: PropTypes.oneOf(types),
    onClick: PropTypes.func,
    track: PropTypes.oneOfType([
        PropTypes.shape({ name: PropTypes.any }),
        PropTypes.bool,
    ]),
};
