import cx from "classnames";
import PropTypes from "prop-types";
import React, { useRef } from "react";
export const PanelContainerContext = React.createContext(null);

export const PanelContainer = ({ children, className, ...props }) => {
    const ref = useRef();

    return (
        <div ref={ref} className={cx(className, "pmPanelContainer")} {...props}>
            <PanelContainerContext.Provider value={ref.current}>
                {children}
            </PanelContainerContext.Provider>
        </div>
    );
};

PanelContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
