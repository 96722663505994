import { Gap, H4, Image, P } from "@/components/DesignSystem";
import PropTypes from "prop-types";
import React from "react";
import pfxLogo from "../../assets/img/pricefx-platform-logo.svg";
import styles from "./styles.less";

const LoginHeader = ({ title, subtitle }) => {
    return (
        <>
            <Image src={pfxLogo} alt="Pricefx" className={styles.logo} />
            <Gap size="medium" />
            <H4>{title}</H4>
            {subtitle && <P>{subtitle}</P>}
        </>
    );
};

LoginHeader.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

export default LoginHeader;
