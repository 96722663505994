import PropTypes from "prop-types";
import React from "react";
import styles from "./ContainerWithFloat.style.less";

export function ContainerWithFloat({
    flowRight,
    children,
    as: Wrapper = "div",
    ...props
}) {
    return (
        <Wrapper {...props}>
            <div className={styles.floatRight}>{flowRight}</div>
            {children}
        </Wrapper>
    );
}

ContainerWithFloat.propTypes = {
    children: PropTypes.node.isRequired,
    flowRight: PropTypes.node.isRequired,
    as: PropTypes.node,
};
