import { Card as AntCard } from "antd";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
// assets
import "./Card.style.less";

const CARD_PREFIX = "pmCard--";

export function Card({
    type,
    spacing,
    background = "white",
    className,
    children,
    ...props
}) {
    const classes = cx(
        "pmCard",
        type && `${CARD_PREFIX}${type}`,
        spacing && `${CARD_PREFIX}${spacing}`,
        `${CARD_PREFIX}background-${background}`,
        className,
    );

    return (
        <AntCard {...props} className={classes}>
            {children}
        </AntCard>
    );
}

Card.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    type: PropTypes.string,
    spacing: PropTypes.string,
};
