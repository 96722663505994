import { Calendar as AntCalendar } from "antd";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./Calendar.style.less";

export function Calendar({ className, hiddenYear = false, ...props }) {
    const classes = cx(
        "pmCalendar",
        className,
        hiddenYear && "pmCalendar--hiddenYear",
    );

    return <AntCalendar {...props} className={classes} />;
}

Calendar.propTypes = {
    className: PropTypes.string,
    hiddenYear: PropTypes.bool,
    type: PropTypes.string,
};
