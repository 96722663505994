import { userAccountId } from "@/security/UserContext";
import { useRecoilValue } from "recoil";

export const useDefaultParams = () => {
    const currentAccountId = useRecoilValue(userAccountId);

    return {
        accountId: currentAccountId,
    };
};
