import { getDic } from "../dic/dic";
import { createAxiosClient } from "./axios.factory";

// temp solution enabling locationRouterService usage in adalService
const createHandler = () => {
    let axiosService;
    const handler = {
        get(_obj, prop) {
            if (!axiosService) {
                const dic = getDic();
                axiosService = createAxiosClient(dic);
            }
            return axiosService[prop];
        },
    };
    return handler;
};

export const axiosService = new Proxy({}, createHandler());
