import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import get from "lodash/fp/get";
import pipe from "lodash/fp/pipe";
import { STATUSES } from "./useQueryLoadable.hook";

export const waitForValue = maybeValue =>
    maybeValue === undefined ? new Promise(() => {}) : maybeValue;

export const pendingPromise = () => new Promise(() => {});

export const crudSuccess = (what, namePath, crud) => result => {
    const crudType = typeof crud === "function" ? crud(result) : crud;
    const crudMsg =
        {
            C: t("general.created"),
            R: t("general.fetched"),
            U: t("general.updated"),
            D: t("general.deleted"),
        }[crudType] || t("general.updated");
    const name = namePath ? get(namePath, result) : undefined;

    return [what, name, crudMsg, "successfully"].filter(Boolean).join(" ");
};

export const translateWithMessage = (
    messageKey,
    path = "response.data",
    extractMessage = getErrorMessage,
) => pipe(get(path), extractMessage, message => t(messageKey, { message }));

export const unwrapAxiosError = get("response.data");

export const responseErrorMessage = pipe(unwrapAxiosError, getErrorMessage);

export const extractUUID = e => e?.response?.data?.uuid || e?.uuid;

export const getLoadable = resourceOrLoadable =>
    resourceOrLoadable?.loadable ?? resourceOrLoadable;

export const hasValue = resourceOrLoadable =>
    getLoadable(resourceOrLoadable).state === STATUSES.hasValue;
export const hasError = resourceOrLoadable =>
    getLoadable(resourceOrLoadable).state === STATUSES.hasError;
export const isLoading = resourceOrLoadable =>
    getLoadable(resourceOrLoadable).state === STATUSES.loading;

export const isLoadable = maybeLoadable =>
    Object.keys(STATUSES).includes(maybeLoadable?.state);
export const isResourceWithReload = maybeResource =>
    Object.keys(STATUSES).includes(maybeResource?.loadable?.state) &&
    !!maybeResource?.reload;

export const maybeError = resourceOrLoadable =>
    hasError(resourceOrLoadable)
        ? getLoadable(resourceOrLoadable).contents
        : undefined;

export const mapLoadableItems = (resourceOrLoadable, mapper) => {
    const loadable = getLoadable(resourceOrLoadable);
    if (loadable.valueMaybe() === undefined) {
        return loadable;
    } else {
        const contents = mapper(loadable.valueMaybe());

        return {
            ...loadable,
            contents,
            valueMaybe: () => contents,
        };
    }
};

export const throwMapped = mapper => e => {
    throw mapper(e);
};

export const logLoadable = (name, resourceOrLoadable, ...extras) => {
    const loadable = getLoadable(resourceOrLoadable);
    let color = { loading: "yellow", hasValue: "green", hasError: "red" }[
        loadable.state
    ];
    if (loadable.state === "hasValue" && loadable.contents === undefined)
        color = "blue";
    console.log(
        `${name} %c[${loadable.state}]`,
        `color:${color};`,
        loadable.contents,
        ...extras,
    );
};
