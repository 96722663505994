import { orEmpty } from "@/components/DesignSystem/Forms/validators";
import { passwordRestrictions } from "@/utils/passwordUtils";
import { Forms } from "@pricefx/unity-components";
import cx from "classnames";
import zip from "lodash/zip";
import React, { Suspense } from "react";
import { Input as DesignSystemInput } from "../../../index";
import style from "./style.less";
const {
    Field,
    success,
    error,
    validators,
    useFieldValidation,
    isSuccess,
    isError,
} = Forms;

const validations = [
    {
        validate: (value = "") =>
            passwordRestrictions.containsLowerCase(value)
                ? success()
                : error("1_normal_letter"),
        message: "1 Normal letter",
    },
    {
        validate: (value = "") =>
            passwordRestrictions.containsUpperCase(value)
                ? success()
                : error("1_capital_letter"),
        message: "1 Capital letter",
    },
    {
        validate: (value = "") =>
            passwordRestrictions.containsDigit(value)
                ? success()
                : error("1_number"),
        message: "1 Number",
    },
    {
        validate: (value = "") =>
            passwordRestrictions.containsSpecialChar(value)
                ? success()
                : error("special_char"),
        message: "1 Special char",
    },
    {
        validate: (value = "") =>
            passwordRestrictions.isLongerThan(value)
                ? success()
                : error("min_10_chars"),
        message: "Min 10 Characters",
    },
];

const [messages, validator] = [
    validations.map(({ message }) => message),
    validators.all(validations.map(({ validate }) => validate)),
];

const MutliValidationResult = ({ name, formId, touched, messages }) => {
    const validation = useFieldValidation({ formId, name });
    // when not required, all validations can fail but field is still valid
    const valid = isSuccess(validation);

    return zip(messages, validation.value).map(([message, result]) => {
        return (
            <span
                key={message}
                className={cx(
                    style.validation,
                    touched && result && isError(result) && !valid
                        ? style.errorColor
                        : result && isSuccess(result)
                        ? style.successColor
                        : style.greyColor,
                )}
            >
                {message}
            </span>
        );
    });
};

const MultiValidationComponent = ({ name, formId }) => {
    const field = Forms.useField({ formId, name });
    return (
        <Suspense fallback={messages}>
            <MutliValidationResult
                messages={messages}
                name={name}
                formId={formId}
                touched={field.touched}
            />
        </Suspense>
    );
};

export const RestrictedPassword = ({ required = false, ...props }) => (
    <Field
        as={DesignSystemInput.Password}
        {...props}
        required={required}
        extraComponent={MultiValidationComponent}
        // getValidateStatus={() => undefined}
        validator={required ? validator : orEmpty(validator)}
    />
);
