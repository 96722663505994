import { Forms as UnityForms } from "@pricefx/unity-components";
import PropTypes from "prop-types";
import React from "react";
import * as pmComponents from "./components";
import * as Fields from "./fields";
import * as pmValidators from "./validators";
import * as pmHooks from "./hooks";

const Form = ({ disableBanner = true, ...props }) => (
    <UnityForms.Form disableBanner={disableBanner} {...props} />
);

export const Forms = {
    ...UnityForms,
    Form,
    pmValidators,
    Fields,
    pmComponents,
    pmHooks,
};

Form.propTypes = {
    disableBanner: PropTypes.bool,
};
