import { ButtonMenu, Modal, UnityLayout } from "@/components/DesignSystem";
import { useSyncRowSelection } from "@/components/DesignSystem/Table/hooks/useSyncRowSelection.hook";
import { TableLocalFiltered } from "@/components/TableLocalFiltered";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { Gap } from "../Gap";

export const AddEntitiesModal = ({
    visible,
    onClose,
    availableEntities,
    onAdd,
    rowKey,
    createColumns,
    addModalTitle,
    addModalDescription,
}) => {
    const [selected, setSelected] = useState([]);
    const rowSelection = useSyncRowSelection(selected, setSelected);
    const handleAdd = () => {
        onAdd(selected);
        setSelected([]);
        onClose();
    };
    const columns = useMemo(() => createColumns({}), [createColumns]);

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header size={3} title={addModalTitle} />
                <UnityLayout.Content>
                    {addModalDescription && (
                        <>
                            {addModalDescription}
                            <Gap />
                        </>
                    )}
                    <TableLocalFiltered
                        columns={columns}
                        dataSource={availableEntities}
                        rowKey={rowKey}
                        rowSelection={rowSelection}
                        customHeight={{ allowShrink: false, height: 300 }}
                        // pagination={false}
                        // padding={false}
                    />
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    label: t("general.add"),
                                    onClick: handleAdd,
                                    type: "primary",
                                    disabled: !selected.length,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

AddEntitiesModal.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    availableEntities: PropTypes.arrayOf(PropTypes.object.isRequired)
        .isRequired,
    onAdd: PropTypes.func.isRequired,
    rowKey: PropTypes.string.isRequired,
    createColumns: PropTypes.func.isRequired,
    addModalTitle: PropTypes.node.isRequired,
    addModalDescription: PropTypes.node,
};
