import React from "react";
import { atom } from "recoil";
import pageStyles from "../../views/Pages/styles.less";
import FirstPassword from "./components/FirstPassword.component";
import Login from "./components/Login.component";
import SecondAuth from "./components/SecondAuth.component";
import { LoginStates } from "./constants";
import { partitionRedirect } from "./helpers";
import { useLogin } from "./useLogin.hook";

const lgk = (tail = []) => ["AUTH", "login", "service", ...tail];

export const loginErrorState = atom({
    key: "loginErrorState",
    default: null,
});

export const LoginPage = () => {
    const { loginState, userContext, loginMutation } = useLogin();

    const urlParams = new URLSearchParams(window.location.search);
    const partitionLogin = urlParams.get("partitionLogin");

    // Partition login redirect
    if (partitionLogin === "") {
        partitionRedirect();
        return null;
    }

    return (
        <div className={pageStyles.page}>
            {loginState === LoginStates.NONE && (
                <Login loginMutation={loginMutation} />
            )}
            {loginState === LoginStates.NEEDS_PASSWORD_CHANGE && (
                <FirstPassword
                    userContext={userContext}
                    loginMutation={loginMutation}
                />
            )}
            {loginState === LoginStates.NEEDS_AUTHENTICATION_SECOND_STEP && (
                <SecondAuth
                    userContext={userContext}
                    loginMutation={loginMutation}
                />
            )}
        </div>
    );
};

LoginPage.propTypes = {};
