import isEqual from "lodash/isEqual";

export const INIT_STATE = {
    pagination: { pageSize: 30, current: 1 },
    filter: {},
    sorter: {},
};

export const PAGINATION_CHANGE = "PAGINATION_CHANGE";
export const SORT_CHANGE = "SORT_CHANGE";
export const FILTER_CHANGE = "FILTER_CHANGE";

export const reducer = (state, action) => {
    let newState;

    switch (action.type) {
        case SORT_CHANGE:
            {
                newState = {
                    ...state,
                    sorter: action.data,
                };
            }
            break;
        case PAGINATION_CHANGE:
            {
                newState = {
                    ...state,
                    pagination: action.data,
                };
            }
            break;
        case FILTER_CHANGE:
            {
                newState = {
                    ...state,
                    pagination: {
                        pageSize: state.pagination.pageSize,
                        current: INIT_STATE.pagination.current,
                    },
                    filter: action.data,
                };
            }
            break;
    }

    return isEqual(state, newState) ? state : newState;
};
