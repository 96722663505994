import { Forms } from "@pricefx/unity-components";
import identity from "lodash/identity";
import React from "react";
import { TimePicker as DesignSystemTimePicker } from "../../index";

const { Field } = Forms;

export const TimePicker = ({ ...props }) => {
    return <Field as={DesignSystemTimePicker} from={identity} {...props} />;
};
