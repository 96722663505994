import set from "lodash/set";
import { useMemo } from "react";

const useColumnResizing = (hasColumnResizing, setColumnsInfo) =>
    useMemo(
        () =>
            hasColumnResizing
                ? {
                      onResize: (columnName, width) => {
                          setColumnsInfo(info => {
                              const columnIndex = info.findIndex(
                                  column => column.name === columnName,
                              );
                              if (columnIndex < 0) {
                                  return info;
                              }

                              return [
                                  ...set(info, [columnIndex, "width"], width),
                              ];
                          });
                      },
                  }
                : null,
        [hasColumnResizing, setColumnsInfo],
    );

export default useColumnResizing;
